import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { QueryClientProvider } from 'react-query'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Routes from './routes/index'
import ScrollTop from './config/scroll'
import { client } from './config/reactQuery'
import './App.scss'
import { WalletConnect } from './Wrapper/WalletConnect'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const history = createBrowserHistory()
function App () {
  return (
    <WalletConnect>
    <ToastContainer />
    <QueryClientProvider client={client}>
      <BrowserRouter history={history}>
      <ScrollTop />
      <DndProvider backend={HTML5Backend}>
        <Routes />
      </DndProvider>
      </BrowserRouter>
    </QueryClientProvider>
    </WalletConnect>
  )
}

export default App
