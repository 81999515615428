import React from 'react'
import PropTypes from 'prop-types'
import './../assets/sass/views/Home.scss'
import caution from './../assets/images/inote.png'
import warning from './../assets/images/warning.svg'

export default function HomeModal (props) {
  if (!props.show) {
    return null
  }
  return (
    <div className="homeModal">
      <div className="modalContent">
        {
          props.decline
            ? <>
              <img className="modalImage" src={warning} />
            </>
            : <>
              <img className="modalImage" src={caution} />
            </>
        }
          <div className="modalHeader">
              <h3>{ props.title }</h3>
          </div>
          <div>
              <p>{props.content1}</p>
              <p>{props.content2}</p>
              <p>{props.content3}</p>
          </div>
          <div className="modalFooter">
            {
              props.decline
                ? <>
                  <button onClick={ props.onClose } className="button no">{ props.decline }</button>
                  <button onClick={ props.onConfirm } className="button yes">{ props.validate }</button>
                </>
                : <button onClick={ props.onClose } className="button ok">{ props.validate }</button>
            }
          </div>
      </div>
    </div>
  )
}
HomeModal.propTypes = {
  title: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  content3: PropTypes.string,
  decline: PropTypes.string,
  validate: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool
}
