import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import cookies from './cookies'
const AffiliateTracker = () => {
  const location = useLocation()
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const affiliateCode = params.get('code')
    if (affiliateCode) {
      const today = new Date() // Get today's date
      const nextWeek = new Date(today) // Create a new Date object based on today's date
      nextWeek.setDate(today.getDate() + 7) // Add 7 days to today's date
      cookies.set('noraCode', affiliateCode,
        { path: '/', secure: true, sameSite: 'none', expires: nextWeek }) // Set cookie for 7 days
    }
  }, [location])
  return null
}
export default AffiliateTracker
