/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import { getCurrentPoolData } from '../../query/home/home.query'

import '../../assets/sass/views/Home.scss'
import '../../assets/sass/views/modal.scss'
import Slider from 'react-slick'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import Sidebar from '../../components/Sidebar'
import TicketModal from './TicketModal'
import CurrentPool from './CurrentPool'
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'
import bannerdice from '../../assets/images/banner-dices.png'
import redballimg from '../../assets/images/red-ball-img.png'
import greenballimg from '../../assets/images/green-ball-img.png'
import blueballimg from '../../assets/images/blue-ball-img.png'
import previcon from '../../assets/images/arrow-left.png'
import nexticon from '../../assets/images/arrow-right.png'
import loadingimg from '../../assets/images/data-loader.gif'
import '../../assets/sass/views/loader.scss'
import Modal from './../../components/Modal'
import { useTranslation } from 'react-i18next'
import BuyModal from '../../components/buyModal'

import '../../../src/assets/css/font-awesome.css'
import AffiliateTracker from '../../AffiliateTracker'

const Home = () => {
  const { t } = useTranslation('global')
  const [loading, setLoading] = useState({ status: true })
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [selectedTicket, setSelectedTicket] = useState(1)
  const [slider1, setSlider1] = useState(null)
  const [slider2, setSlider2] = useState(null)
  const [modal, setModal] = useState(false)
  const { state } = useLocation()
  const ncbTickets = state?.ncbTickets
  const stepsTickets = state?.stepsTickets ? state.stepsTickets : '[]'
  const [currentPoolData, setCurrentPoolData] = useState(state?.currentPoolData ? state.currentPoolData : {})
  const [ticket, setTicket] = useState(state?.ticketState?.length
    ? state.ticketState
    : [
        { id: 1, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 2, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 3, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 4, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 5, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 6, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 7, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 8, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 9, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 10, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 11, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 12, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 13, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 14, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false }
      ])
  const [sliderIndex, setSliderIndex] = useState(1)
  const navigate = useNavigate()
  const ref = useRef(null)
  const scoreSlider = useRef()

  const goPrev = (e) => {
    if (e.detail === 1) {
      scoreSlider.current.slickPrev()
      setSlider2(scoreSlider.current)
      setSliderIndex((sliderIndex - 1) % ticket.length)
      setSelectedTicket((selectedTicket - 1) % ticket.length)
      if (selectedTicket === 1) {
        setSliderIndex(ticket.length)
        setSelectedTicket(ticket.length)
      }
    }
  }
  const goNext = (e) => {
    if (e.detail === 1) {
      scoreSlider.current.slickNext()
      setSlider2(scoreSlider.current)
      setSliderIndex(sliderIndex + 1)
      setSelectedTicket(selectedTicket + 1)
      if (selectedTicket === ticket.length) {
        setSliderIndex(1)
        setSelectedTicket(1)
      }
    }
  }
  const toggle = () => {
    // console.log(selectedTicket + ' ' + ticket.id + ' ' + sliderIndex + ' ' + ticket.length)
    if ((selectedTicket === 1 || ticket[Number(selectedTicket - 1)].confirmClick || ticket[Number(selectedTicket - 2)].confirmClick)) {
      if (ticket[selectedTicket - 1].confirmClick) {
        sessionStorage.setItem('tmpList', JSON.stringify(ticket[selectedTicket - 1]))
      }
      return setModal(!modal)
    }
  }
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const selectedTicketNo = (ticketNo) => {
    setSelectedTicket(ticketNo)
    setSliderIndex(ticketNo)
  }

  const quickPickClick = () => {
    navigate('/nori/quick-pick', { state: { ticket, currentPoolData, ncbTickets, stepsTickets } })
  }

  const sellNoriClick = () => {
    navigate('/nori/sell', { state: { ticket, currentPoolData, ncbTickets, stepsTickets } })
  }

  const useNoriClick = () => {
    navigate('/nori/use', { state: { ticket, currentPoolData, ncbTickets, stepsTickets } })
  }

  const noriClick = () => {
    window.scrollTo(0, 0)
    navigate('/nori/build', { state: { ticket, currentPoolData, ncbTickets, stepsTickets } })
  }

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav'
  }

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    arrows: false,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  }
  const winnerslider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    dots: false,
    arrows: false,
    swipeToSlide: true,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 3000
  }
  const handleDeleteClick = () => {
    setTicket(
      [
        { id: 1, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 2, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 3, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 4, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 5, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 6, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 7, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 8, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 9, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 10, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 11, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 12, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 13, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false },
        { id: 14, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false }
      ]
    )
    setShowDeleteTickets(false)
  }
  useEffect(() => {
    setNav1(slider1)
    setNav2(slider2)
  })

  const list = document.getElementById('myDIV')?.classList

  useEffect(() => {
    setTimeout(() => {
      setLoading({ ...loading, status: false })
      list?.remove('active')
    }, 1000)
  }, [list])

  useEffect(() => {
    if (ticket?.length) setLoading({ ...loading, status: false })
  }, [ticket])

  useQuery('current-pool', getCurrentPoolData, {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setCurrentPoolData(Object.assign(currentPoolData, data))
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: 'Internal Server Error' } })
    }
  })
  const [showBuy, setShowBuy] = useState(false)
  const [showDeleteTickets, setShowDeleteTickets] = useState(false)

  return (
    <>
      {showBuy
        ? ticket[0].confirmClick
          ? <BuyModal
            showBuy={showBuy}
            setShowBuy={setShowBuy}

            nbrTicket={ticket.filter((t) => t.totalSelectedNo === 6).length}
            competition={ticket.find((t) => t.noriCompetition)}
            ticket={ticket}

            nps={JSON.parse(stepsTickets)}
            ncb={ncbTickets}
          />
          : null
        : null
      }
      {ticket[0].confirmClick
        ? <Modal
          title={t('views.home.home.warning')}
          content1={t('views.home.home.beSure')}
          validate={t('views.home.home.yes')}
          decline={t('views.home.home.no')}
          onClose={() => setShowDeleteTickets(false)} onConfirm={handleDeleteClick}
          show={showDeleteTickets}
        />
        : <Modal
          title={t('views.home.home.sorry')}
          content1={t('views.home.home.noTicket')}
          validate={t('views.home.home.ok')}
          onClose={() => setShowDeleteTickets(false)}
          show={showDeleteTickets}
        />
      }
      <AffiliateTracker />
      <div className='loader-container align-items-center justify-content-center' id='myDIV'>
        <img className="loader" src={loadingimg} alt='Loading...' />
      </div>
      <TicketModal modal={modal} toggle={toggle} selectedTicket={ticket} setSelectedTicket={setTicket} ticketNo={selectedTicket} setTicketNo={setSelectedTicket} />
      {loading.status
        ? list?.add('active')
        : ''}
      <Header activeTab={1} tickets={ticket} currentPoolData={currentPoolData} ncbTickets={ncbTickets} stepsTickets={stepsTickets} />
      {/* Banner Sec */}
      <div className='home-banner-sec'>
        <Sidebar sideActiveTab={1} tickets={ticket} currentPoolData={currentPoolData} ncbTickets={ncbTickets} stepsTickets={stepsTickets}/>
        <div className='home-banner-middle-sec'>
          <div className='banner-common-box-sec pb-0 banner-left-box'>
            <div className='banner-common-box-padding'>
              <Slider {...winnerslider}>
                {currentPoolData?.infoSlider?.map((win, index) => {
                  return (<div key={index}>
                    <div className='text-center w-100'>
                      <h4>{win?.title}</h4>
                    </div>
                    <ul className='winning-list'>
                      {win?.ilines?.map((sub, i) => <li key={i}><span>{sub}</span></li>)}
                    </ul>
                  </div>)
                })}
              </Slider>
            </div>
          </div>
          <div className='banner-dice-sec'>
            <div className='dice-box-header'>
              <h5>{t('views.home.home.numberMadeFun')}</h5>
            </div>
            <h3>{t('views.home.home.poweredBy')}<a href="https://www.noracle.io/" target="_blank" rel="noreferrer">NORACLE</a></h3>
            <img src={bannerdice} className="dice-img" />
            <div className='text-center'>
              <a onClick={handleClick} className='common-white-btn'><span>{t('views.home.home.playNow')}</span></a>
            </div>
          </div>
          <div className='banner-footer d-mobile'>
            <h4>{t('views.home.home.instruction')}</h4>
          </div>
          <CurrentPool poolData={currentPoolData} />
        </div>
        <div className='banner-footer d-desktop'>
          <h4>{t('views.home.home.instruction')}</h4>
        </div>
        <div className='banner-scroll-sec' ref={ref}></div>
      </div>
      {/* Nori Sec */}
      <div className='build-nori-sec' id='build-nori-sec'>
        <h1 className='text-center'>{t('views.home.home.buildANori')}</h1>
        <p>{t('views.home.home.buildANoriInfo')}</p>
        <div className='nori-middle-sec'>
          <div className='nori-left-sec'>
            <div className='nori-common-sec config-sec'>
              <div className='nori-config-sec'>
                <p>{t('views.home.home.configurationOption')}</p>
                <a className='common-blue-btn' onClick={noriClick}><span>{t('views.home.home.buildNori')}</span></a>
                <a className='common-white-btn' onClick={quickPickClick}><span>{t('views.home.home.quickPick')}</span></a>
              </div>
            </div>
            <div className='nori-common-sec other-nori-sec'>
              <div className='nori-config-sec'>
                <p>{t('views.home.home.otherOption')}</p>
                <div className='other-nori-btns'>
                  <a className='common-white-btn small-btn' onClick={useNoriClick}><span>{t('views.home.home.useNori')}</span></a>
                  <a className='common-white-btn small-btn' onClick={sellNoriClick}><span>{t('views.home.home.sellNori')}</span></a>
                </div>
              </div>
            </div>
            <img src={greenballimg} className='greenballimg' />
            <img src={redballimg} className='redballimg' />
          </div>
          <div className='nori-right-sec'>
            <img src={blueballimg} className='blueballimg' />

            <div className='nori-common-sec'>
              <Slider
                {...settingsMain}
                asNavFor={nav2}
                className='nori-number-slider'
                ref={slide => setSlider1(slide)}
              >
                {ticket.length
                  ? ticket.map((t, i) =>
                    t?.totalSelectedNo === 6
                      ? <div onClick={toggle} className='' key={t.id}>
                        <ul className='hexagone-list orange ' key={t.id}>
                          {Array.from(Array(42), (li, i) => {
                            if (t?.selectedNo.includes(i + 1) == true) {
                              return <li key={i + 1} className='active'>{i + 1}</li>
                            } else {
                              return <li key={i + 1}></li>
                            }
                          })}
                        </ul>
                      </div>
                      : <div onClick={() => { toggle() }} key={t.id}>
                        <ul className='hexagone-list test' key={t.id}>
                          {Array.from(Array(42), (li, i) => {
                            if (t?.selectedNo?.includes(i + 1) == true) {
                              return <li key={i + 1} className='active'>{i + 1}</li>
                            } else {
                              return <li key={i + 1}></li>
                            }
                          })}
                          <span className={t.id > 999 ? 'ticket-number ticket-number-large three' : 'ticket-number ticket-number-large'}>{t.id}</span>
                        </ul>
                      </div>
                  )
                  : ''}
              </Slider>
              <div className="thumbnail-slider-wrap">
                <button onClick={goPrev} className='custom-left-btn'>
                  <img src={previcon} />
                </button>
                <button onClick={goNext} className='custom-right-btn'>
                  <img src={nexticon} />
                </button>
                <Slider
                  {...settingsThumbs}
                  asNavFor={nav1}
                  ref={scoreSlider}>
                  {ticket.map((t, i) =>
                    t.totalSelectedNo === 6
                      ? <div onClick={() => selectedTicketNo(t.id)} key={t.id}>
                        <ul className='hexagone-list orange' key={t.id}>
                          {Array.from(Array(42), (li, e) => {
                            if (t?.selectedNo.includes(e + 1) == true) {
                              return <li key={e + 1} className='active'>{e + 1}</li>
                            } else {
                              return <li key={e + 1}></li>
                            }
                          })}
                        </ul>
                        <span className={t.id > 999 ? 'ticket-number three' : 'ticket-number'}>{t.id}</span>
                      </div>
                      : <div onClick={() => selectedTicketNo(t.id)} key={t.id}>
                        <ul className='hexagone-list' key={t.id}>
                          {Array.from(Array(42), (li, e) => {
                            if (t?.selectedNo?.includes(e + 1) == true) {
                              return <li key={e + 1} className='active'>{e + 1}</li>
                            } else {
                              return <li key={e + 1}></li>
                            }
                          })}
                        </ul>
                        <span className={t.id > 999 ? 'ticket-number three' : 'ticket-number'}>{t.id}</span>
                      </div>
                  )}
                </Slider>
                {!ticket[0].confirmClick ? showBuy ? <div style={{ color: 'red' }} className={'noTicket'}>{t('views.home.home.noTicketFilled')}</div> : null : null}
              </div>
              <div className='other-nori-btns'>
                <a className='common-white-btn small-btn'><span onClick={() => setShowDeleteTickets(true)}>{t('views.home.home.deleteAll')}</span></a>
                <a className='common-blue-btn small-btn'><span onClick={() => setShowBuy(true)}>{t('views.home.home.buyTicket')}</span></a>&nbsp; &nbsp;
                <span className='tickets-numbers tooltip-heading d-inline-block'>{ticket.filter((t) => t.totalSelectedNo === 6).length} <span className='tooltiptext'>{ticket.filter((t) => t.totalSelectedNo === 6).length > 1 ? ticket.filter((t) => t.totalSelectedNo === 6).length + ' ' + t('views.home.home.tickets') : ticket.filter((t) => t.totalSelectedNo === 6).length + ' ' + t('views.home.home.ticket')}  </span></span>
              </div>
              <p className='per-ticket-text'>$ 0.5 {t('views.home.home.perTicket')}</p>
            </div>
          </div>
        </div>
      </div>
      {/* vrf white badge */}
      <div className='chainlink-banner'>
        <a href="https://chain.link/badge" target="_blank" rel="noreferrer">
          <img src="https://chain.link/badge-randomness-black" alt="randomness secured with chainlink" />
        </a>
      </div>
      {/* <Range/> */}
      <Footer isVisibleBall={true} currentPool={currentPoolData?.lastDraws} scoreDate={currentPoolData?.lastWiNs} graphData={currentPoolData?.lastHundredDraws} />
    </>
  )
}
export default Home
