import React from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
const style = {
  height: '100%',
  width: '100%'
}
export const Dustbin = (greedy, dustbin) => {
  const [{ canDrop, isOver, isOverCurrent }, drop] = useDrop(() => ({
    accept: ItemTypes.BOX,
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (didDrop && !greedy) {
        return null
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true })
    })
  }))
  const isActive = canDrop && isOver
  let backgroundColor = '#222'
  let dustbinClass1 = ''
  let dustbinClass2 = ''
  if (isActive) {
    backgroundColor = 'darkgreen'
  } else if (isOverCurrent || (isOver && greedy)) {
    backgroundColor = 'darkgreen'
    if (dustbin === 1) dustbinClass2 = 'active'
    if (dustbin === 2) dustbinClass1 = 'active'
  }
  return (
    <div className={dustbin === 1 ? dustbinClass2 : dustbinClass1} ref={drop} style={{ ...style, backgroundColor }} data-testid="dustbin">
    {dustbinClass1}{dustbinClass2}
  </div>
  )
}
