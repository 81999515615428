import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap'
import { replayResp } from '../../query/walletconnect/walletconnect.query'
import closeicon from '../../assets/images/close-icon.svg'
import { useTranslation } from 'react-i18next'

const MyGameDetailsModal = (props) => {
  const { t } = useTranslation('global')
  const [noriCheck, setNoriCheck] = useState({ reuseTicket: true, reuseNori: true })
  const navigate = useNavigate()
  const { refetch: replayRefetch } = useQuery('reply-refetch', () => replayResp(props?.detailData, noriCheck), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const ticketArr = []
      data?.ts?.map((t, i) => ticketArr.push({ id: i + 1, selectedNo: t.n, totalSelectedNo: t.n.length, autoGenerated: true, confirmClick: false }))
      navigate('/nori/build', { state: { reuseNoriData: data, tickets: ticketArr } })
    }
  })

  const handleChange = (no, e) => {
    if (no === 1) {
      setNoriCheck({ ...noriCheck, reuseTicket: e.target.checked })
    } else if (no === 2) {
      setNoriCheck({ ...noriCheck, reuseNori: e.target.checked })
    }
  }

  return (
    <>
      <Modal toggle={props.toggle} className='settings-popup result-popup' id='settings-popup' isOpen={props.modal}>
        <ModalBody>
          <div className='common-modal-sec'>
            <button onClick={props.toggle} className='common-close-btn'><img src={closeicon} /></button>
            <h4 className='common-title'>{t('views.myGame.myGameDetails.title')}</h4>
            <div className='ticket-details-header'>
              <div className='header-detail-item'>
                <ul>
                  <li className='header-detail-item-left'>
                    {t('views.myGame.myGameDetails.firstDraw')}<strong>{props.detailData?.ldt !== null ? `${new Date(props.detailData?.ldt).getUTCDate() < 10 ? '0' + new Date(props.detailData?.ldt).getUTCDate() : new Date(props.detailData?.ldt).getUTCDate()}/${(new Date(props.detailData?.ldt).getMonth() + 1) < 10 ? '0' + (new Date(props.detailData?.ldt).getMonth() + 1) : new Date(props.detailData?.ldt).getMonth() + 1}/${new Date(props.detailData?.ldt).getFullYear()}` : '-'}</strong>
                  </li>
                  <li className='header-detail-item-right'>
                    <div className={props?.detailData?.norID === null ? 'form-grp disabled' : 'form-grp'}>
                      <input type='checkbox' checked={noriCheck.reuseNori} onChange={(e) => handleChange(2, e)} />
                      <label>{t('views.myGame.myGameDetails.reuseNori')}</label>
                    </div>
                  </li>
                </ul>
              </div>
              <div className='header-detail-item'>
                <ul>
                  <li className='header-detail-item-left'>
                    {t('views.myGame.myGameDetails.lastDraw')}<strong>{props.detailData?.fdt !== null ? `${new Date(props.detailData?.fdt).getUTCDate() < 10 ? '0' + new Date(props.detailData?.fdt).getUTCDate() : new Date(props.detailData?.fdt).getUTCDate()}/${(new Date(props.detailData?.fdt).getMonth() + 1) < 10 ? '0' + (new Date(props.detailData?.fdt).getMonth() + 1) : new Date(props.detailData?.fdt).getMonth() + 1}/${new Date(props.detailData?.fdt).getFullYear()}` : '-'}</strong>
                  </li>
                  <li className='header-detail-item-right'>
                    <div className='form-grp'>
                      <input type='checkbox' checked={noriCheck.reuseTicket} onChange={(e) => handleChange(1, e)} />
                      <label>{t('views.myGame.myGameDetails.reuseTicket')}</label>
                    </div>
                  </li>
                </ul>
              </div>

              <div className='header-detail-item'>
                <ul>
                  <li className='header-detail-item-left'>
                    {t('views.myGame.myGameDetails.usages')}<strong>{props.detailData?.usg}</strong>
                  </li>
                </ul>
              </div>
            </div>
            {props?.detailData?.ts?.length
              ? <div className='ticket-details-list'>
                <ul>
                  {props?.detailData?.ts.map((game, i) => {
                    return (<li key={i}>
                      {t('views.myGame.myGameDetails.ticket')} {i + 1}
                      <ul>
                        {game.n.length
                          ? game.n.map((subGame, j) => {
                            return (
                              <li key={j}><span>{subGame}</span></li>
                            )
                          })
                          : <li> - </li>
                        }
                      </ul>
                    </li>)
                  })}
                </ul>
              </div>
              : <div className='ticket-details-list'>{t('views.myGame.myGameDetails.noRecord')}</div>
            }
            <button className='common-blue-btn small-btn' onClick={replayRefetch}><span>{t('views.myGame.myGameDetails.replay')}</span></button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

MyGameDetailsModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  detailData: PropTypes.object
}

export default MyGameDetailsModal
