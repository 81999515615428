import React from 'react'
import '../../assets/sass/views/Nori.scss'
import Nori from './Nori.jsx'

const BuildNori = (props) => {
  return (
    <>
      <Nori {...props}/>
    </>
  )
}

export default BuildNori
