import React, { useState, useEffect } from 'react'
import { addLeadingZeros } from '../../utils/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const CurrentPool = (props) => {
  const { t } = useTranslation('global')
  const [timer, setTimer] = useState({
    days: '00',
    hours: '00',
    min: '00',
    sec: '00'
  })
  useEffect(() => {
    if (props?.poolData?.currentPool?.nextDrawDate && props?.poolData?.currentPool?.currentTime) {
      if (Number.isNaN(restTime)) {
        setRestTime(new Date(props?.poolData?.currentPool?.nextDrawDate) - new Date(props?.poolData?.currentPool?.currentTime))
      }
      if (restTime <= 0) {
        setTimer({
          days: '00',
          hours: '00',
          min: '00',
          sec: '00'
        })
      } else {
        const crestTime = parseInt(restTime)
        const cdays = Math.floor(crestTime / (1000 * 60 * 60 * 24))
        const chours = Math.floor((crestTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const cminutes = Math.floor((crestTime % (1000 * 60 * 60)) / (1000 * 60))
        const cseconds = Math.floor((crestTime % (1000 * 60)) / 1000)
        setTimer({
          days: cdays,
          hours: chours,
          min: cminutes,
          sec: cseconds
        })
      }
    }
  })
  const [restTime, setRestTime] = useState(new Date(props?.poolData?.currentPool?.nextDrawDate) - new Date(props?.poolData?.currentPool?.currentTime))
  useEffect(() => {
    const crestTime = restTime
    if (restTime <= 0) {
      setTimer({
        days: '00',
        hours: '00',
        min: '00',
        sec: '00'
      })
    } else {
      const cdays = Math.floor(crestTime / (1000 * 60 * 60 * 24))
      const chours = Math.floor((crestTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const cminutes = Math.floor((crestTime % (1000 * 60 * 60)) / (1000 * 60))
      const cseconds = Math.floor((crestTime % (1000 * 60)) / 1000)
      setTimer({
        days: cdays,
        hours: chours,
        min: cminutes,
        sec: cseconds
      })
    }
  }, [restTime])
  useEffect(() => {
    /* setInterval(
      () => {
        if (props?.poolData?.currentPool?.nextDrawDate) {
          // console.log('props?.poolData?.currentPool?.nextDrawDate: ' + props)
          // const startDate = countDownCalculations(dateCheck(new Date(props?.poolData?.currentPool?.currentTime).getTime()))
          // console.log('startDate: ' + startDate)
          // startDate && setTimer(startDate)
          setRestTime(prevTime => prevTime - 1000)
        }
      },
      0,
      1000
    ) */
    const timer = setInterval(() => {
      setRestTime(prevTime => prevTime - 1000)
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [restTime])
  return (
    <div className='banner-common-box-sec right-common-box'>
      <div className='banner-common-box-padding'>
        <h4>{t('views.home.currentPool.title')}</h4>
        <h3>{props?.poolData?.currentPool?.totalValue} BNB</h3>
        <h3>({props?.poolData?.currentPool?.totalValueUSDT} $)</h3>
        <ul className='pool-list'>
          <li>{props?.poolData?.currentPool?.participatingAddresses}{t('views.home.currentPool.participAddr')}</li>
          <li>{props?.poolData?.currentPool?.ticketsBought}{t('views.home.currentPool.ticketBought')}</li>
          <li>{props?.poolData?.currentPool?.competingNoris}{t('views.home.currentPool.competingNori')}</li>
        </ul>
      </div>
      <div className='next-draw-timer-sec'>
        <div className='next-draw-sec'>
          <p>{t('views.home.currentPool.title2')}</p>
          <ul>
            <li>
              <h3>{addLeadingZeros(timer?.days)}</h3>
              <span>{t('views.home.currentPool.days')}</span>
            </li>
            <li>
              <h3>{addLeadingZeros(timer?.hours)}</h3>
              <span>{t('views.home.currentPool.hours')}</span>
            </li>
            <li>
              <h3>{addLeadingZeros(timer?.min)}</h3>
              <span>{t('views.home.currentPool.minuts')}</span>
            </li>
            <li>
              <h3>{addLeadingZeros(timer?.sec)}</h3>
              <span>{t('views.home.currentPool.seconds')}</span>
            </li>
          </ul>
        </div>
      </div>
    </div >
  )
}

CurrentPool.propTypes = {
  poolData: PropTypes.object
}

export default CurrentPool
