import React, { memo } from 'react'
import Box from './Box'
import { Dustbin } from './Dustbin'
import numberbg from '../../assets/images/numbers-bg.png'
export const Container = memo(function Container () {
  return (
    <div>
      {/* <div style={{ overflow: 'hidden', clear: 'both' }}>
        <Dustbin />
      </div>
      <div style={{ overflow: 'hidden', clear: 'both' }}>
        <Box name="Glass" />
        <Box name="Banana" />
        <Box name="Paper" />
      </div> */}
      <div>
      <div className='common-step-box'>
        <div className='text-center'>
          <h4 className='common-title tooltip-heading'>Prioritize Group Of Numbers
          {/* <img src={infoicon} className="infoicon" /> */}
          <span className="tooltiptext">The configuration of this option affects the probability of occurrence of numbers in the generated series. Besides mandatory numbers resp. excluded numbers which have an appearance probability of 100% resp. 0%, other numbers can be set custom priority levels.</span>
          </h4>
          <p>Drag and drop or click from the left container on all numbers that should be less prioritized during the generation of combinations and select the priority level.</p>
          <div className='nori-mandetory-ticket-sec'>
            <div className='nori-ticket-sec drag-sec'>
              {/* <img src={numberbg} /> */}
              <img src={numberbg} className='numberbg' />
             <div className='dustbin-sec' id='dust1'><Dustbin name={1} dustbin={1}></Dustbin></div>
              <div className='drag-item-list' >
              {Array.from(Array(42), (op, i) =>
                <Box key={i + 1} id={i + 1} container={1} name={i + 1} className='active'>{i + 1}</Box>
              )}
              </div>
            </div>
            <div className='nori-ticket-sec drag-sec'>
                <img src={numberbg} className='numberbg' />
                <div className='dustbin-sec' id='dust2' ><Dustbin name={2} dustbin={2}></Dustbin></div>
                 <div className='drag-item-list' >
                 {Array.from(Array(42), (op, i) =>
                  <Box key={i + 1} id={i + 1} name={i + 1} container={2} className='active'>{i + 1}</Box>
                 )}
            </div>

            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
})
