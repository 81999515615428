import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import playicon from '../assets/images/play.svg'
import noraicon from '../assets/images/Nora-icon.svg'
import competition from '../assets/images/Competitions.svg'
import winners from '../assets/images/Winners.svg'
import statistic from '../assets/images/Statistics.svg'
import mygames from '../assets/images/My-games.svg'
import livedrawings from '../assets/images/Live-drawings.svg'
import toogleimg from '../assets/images/toogle-icon.svg'

import '../assets/sass/views/common.scss'

const Sidebar = (props) => {
  const { t } = useTranslation('global')
  const [isActive, setIsActive] = React.useState(false)
  const navigate = useNavigate()
  const stepsTickets = JSON.stringify(props.stepsTickets)

  const handleClick = (no) => {
    if (no === 1) {
      navigate('/', { state: { ticketState: props.tickets, currentPoolData: props.currentPoolData, ncbTickets: props.ncbTickets, stepsTickets: stepsTickets } })
    }
    if (no === 2) {
      navigate('/competition', { state: { ticketState: props.tickets, currentPoolData: props.currentPoolData, ncbTickets: props.ncbTickets, stepsTickets: stepsTickets } })
    }
    if (no === 6) {
      navigate('/live-drawings', { state: { ticketState: props.tickets, ncbTickets: props.ncbTickets, stepsTickets: stepsTickets } })
    }
    window.location.reload()
  }
  return (
    <div>
      <ul className={isActive === true ? 'sidebar-open sidebar' : 'sidebar'}>
        <li>
          <a>
            <img src={playicon} />
          </a>
        </li>
        <li>
          <a onClick={() => handleClick(1)} className={props.sideActiveTab === 1 ? 'active' : ''}>
            <img src={noraicon} />
            <span>Nora642</span>
          </a>
        </li>
        <li>
          <a onClick={() => handleClick(2)} className={props.sideActiveTab === 2 ? 'active' : ''}>
            <img src={competition} />
            <span>{t('component.sidebar.competitions')}</span>
          </a>
        </li>
        <li>
          <a href='/winners' className={props.sideActiveTab === 3 ? 'active' : ''}>
            <img src={winners} />
            <span>{t('component.sidebar.winners')}</span>
          </a>
        </li>
        <li>
          <a href='/statistics' className={props.sideActiveTab === 4 ? 'active' : ''}>
            <img src={statistic} />
            <span>{t('component.sidebar.statistics')}</span>
          </a>
        </li>
        <li>
          <a href='/mygames' className={props.sideActiveTab === 5 ? 'active' : ''}>
            <img src={mygames} />
            <span>{t('component.sidebar.myGames')}</span>
          </a>
        </li>
        <li>
          <a onClick={() => handleClick(6)} className={props.sideActiveTab === 6 ? 'active' : ''}>
            <img src={livedrawings} />
            <span>{t('component.sidebar.live')} <span>{t('component.sidebar.drawings')}</span></span>
          </a>
        </li>
      </ul>
      <div className='mobile-sidebar-btn'>
        <a onClick={() => { setIsActive(!isActive) }} ><img src={toogleimg} /></a>
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  sideActiveTab: PropTypes.number,
  tickets: PropTypes.array,
  currentPoolData: PropTypes.object,
  ncbTickets: PropTypes.number,
  stepsTickets: PropTypes.string
}

export default Sidebar
