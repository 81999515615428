import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function ScrollTop () {
  useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <></>
  )
}
export default ScrollTop
