import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import '../src/assets/sass/views/common.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next, I18nextProvider } from 'react-i18next'

import tradEn from './languages/en.json'
import tradDe from './languages/de.json'
import tradFr from './languages/fr.json'
import tradEs from './languages/es.json'
import tradIt from './languages/it.json'
import tradPt from './languages/pt.json'
import tradNl from './languages/nl.json'
import tradTk from './languages/tk.json'
import tradRu from './languages/ru.json'
import tradRo from './languages/ro.json'
import tradZh from './languages/zh.json'
import tradKo from './languages/ko.json'
import tradjp from './languages/jp.json'
import tradpl from './languages/pl.json'

const resources = {
  en: {
    global: tradEn
  },
  de: {
    global: tradDe
  },
  fr: {
    global: tradFr
  },
  es: {
    global: tradEs
  },
  it: {
    global: tradIt
  },
  pt: {
    global: tradPt
  },
  nl: {
    global: tradNl
  },
  tk: {
    global: tradTk
  },
  ru: {
    global: tradRu
  },
  ro: {
    global: tradRo
  },
  zh: {
    global: tradZh
  },
  ko: {
    global: tradKo
  },
  jp: {
    global: tradjp
  },
  pl: {
    global: tradpl
  }
}
i18next.use(LanguageDetector).use(initReactI18next).init({
  debug: false,
  resources,
  lng: sessionStorage.getItem('curentLanguage') ? sessionStorage.getItem('curentLanguage') : 'en'
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>{' '}
  </React.StrictMode>
)

reportWebVitals()
