import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import Sidebar from '../../components/Sidebar'
import Loading from '../../components/Loader'
import { useTranslation } from 'react-i18next'
import '../../assets/sass/views/Competition.scss'
import '../../assets/sass/views/Nori.scss'
import '../../assets/sass/views/footer.scss'

import compititionimg from '../../assets/images/compititaion-bg.png'
import { useNavigate, useLocation } from 'react-router-dom'

const Competition = () => {
  const { state } = useLocation()
  const { t } = useTranslation('global')
  const [loading, setLoading] = useState({ status: true })
  const navigate = useNavigate()
  const ticketState = state.ticketState
  const currentPoolData = state.currentPoolData
  const handleClick = () => {
    navigate('/competitions', { state: { ticketState: ticketState, currentPoolData: currentPoolData } })
  }
  const winnerClick = () => {
    navigate('/winners')
  }
  useEffect(() => {
    setTimeout(() => {
      setLoading({ status: false })
    }, 500)
  }, [])
  return (
    <>
      {loading.status ? <Loading addClass={'active'} /> : ''}
      <Header activeTab={1} tickets={ticketState} currentPoolData={currentPoolData} />
      <div className='statics-tabs-sec competition-main-sec'>
        <Sidebar sideActiveTab={2} tickets={ticketState} currentPoolData={currentPoolData} />
        <div className='competition-sec'>
          <h1>{t('views.competition.title')}</h1>
          <p>{t('views.competition.buildANori')}</p>
          <p>{t('views.competition.toSee1')} <a onClick={winnerClick}>{t('views.competition.winner')}</a> {t('views.competition.toSee2')}</p>
          <div className='compititaion-inner-sec'>
            <img src={compititionimg} />
            <div>
              <h3>{t('views.competition.startText')}</h3>
              <a onClick={handleClick} className='common-blue-btn small-btn'>{t('views.competition.startButton')}</a>
            </div>
          </div>
        </div>
      </div>
      <Footer isVisibleBall={false} />
    </>
  )
}

export default Competition
