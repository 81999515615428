import { toast } from 'react-toastify'

/**
 * @function
 * Display a toast notification with a given type and message.
 *
 * @param {('error' | 'info' | 'warn' | 'success')} type - Type of the notification.
 * @param {string} message - Message content for the toast.
 *
 * @example
 * notify('error', 'An error occurred.');
 */
export const notify = (type, message) => {
  toast.clearWaitingQueue()
  toast[type](message, { toastId: message })
}
