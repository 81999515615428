import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import { Modal, ModalBody } from 'reactstrap'
import WinnerDetailModal from './WinnerDetailModal'
import { getDetailWinners } from '../../query/winners/winner.query'
import closeicon from '../../assets/images/close-icon.svg'
import { useTranslation } from 'react-i18next'

const WinnerCompModal = (props) => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const [detailId, setDetailId] = useState('')
  const [subDetailData, setSubDetailData] = useState({})
  const [noriEvalData, setNoriEvalData] = useState({})
  const [detailModal, setDetailModal] = useState(false)
  const detailToggle = () => {
    return setDetailModal(!detailModal)
  }
  const handleDetail = (id) => {
    setDetailId(id)
    detailToggle()
  }

  useQuery(['get-detail-winners', detailId], () => getDetailWinners(detailId), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      if (data) {
        data.graphData = [{ x: 0, y: 0 }]
        data?.nsd?.noriStats?.map((graph, i) => {
          data.graphData.push({ x: (i + 1) * 10, y: graph?.compliant })
          return data
        })
        setNoriEvalData(data)
        setSubDetailData(data)
      }
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.winners.winnersComp.error') } })
    }
  })

  const columnsWinners = [
    {
      id: 1,
      name: t('views.winners.winnersComp.users'),
      selector: (row) => row.user,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 2,
      name: t('views.winners.winnersComp.number'),
      selector: (row) => row.details,
      sortable: false,
      reorder: false,
      center: true
    }
  ]
  const rowsWinners = props?.data?.map((wn) => {
    const winnerArr = {}
    winnerArr.user = wn?.usr
    winnerArr.details = <a onClick={() => handleDetail(wn?.nid)} className='common-white-btn small-btn'><span>View Details</span></a>
    return winnerArr
  })
  const paginationComponentOptions = {
    noRowsPerPage: true
  }
  const fetchData = async (page) => {
    const data = props?.data?.length ? [...props.data] : []
    const result = []
    for (let i = (8 * (page - 1)); i <= (page * 8) - 1; i++) {
      result.push(data[i])
    }
  }
  const handlePageChange = (page, no) => {
    fetchData(page, no)
  }
  return (
    <>
      <Modal toggle={props.toggle} className='settings-popup result-popup compitition-winner-sec nora-642-popup' id='settings-popup' isOpen={props.modal}>
        <ModalBody>
          <div className='common-modal-sec'>
            <button onClick={props.toggle} className='common-close-btn'><img src={closeicon} /></button>
            <h4 className='common-title'>{t('views.winners.winnersComp.noriCompetition')}</h4>
            <div className='common-table-sec'>
              <DataTable
                columns={columnsWinners}
                data={rowsWinners}
                pagination
                paginationPerPage={6}
                paginationComponentOptions={paginationComponentOptions}
                onChangeRowsPerPage={handlePageChange}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
      <WinnerDetailModal toggle={detailToggle} modal={detailModal} noriEvalData={noriEvalData} subDetailData={subDetailData} />
    </>
  )
}
WinnerCompModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  data: PropTypes.array
}

export default WinnerCompModal
