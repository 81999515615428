import React from 'react'
import PropTypes from 'prop-types'

import loadingimg from '../assets/images/data-loader.gif'
import '../assets/sass/views/loader.scss'

const Loading = (props) => {
  return (
      <div className={`${props.classes} loader-container align-items-center justify-content-center ${props.addClass}`}>
        {/* <div className="loader">
        </div> */}
        <img className="loader" src={loadingimg} alt='Loading...' />
      </div>
  )
}

Loading.propTypes = {
  classes: PropTypes.string,
  addClass: PropTypes.string
}

export default Loading
