import Cookies from 'universal-cookie'
// import jwt from 'jsonwebtoken'
import { jwtDecode } from 'jwt-decode'

const cookies = new Cookies()

export default cookies

function isTokenExpired (token) {
  if (!token) return true
  // const decoded = jwt.decode(token, { complete: true });
  const decoded = jwtDecode(token)
  const exp = decoded?.exp
  if (!exp) return true // If there's no expiration, treat it as expired
  return Date.now() >= exp * 1000 // Compare current time with expiration
}

export function getUserToken () {
  const token = cookies.get('userToken')
  if (!token || isTokenExpired(token)) {
    // Handle token expiration or absence, e.g., redirect to login
    return null
  }
  return token
}
