import React from 'react'
import PropTypes from 'prop-types'
import infoicon from '../../../assets/images/info-icon.png'
import { useTranslation } from 'react-i18next'

const SixthComponent = (props) => {
  const { t } = useTranslation('global')
  const handleChange = (e, id, no) => {
    const result = [...props.steps]
    if (e.target.value) {
      const res = result[3].selAdvanceFilterCriteria.findIndex((m) => id === m.id)
      if (no === 1) {
        result[3].selAdvanceFilterCriteria[res].min = e.target.value
      } else if (no === 2) {
        result[3].selAdvanceFilterCriteria[res].max = e.target.value
      } else {
        result[3].tolerance = Number(e.target.value)
      }
      props.setPrevActiveStep(false)
      return props.setSteps(result)
    }
  }
  return <div>

    <div className='common-step-box sixth-step-box'>
      <div className='text-center'>
        <h4 className='common-title align-items-start'><span> {t('views.nori.step6.title')} </span><div className='tooltip-heading d-inline-block'>
          <img src={infoicon} className="infoicon" />
          <span className='tooltiptext'>{t('views.nori.step6.instruction')}</span>
        </div>
        </h4>
        <p>{t('views.nori.step6.infos')}</p>
      </div>
      <div className='filter-list-sec'>
        <div className='filter-list-item'>
          <div className='filter-left-sec'>
          </div>
          <div className='filter-right-sec filter-header'>
            <div>
              <label>{t('views.nori.step6.min')}</label>
            </div>
            <div>
              <label>{t('views.nori.step6.max')}</label>
            </div>
          </div>
        </div>
        {props.steps[3].selAdvanceFilterCriteria.map((afc) =>
          <div className='filter-list-item' key={afc.id}>
            <div className='filter-left-sec ' key={afc.id}>
              <div className='tooltip-heading'>
                <img src={infoicon} />
                <span className='tooltiptext'>{afc.tooltip}</span>
              </div>
              <span>{afc.key}</span>
            </div>
            <div className='filter-right-sec'>
              <div className='filter-select'>
                <div>
                  <select value={Number(afc.min)} onChange={(e) => handleChange(e, afc.id, 1)}>
                    {Array.from(Array(Number(afc.dmin) === Number(afc.min) ? Number(afc.max - afc.min) + 1 : Number(afc.max - afc.dmin) + 1), (op, i) =>
                      <option key={i + Number(afc.dmin)} value={i + Number(afc.dmin)}>{i + Number(afc.dmin)}</option>
                    )}
                  </select>
                </div>
              </div>
              <div className='filter-select'>
                <div>
                  <select value={Number(afc.max)} onChange={(e) => handleChange(e, afc.id, 2)}>
                    {Array.from(Array(Number(afc.dmax) === Number(afc.max) ? Number(afc.max - afc.min) + 1 : Number(afc.dmax - afc.min) + 1), (op, i) =>
                      <option key={Number(afc.min) === Number(afc.dmin) ? i + Number(afc.dmin) : i + Number(afc.min)} value={Number(afc.min) === Number(afc.dmin) ? i + Number(afc.dmin) : i + Number(afc.min)}>{Number(afc.min) === Number(afc.dmin) ? i + Number(afc.dmin) : i + Number(afc.min)}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
        <hr />
        <div className='filter-list-item'>
          <div className='filter-left-sec tooltip-heading'>
            <img src={infoicon} />
            <span>{t('views.nori.step6.tolerance')}</span>
            <span className='tooltiptext'>{props.steps[3].tolTooltip}</span>
          </div>
          <div className='filter-right-sec'>
            <div className='filter-select after-none'>
            </div>
            <div className='filter-select'>
              <div>
                <select value={Number(props.steps[3].tolerance)} onChange={(e) => handleChange(e, '', 3)}>
                  {Array.from(Array(8), (op, i) =>
                    <option key={Number(i)} value={Number(i)}>{Number(i)}</option>
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div></div>
}

SixthComponent.propTypes = {
  steps: PropTypes.array,
  setSteps: PropTypes.func,
  setPrevActiveStep: PropTypes.func
}

export default SixthComponent
