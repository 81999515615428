import axios from 'axios'
import Axios from '../../axios'
import cookies from '../../cookies'

export async function getWinnerList () {
  const response = await Axios.get('/game/winnersBoards')
  return response
}

export async function getListByDate (date) {
  if (date) {
    const response = await Axios.get(`/game/winnersBoard/${date}`)
    return response
  }
}

export async function getNoraWinners (id) {
  if (id) {
    const response = await Axios.get(`/game/nora642/winners/${id}`)
    return response
  }
}

export async function getCompWinners (id) {
  if (id) {
    const response = await Axios.get(`/game/competition/winners/${id}`)
    return response
  }
}

export async function getDetailWinners (id) {
  if (id) {
    const response = await Axios.get(`/game/competition/winners/nori/${id}`)
    return response
  }
}

export async function getSignature (data) {
  const response = await axios.post(`${process.env.REACT_APP_NODE_BACKEND_URL}/game/create/sign`, data,
    {
      headers: {
        Authorization: cookies.get('userToken')
      }
    }
  )
  return response
}

export async function getLiveDrawings () {
  const response = await Axios.get('/liveDrawData')
  return response
}
