import React from 'react'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import { Modal, ModalBody } from 'reactstrap'
import closeicon from '../../assets/images/close-icon.svg'
import { useTranslation } from 'react-i18next'

const WinnerNoraModal = (props) => {
  const { t } = useTranslation('global')
  const columnsWinners = [
    {
      id: 1,
      name: t('views.winners.winnersNora.user'),
      selector: (row) => row.user,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 2,
      name: t('views.winners.winnersNora.number'),
      selector: (row) => row.number,
      sortable: false,
      reorder: false,
      center: true,
      width: '70%'
    }
  ]
  const rowsWinners = props?.data?.map((wn) => {
    const winnerArr = {}
    winnerArr.user = wn?.usr
    winnerArr.number = <ul className='winning-number'>{wn?.nbs?.map((sub, i) => <li key={i} className={wn?.wni.includes(i) ? 'green' : ''}>{sub}</li>)}</ul>
    return winnerArr
  })
  const paginationComponentOptions = {
    noRowsPerPage: true
  }
  const fetchData = async (page) => {
    const data = props?.data?.length ? [...props.data] : []
    const result = []
    for (let i = (8 * (page - 1)); i <= (page * 8) - 1; i++) {
      result.push(data[i])
    }
  }
  const handlePageChange = (page, no) => {
    fetchData(page, no)
  }
  return (
    <Modal toggle={props.toggle} className='settings-popup result-popup nora-642-popup' id='settings-popup' isOpen={props.modal}>
      <ModalBody>
        <div className='common-modal-sec'>
          <button onClick={props.toggle} className='common-close-btn'><img src={closeicon} /></button>
          <h4 className='common-title'>{t('views.winners.winnersNora.noraWinners')}</h4>
          <div className='common-table-sec'>
            <DataTable
              columns={columnsWinners}
              data={rowsWinners}
              pagination
              paginationPerPage={6}
              paginationComponentOptions={paginationComponentOptions}
              onChangeRowsPerPage={handlePageChange}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
WinnerNoraModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  data: PropTypes.array
}

export default WinnerNoraModal
