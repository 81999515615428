import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { getSellNori, getPrevNorID } from '../../query/nori/nori.query'
import { getCurrentPoolData } from '../../query/home/home.query'
import UseModal from './UseNoriModal'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import Sidebar from '../../components/Sidebar'
import Loading from '../../components/Loader'
import { useTranslation } from 'react-i18next'
import '../../assets/sass/views/Nori.scss'
import redballimg from '../../assets/images/red-ball-img.png'
import blueballimg from '../../assets/images/blue-ball-img.png'

const UseNori = (props) => {
  const { t } = useTranslation('global')
  const { state } = useLocation()
  const ncbTickets = state?.ncbTickets
  const stepsTickets = state?.stepsTickets ? state.stepsTickets : '[]'
  const [loading, setLoading] = useState({ status: true })
  const [modal, setModal] = useState(false)
  const [useModal, setUseModal] = useState(false)
  const [useNoriData, setUseNoriData] = useState([])
  const [index, setIndex] = useState(0)
  const [modalNum, setModelNum] = useState(0)
  const navigate = useNavigate()
  const [isFetch, setIsFetch] = useState(false)
  const [homeData, setHomeData] = useState({})
  const tickets = useState(state?.ticket)

  const togglemodal = () => {
    return setModal(!modal)
  }
  const useToggle = () => {
    return setUseModal(!useModal)
  }
  useQuery(['current-pool', isFetch], getCurrentPoolData, {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      if (!state) setHomeData(data)
      setIsFetch(false)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.quickPick.error') } })
    }
  })
  useQuery(['get-sellnori', useNoriData.length], getSellNori, {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      data.map((item) => {
        if (item?.noriPrevData?.noriKeyData?.rcb) item.noriPrevData.noriKeyData.rcb = item.noriPrevData.noriKeyData.rcb.toFixed(4)
        item.isSelected = false
        item.isUseOpen = false
        if (item?.noriPrevData?.noriDateStats?.noriStats.length) {
          item.graphData = [{ x: 0, y: 0 }]
          item?.noriPrevData?.noriDateStats?.noriStats.map((graph, i) => {
            item.graphData.push({ x: (i + 1) * 10, y: graph?.compliant })
            return item
          })
        }
        return item
      })
      setLoading({ ...loading, status: false })
      setUseNoriData(Object.assign(useNoriData, data))
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.useNori.useNori.error') } })
    }
  })

  useQuery(['prev-norid', index], () => { if (useNoriData[index]?.norId && !useNoriData[index].noriPrevData) return getPrevNorID(useNoriData[index]?.norId) }, {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (resp) => {
      if (resp) {
        const finalUseNoriData = [...useNoriData]
        finalUseNoriData[index].noriPrevData = resp
        if (finalUseNoriData[index]?.noriPrevData?.noriKeyData?.rcb) finalUseNoriData[index].noriPrevData.noriKeyData.rcb = finalUseNoriData[index].noriPrevData.noriKeyData.rcb.toFixed(4)
        finalUseNoriData[index].isSelected = false
        finalUseNoriData[index].isUseOpen = false
        const graphData = [{ x: 0, y: 0 }]
        finalUseNoriData[index]?.noriPrevData?.noriDateStats?.noriStats.map((graph, i) => {
          graphData.push({ x: (i + 1) * 10, y: graph?.compliant })
          return graph
        })
        finalUseNoriData[index].graphData = graphData
        setLoading({ ...loading, status: false })
        setUseNoriData(finalUseNoriData)
      }
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.useNori.useNori.error') } })
    }
  })

  const handleModal = (norId, no) => {
    const data = [...useNoriData]
    const index = data.findIndex((ele) => ele.norId === norId)
    if (index !== -1) {
      data[index].isSelected = true
      setIndex(index)
    }
    setUseNoriData(data)
    if (no === 1) {
      if (data[index].noriPrevData === undefined && data[index].norId !== undefined) {
        setLoading({ ...loading, status: true })
      }
      setLoading({ ...loading, status: false })
      setModelNum(no)
      togglemodal()
    } else {
      setModelNum(no)
      useToggle()
    }
  }

  const fetchData = async (page) => {
    const data = [...useNoriData]
    const result = []
    for (let i = (8 * (page - 1)); i <= (page * 8) - 1; i++) {
      result.push(data[i])
    }
  }
  const handleNext = () => {
    navigate('/', { state: { ticketState: tickets, currentPoolData: state?.currentPoolData || homeData.currentPoolData, ncbTickets: ncbTickets, stepsTickets: stepsTickets } })
  }
  const handlePageChange = page => {
    fetchData(page)
  }

  const movies = useNoriData?.map((use) => {
    const useNoriArr = {}
    useNoriArr.user = use?.user
    useNoriArr.playedoptions = use?.playedOps && use?.playedOps.length ? <ul>{use.playedOps.map((p) => <li key={p}><span>{p}</span></li>)}</ul> : <p>-</p>
    useNoriArr.noricWeight = use?.noricWeight.toFixed(2)
    useNoriArr.preview = <a className='preview-btn' onClick={() => handleModal(use?.norId, 1)}><span>{t('views.useNori.useNori.prev')}</span></a>
    useNoriArr.action = <a className='use-btn' onClick={() => handleModal(use?.norId, 2)}>{t('views.useNori.useNori.use')}</a>
    return useNoriArr
  })
  const columns = [
    {
      id: 1,
      name: t('views.useNori.useNori.user'),
      selector: (row) => row.user,
      center: true,
      sortable: false,
      reorder: false
    },
    {
      id: 2,
      name: t('views.useNori.useNori.playedOption'),
      selector: (row) => row.playedoptions,
      sortable: false,
      reorder: false,
      width: '176px'
    },
    {
      id: 3,
      name: t('views.useNori.useNori.noriWeight'),
      selector: (row) => row.noricWeight,
      center: true,
      sortable: true,
      reorder: false
    },
    {
      id: 4,
      name: t('views.useNori.useNori.preview'),
      selector: (row) => row.preview,
      center: true,
      sortable: false,
      reorder: false
    },
    {
      id: 5,
      name: t('views.useNori.useNori.action'),
      selector: (row) => row.action,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  const paginationComponentOptions = {
    noRowsPerPage: true
  }

  return (
    <>
      {loading.status ? <Loading addClass={'active'} /> : ''}
      <Header activeTab={1} tickets={state?.ticket} currentPoolData={state?.currentPoolData} ncbTickets={ncbTickets} stepsTickets={stepsTickets} />
      <div className="stepper-sec">
        <Sidebar sideActiveTab={1} tickets={state?.ticket} currentPoolData={state?.currentPoolData} ncbTickets={ncbTickets} stepsTickets={stepsTickets} />
        <div className="box">
          <img src={blueballimg} className='blueballimg' />
          <div className="step-component step-header">
            <div className='mb-0 steps-left-sec left-header-sec'>
              <h1 className='pl-0'>{t('views.useNori.useNori.title')}</h1>
            </div>
          </div>
          <div className='common-table-box-sec use-a-nori-table pb-0'>
            <img src={redballimg} className='redballimg' />
            <div className='btn-component ml-30'>
              <div>
                <input type="button" value={t('views.useNori.useNori.back')} onClick={handleNext} />
              </div>
            </div>
            <div className='text-center'>
              <h4 className='common-title'>{t('views.useNori.useNori.title')}</h4>
              <p>{t('views.useNori.useNori.useNoriInfo')}</p>
            </div>
            <div className='common-table-sec use-nori-table '>
              <DataTable
                columns={columns}
                data={movies}
                pagination
                paginationPerPage={8}
                paginationComponentOptions={paginationComponentOptions}
                onChangeRowsPerPage={handlePageChange}
                defaultSortAsc={false}
                defaultSortFieldId={3}
              />

            </div>

          </div>
        </div>
      </div>
      <Footer isVisibleBall={false} scoreDate={state?.currentPoolData?.lastWiNs} currentPool={state?.currentPoolData?.lastDraws} graphData={state?.currentPoolData?.lastHundredDraws} />
      {modalNum === 2
        ? <UseModal toggle2={useToggle} modal2={useModal} modelNo={modalNum} loading={loading} noriData={useNoriData} setNoriData={setUseNoriData} indexId={index} />
        : <UseModal toggle1={togglemodal} modal1={modal} modelNo={modalNum} loading={loading} noriData={useNoriData} setNoriData={setUseNoriData} indexId={index} />
      }
    </>
  )
}

export default UseNori
