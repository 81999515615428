import React from 'react'
import PropTypes from 'prop-types'
import CanvasJSReact from '../assets/js/canvasjs.react'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

const UseModalGraph = (props) => {
  const options = {
    animationEnabled: true,
    exportEnabled: true,
    backgroundColor: '#1F1F1F',
    colorSet: '#49B9FF',
    color: '#fff',
    axisY: {
      title: '',
      interval: 1,
      labelFontColor: 'white',
      minimum: 0,
      maximum: 10,
      gridColor: 'rgba(20, 121, 184, 0.4)',
      valueFormatString: '00'
    },
    axisX: {
      title: '',
      prefix: '',
      interval: 10,
      minimum: 0,
      labelFontColor: 'white',
      gridColor: 'orange',
      maximum: 101,
      valueFormatString: '00'
    }
  }
  options.data = [{
    type: 'line',
    dataPoints: props?.graphData
  }]
  const containerProps = {
    height: '300px',
    width: '100%'
  }
  return (
    <>
        <CanvasJSChart containerProps={containerProps} options={options} />
    </>
  )
}

UseModalGraph.propTypes = {
  graphData: PropTypes.array
}

export default UseModalGraph
