import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Loading from '../../components/Loader'
import Footer from '../../components/footer'
import '../../assets/sass/views/Tutorial.scss'
import { useTranslation } from 'react-i18next'

const Videos = () => {
  const { t, i18n } = useTranslation('global')
  const testLang = i18n.language
  const [loading, setLoading] = useState({ status: true })
  const video = {
    color: 'white',
    'text-align': 'center'
  }
  useEffect(() => {
    setTimeout(() => {
      setLoading({ status: false })
    }, 500)
  }, [])
  return (
    <>
      {loading.status ? <Loading addClass={'active'} /> : ''}
      <Header activeTab={2} />
      <div className='videos'>
        <div className="generalPresentation">
          <h1 style={video}>{t('views.video.t1')}</h1>
          <div className="frame">
            {testLang === 'fr'
              ? <iframe
                src="https://www.youtube.com/embed/S_MxaVV6iKw"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              : (testLang === 'du'
                  ? <iframe
                  src="https://www.youtube.com/embed/vyDOhtA3pe8"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                  : <iframe
                  src="https://www.youtube.com/embed/aCfLitxXVwM"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>)

            }
          </div>
        </div>
        <div className="howPlay">
          <h1 style={video}>{t('views.video.t2')}</h1>
          <div className="frame">
            <iframe
              src="https://www.youtube.com/embed/-9-f6tvIT6Y"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="howPlay">
          <h1 style={video}>{t('views.video.t3')}</h1>
          <div className="frame">
            <iframe
              src="https://www.youtube.com/embed/pmVYV9tKBto"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <Footer isVisibleBall={false} />

    </>
  )
}

export default Videos
