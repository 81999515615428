import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Loading from '../../components/Loader'
import Footer from '../../components/footer'
import '../../assets/sass/views/Tutorial.scss'

import currentPool from '../../assets/images/currentPool.jpg'
import ticketManager from '../../assets/images/ticketsManager.jpg'
import winningPannel from '../../assets/images/winningPannel.jpg'
import ticketPannel from '../../assets/images/ticketpannel.jpg'
import quiPickGen from '../../assets/images/quickPickGen.jpg'
import ticketModifier from '../../assets/images/ticketModifier.jpg'
import buildNori from '../../assets/images/buildNori.jpg'
import liveDraw from '../../assets/images/liveDraw.jpg'
import probaControl from '../../assets/images/probaControl.jpg'
import probaControl2 from '../../assets/images/probaControl2.jpg'
import compliance from '../../assets/images/compliance.jpg'
import pFactor from '../../assets/images/pFactor.jpg'
import noriPrev from '../../assets/images/noriPrev.jpg'
import noriToSell from '../../assets/images/noriToSell.jpg'
import sellNori from '../../assets/images/sellNori.jpg'
import buildNoriComplete from '../../assets/images/buildNoriComplete.jpg'
import competition from '../../assets/images/competition.jpg'
import winner from '../../assets/images/winner.jpg'
import winnersTickets from '../../assets/images/winnersTickets.jpg'
import noriCompetitionWinners from '../../assets/images/noriCompetitionWinners.jpg'
import winerStrategyDetails from '../../assets/images/winerStrategyDetails.jpg'
import statistics from '../../assets/images/statistics.jpg'
import statistics2 from '../../assets/images/statistics2.jpg'
import myGame from '../../assets/images/myGame.jpg'
import useNori from '../../assets/images/useNori.jpg'

import { useTranslation } from 'react-i18next'

const Tutorial = () => {
  const { t } = useTranslation('global')
  const [loading, setLoading] = useState({ status: true })
  useEffect(() => {
    setTimeout(() => {
      setLoading({ status: false })
    }, 500)
  }, [])
  return (
    <>
      {loading.status ? <Loading addClass={'active'} /> : ''}
      <Header activeTab={3} />
      {/**
       <div className='tutorials-list-sec'>
        <div className='tutorial-list-item'>
          <h1>What is NORACLE?</h1>
          <div className='tutorial-list-img'>
            <img src={tutorialnoracle} />
          </div>
        </div>
        <div className='tutorial-list-item'>
          <h1>HOW TO PLAY?</h1>
          <div className='tutorial-list-img'>
            <img src={tutorialplay} />
            <a href='#' className='playbtn' ><img src={playicon} /></a>
          </div>
        </div>
        <div className='tutorial-list-item mb-20'>
          <h1>HOW TO INVEST/STAKE ?</h1>
          <div className='tutorial-list-img'>
            <img src={tutorialinvest} />
          </div>
        </div>
        <p>You can find more detailed information in our <a href='#'>whitepaper</a> or in the <a href='#'>Help</a> section of the Dapp.</p>
      </div>
       */}
      <div className='tutorial'>
        <div className='presentation'>
          <h1>{t('views.tutorial.about')}</h1>
          <p>{t('views.tutorial.p1')}</p>
          <p>{t('views.tutorial.p2')}</p>
          <p>{t('views.tutorial.p3')}</p>
          <ol>
            <li>{t('views.tutorial.l1')}</li>
            <li>{t('views.tutorial.l2')}</li>
            <li>{t('views.tutorial.l3')}</li>
          </ol>
          <p>{t('views.tutorial.p4')}</p>
        </div>
        <div className="accordeon">
          <div>
            <input type="radio" id="liste1" name="liste" />
            <label htmlFor="liste1"><div>{t('views.tutorial.play')}</div><div className="cross"></div></label>
            <div className="content">
              <div>
                <input type="radio" id="playListe0" name="playListe" />
                <label htmlFor="playListe0"><div>{t('views.tutorial.playList.home.title')}</div><div className="cross"></div></label>
                <div className="questions">
                  <div className='illustrated'>
                    <img src={currentPool} alt='currentPool' />
                    <p>
                      <h3>{t('views.tutorial.playList.home.content.t1')}</h3>
                      {t('views.tutorial.playList.home.content.p1')}
                    </p>
                  </div>
                  <div className="illustrated">
                    <p>
                      <h3>{t('views.tutorial.playList.home.content.t2')}</h3>
                      {t('views.tutorial.playList.home.content.p2')}
                    </p>
                    <img src={ticketManager} alt="ticketManager" />

                  </div>
                  <div className="illustrated">
                    <img src={winningPannel} alt="winnongPannel" />
                    <p>
                      <h3>{t('views.tutorial.playList.home.content.t3')}</h3>
                      {t('views.tutorial.playList.home.content.p3')}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <input type="radio" id="playListe1" name="playListe" />
                <label htmlFor="playListe1"><div>{t('views.tutorial.playList.quickPick.title')}</div><div className="cross"></div></label>
                <div className="questions">
                  <div className="illustrated">
                    <img src={quiPickGen} alt="quiPickGen" />
                    <p>
                      <h3>{t('views.tutorial.playList.quickPick.content.t1')}</h3>
                      {t('views.tutorial.playList.quickPick.content.p1')}
                    </p>
                  </div>
                  <div className="illustrated">
                    <p>
                      <h3>{t('views.tutorial.playList.quickPick.content.t2')}</h3>
                      {t('views.tutorial.playList.quickPick.content.p2')}
                    </p>
                    <img src={ticketPannel} alt="ticketPannel" />
                  </div>
                  <div className="illustrated">
                    <img src={ticketModifier} alt="ticketModifier" />
                    <p>
                      <h3>{t('views.tutorial.playList.quickPick.content.t3')}</h3>
                      {t('views.tutorial.playList.quickPick.content.p3')}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <input type="radio" id="playListe2" name="playListe" />
                <label htmlFor="playListe2"><div>{t('views.tutorial.playList.buildNori.title')}</div><div className="cross"></div></label>
                <div className="questions">
                  <div className="illustrated">
                    <img src={buildNori} alt="buildNori" />
                    <p>
                      <h3>{t('views.tutorial.playList.buildNori.content.t1')}</h3>
                      {t('views.tutorial.playList.buildNori.content.p1')}
                    </p>
                  </div>
                  <div className="illustrated">
                    <p>
                      <h3>{t('views.tutorial.playList.buildNori.content.t2')}</h3>
                      {t('views.tutorial.playList.buildNori.content.p2')}
                    </p>
                    <img src={ticketPannel} alt="ticketPannel" />
                  </div>
                  <div className="illustrated">
                    <img src={ticketModifier} alt="ticketModifier" />
                    <p>
                      <h3>{t('views.tutorial.playList.buildNori.content.t3')}</h3>
                      {t('views.tutorial.playList.buildNori.content.p3')}
                    </p>
                  </div>
                  <div className="">
                    <p>
                      <h3>{t('views.tutorial.playList.buildNori.content.l')} </h3>
                      <ol className='alternating-colors'>
                        <li>
                          <strong>{t('views.tutorial.playList.buildNori.content.lt1')}</strong>
                          <p>
                            {t('views.tutorial.playList.buildNori.content.lp1')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.buildNori.content.lt2')}</strong>
                          <p>
                            {t('views.tutorial.playList.buildNori.content.lp2')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.buildNori.content.lt3')}</strong>
                          <p>
                            {t('views.tutorial.playList.buildNori.content.lp3')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.buildNori.content.lt4')}</strong>
                          <p>
                            {t('views.tutorial.playList.buildNori.content.lp4')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.buildNori.content.lt5')}</strong>
                          <p>
                            {t('views.tutorial.playList.buildNori.content.lp5')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.buildNori.content.lt6')}</strong>
                          <p>
                            {t('views.tutorial.playList.buildNori.content.lp6')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.buildNori.content.lt7')}</strong>
                          <p>
                            {t('views.tutorial.playList.buildNori.content.lp7')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.buildNori.content.lt8')}</strong>
                          <p>
                            {t('views.tutorial.playList.buildNori.content.lp8')}
                          </p>
                        </li>
                      </ol>
                    </p>
                  </div>
                  <div className="illustrated">
                    <img src={probaControl} alt="" />
                    <p>
                      <h3>{t('views.tutorial.playList.buildNori.content.t4')}</h3>
                      {t('views.tutorial.playList.buildNori.content.p4')}
                    </p>
                  </div>
                  <div className="illustrated">
                    <p>
                      <h3>{t('views.tutorial.playList.buildNori.content.t5')}</h3>
                      {t('views.tutorial.playList.buildNori.content.p5')}
                    </p>
                    <img src={compliance} alt="" />
                  </div>
                  <div className="illustrated">
                    <img src={pFactor} alt="" />
                    <p>
                      <h3>{t('views.tutorial.playList.buildNori.content.t6')}</h3>
                      {t('views.tutorial.playList.buildNori.content.p6')}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <input type="radio" id="playListe3" name="playListe" />
                <label htmlFor="playListe3"><div>{t('views.tutorial.playList.useNori.title')}</div><div className="cross"></div></label>
                <div className="questions">
                  <div className="illustrated">
                    <img src={useNori} alt="useNori" />
                    <p>
                      <h3>{t('views.tutorial.playList.useNori.content.t1')}</h3>
                      {t('views.tutorial.playList.useNori.content.p1')}
                    </p>
                  </div>
                  <div className="illustrated">
                    <p>
                      <h3>{t('views.tutorial.playList.useNori.content.t2')}</h3>
                      {t('views.tutorial.playList.useNori.content.p2')}
                    </p>
                    <img src={noriPrev} alt="useNori" />
                  </div>
                </div>
              </div>
              <div>
                <input type="radio" id="playListe4" name="playListe" />
                <label htmlFor="playListe4"><div>{t('views.tutorial.playList.sellNori.title')}</div><div className="cross"></div></label>
                <div className="questions">
                  <div className="illustrated">
                    <img src={noriToSell} alt="buildNori" />
                    <p>
                      <h3>{t('views.tutorial.playList.sellNori.content.t1')}</h3>
                      {t('views.tutorial.playList.sellNori.content.p1')}
                    </p>
                  </div>
                  <div className="">
                    <p>
                      <h3>{t('views.tutorial.playList.sellNori.content.l')}</h3>
                      <ol className='alternating-colors'>
                        <li>
                          <strong>{t('views.tutorial.playList.sellNori.content.lt1')}</strong>
                          <p>
                            {t('views.tutorial.playList.sellNori.content.lp1')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.sellNori.content.lt2')}</strong>
                          <p>
                            {t('views.tutorial.playList.sellNori.content.lp2')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.sellNori.content.lt3')}</strong>
                          <p>
                            {t('views.tutorial.playList.sellNori.content.lp3')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.sellNori.content.lt4')}</strong>
                          <p>
                            {t('views.tutorial.playList.sellNori.content.lp4')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.sellNori.content.lt5')}</strong>
                          <p>
                            {t('views.tutorial.playList.sellNori.content.lp5')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.sellNori.content.lt6')}</strong>
                          <p>
                            {t('views.tutorial.playList.sellNori.content.lp6')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.sellNori.content.lt7')}</strong>
                          <p>
                            {t('views.tutorial.playList.sellNori.content.lp7')}
                          </p>
                        </li>
                        <li>
                          <strong>{t('views.tutorial.playList.sellNori.content.lt8')}</strong>
                          <p>
                            {t('views.tutorial.playList.sellNori.content.lp8')}
                          </p>
                        </li>
                      </ol>
                    </p>
                  </div>
                  <div className="illustrated">
                    <img src={sellNori} alt="" />
                    <p>
                      <h3>{t('views.tutorial.playList.sellNori.content.t2')}</h3>
                      {t('views.tutorial.playList.sellNori.content.p2')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="competition">
            <input type="radio" id="liste2" name="liste" />
            <label htmlFor="liste2"><div>{t('views.tutorial.competition')}</div><div className="cross"></div></label>
            <div className="content">
              <div className="illustrated">
                <img src={competition} alt="" />
                <p>
                  {t('views.tutorial.competitionList.p')}
                  <h4>{t('views.tutorial.competitionList.t1')}</h4>
                  {t('views.tutorial.competitionList.p1')}
                  <h4>{t('views.tutorial.competitionList.t2')}</h4>
                  {t('views.tutorial.competitionList.p2')}
                  <h4>{t('views.tutorial.competitionList.t3')}</h4>
                  {t('views.tutorial.competitionList.p3')}
                  <h4>{t('views.tutorial.competitionList.t4')}</h4>
                  {t('views.tutorial.competitionList.p4')}
                  <h4>{t('views.tutorial.competitionList.t5')}</h4>
                  {t('views.tutorial.competitionList.p5')}
                </p>
              </div>
              <div className="illustrated">
                <p>
                  <h3>{t('views.tutorial.competitionList.t6')}</h3>
                  {t('views.tutorial.competitionList.p6')}
                </p>
                <img src={buildNoriComplete} alt="buildNori" />
              </div>
              <div className="">
                <p>
                  <h3>{t('views.tutorial.competitionList.l')} </h3>
                  <ol className='alternating-colors'>
                    <li>
                      <strong>{t('views.tutorial.competitionList.lt1')}</strong>
                      <p>
                        {t('views.tutorial.competitionList.lp1')}
                      </p>
                    </li>
                    <li>
                      <strong>{t('views.tutorial.competitionList.lt2')}</strong>
                      <p>
                        {t('views.tutorial.competitionList.lp2')}
                      </p>
                    </li>
                    <li>
                      <strong>{t('views.tutorial.competitionList.lt3')}</strong>
                      <p>
                        {t('views.tutorial.competitionList.lp3')}
                      </p>
                    </li>
                    <li>
                      <strong>{t('views.tutorial.competitionList.lt4')}</strong>
                      <p>
                        {t('views.tutorial.competitionList.lp4')}
                      </p>
                    </li>
                    <li>
                      <strong>{t('views.tutorial.competitionList.lt5')}</strong>
                      <p>
                        {t('views.tutorial.competitionList.lp5')}
                      </p>
                    </li>
                    <li>
                      <strong>{t('views.tutorial.competitionList.lt6')}</strong>
                      <p>
                        {t('views.tutorial.competitionList.lp6')}
                      </p>
                    </li>
                    <li>
                      <strong>{t('views.tutorial.competitionList.lt7')}</strong>
                      <p>
                        {t('views.tutorial.competitionList.lp7')}
                      </p>
                    </li>
                    <li>
                      <strong>{t('views.tutorial.competitionList.lt8')}</strong>
                      <p>
                        {t('views.tutorial.competitionList.lp8')}
                      </p>
                    </li>
                  </ol>
                </p>
              </div>
              <div className="illustrated">
                <img src={probaControl2} alt="" />
                <p>
                  <h3>{t('views.tutorial.competitionList.t7')}</h3>
                  {t('views.tutorial.competitionList.p7')}
                </p>
              </div>
              <div className="illustrated">
                <p>
                  <h3>{t('views.tutorial.competitionList.t8')}</h3>
                  {t('views.tutorial.competitionList.p8')}
                </p>
                <img src={compliance} alt="" />
              </div>
              <div className="illustrated">
                <img src={pFactor} alt="" />
                <p>
                  <h3>{t('views.tutorial.competitionList.t9')}</h3>
                  {t('views.tutorial.competitionList.p9')}
                </p>
              </div>
            </div>
          </div>
          <div className="winners">
            <input type="radio" id="liste3" name="liste" />
            <label htmlFor="liste3"><div>{t('views.tutorial.winners')}</div><div className="cross"></div></label>
            <div className="content">
              <div className="illustrated">
                <img src={winner} alt="winner" />
                <p>
                  {t('views.tutorial.winnersList.p')}
                  <h4>{t('views.tutorial.winnersList.t1')}</h4>
                  {t('views.tutorial.winnersList.p1')}
                  <h4>{t('views.tutorial.winnersList.t2')}</h4>
                  {t('views.tutorial.winnersList.p2')}
                  <h4>{t('views.tutorial.winnersList.t3')}</h4>
                  {t('views.tutorial.winnersList.p3')}
                  <h4>{t('views.tutorial.winnersList.t4')}</h4>
                  {t('views.tutorial.winnersList.p4')}
                </p>
              </div>
              <div className="illustrated">
                <p>
                  <h4>{t('views.tutorial.winnersList.t5')}</h4>
                  {t('views.tutorial.winnersList.p5')}
                </p>
                <img src={winnersTickets} alt="winnersTickets" />
              </div>
              <div className="illustrated">
                <img src={noriCompetitionWinners} alt="noriCompetitionWinners" />
                <p>
                  <h4>{t('views.tutorial.winnersList.t6')}</h4>
                  {t('views.tutorial.winnersList.p6')}
                </p>
              </div>
              <div className="illustrated">
                <p>
                  <h4>{t('views.tutorial.winnersList.t7')}</h4>
                  {t('views.tutorial.winnersList.p7')}
                </p>
                <img src={winerStrategyDetails} alt="winerStrategyDetails" />
              </div>
            </div>
          </div>
          <div className="statistics">
            <input type="radio" id="liste4" name="liste" />
            <label htmlFor="liste4"><div>{t('views.tutorial.statistics')}</div><div className="cross"></div></label>
            <div className="content">
              <div className="illustrated">
                <div className='img'>
                  <img src={statistics} alt="statistics" />
                  <img src={statistics2} alt="statistics" />
                </div>
                <p>
                  <h3>{t('views.tutorial.statisticsList.t1')}</h3>
                  {t('views.tutorial.statisticsList.p1')}
                  <h4>{t('views.tutorial.statisticsList.t2')}</h4>
                  {t('views.tutorial.statisticsList.p2')}
                  <h4>{t('views.tutorial.statisticsList.t3')}</h4>
                  {t('views.tutorial.statisticsList.p3')}
                  <h4>{t('views.tutorial.statisticsList.t4')}</h4>
                  {t('views.tutorial.statisticsList.p4')}
                  <h4>{t('views.tutorial.statisticsList.t5')}</h4>
                  {t('views.tutorial.statisticsList.p5')}
                </p>
              </div>
            </div>
          </div>
          <div className="myGames">
            <input type="radio" id="liste5" name="liste" />
            <label htmlFor="liste5"><div>{t('views.tutorial.myGames')}</div><div className="cross"></div></label>
            <div className="content">
              <div className="illustrated">
                <img src={myGame} alt="myGame" />
                <p>
                  {t('views.tutorial.myGamesList.p')}
                </p>
              </div>
              <div className="illustrated">
                <p>
                  <h4>{t('views.tutorial.myGamesList.t1')}</h4>
                  {t('views.tutorial.myGamesList.p1')}
                </p>
                <img src={myGame} alt="myGame" />
              </div>
              <div className="illustrated">
                <img src={myGame} alt="myGame" />
                <p>
                  <h4>{t('views.tutorial.myGamesList.t2')}</h4>
                  {t('views.tutorial.myGamesList.p2')}
                </p>
              </div>
              <div className="illustrated">
                <p>
                  <h4>{t('views.tutorial.myGamesList.t3')}</h4>
                  {t('views.tutorial.myGamesList.p3')}
                </p>
                <img src={myGame} alt="myGame" />
              </div>
              <div className="illustrated">
                <img src={myGame} alt="myGame" />
                <p>
                  <h4>{t('views.tutorial.myGamesList.t4')}</h4>
                  {t('views.tutorial.myGamesList.p4')}
                </p>
              </div>
            </div>
          </div>
          <div className="liveDraw">
            <input type="radio" id="liste6" name="liste" />
            <label htmlFor="liste6"><div>{t('views.tutorial.liveDrawing')}</div><div className="cross"></div></label>
            <div className="content">
              <div className="illustrated">
                <img src={liveDraw} alt="liveDraw" />
                <p>
                  {t('views.tutorial.liveDrawingList.p')}
                  <h4>{t('views.tutorial.liveDrawingList.t1')}</h4>
                  {t('views.tutorial.liveDrawingList.p1')}
                  <h4>{t('views.tutorial.liveDrawingList.t2')}</h4>
                  {t('views.tutorial.liveDrawingList.p2')}
                  <h4>{t('views.tutorial.liveDrawingList.t3')}</h4>
                  {t('views.tutorial.liveDrawingList.p3')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer isVisibleBall={false} />

    </>
  )
}

export default Tutorial
