/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Range, getTrackBackground } from 'react-range'

const STEP = window.innerWidth < 1200 ? 7 : 1
const MIN = window.innerWidth < 1200 ? 0 : 1
const MAX = window.innerWidth < 1200 ? 42.5 : 42

const Ranger = (props1) => {
  const onSliderValChange = (e) => {
    const filterArr = { ...props1.filter }
    filterArr.range = e.values
    props1.setFilter(filterArr)
    if (!props1.modal) props1.toggle(!props1.modal)
  }

  return (
    <div className="range-slider-sec statics-range" >
      <Range
          values={props1?.filter?.range}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => onSliderValChange({ values })}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '8px',
                background: '#417cff',
                borderRadius: '8px'
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '5px',
                  width: '100%',
                  borderRadius: 'px',
                  background: getTrackBackground({
                    values: props1?.filter?.range,
                    colors: ['#000', '#000'],
                    min: MIN,
                    max: MAX
                  }),
                  alignSelf: 'center',
                  cursor: 'pointer'
                }}
              >
                {children}
              </div>
              <ul>
              {Array.from(Array(window.innerWidth < 1200 ? 7 : 42), (op, i) =>
                <li key={window.innerWidth < 1200 ? (i) * 7 : i + 1}>{window.innerWidth < 1200 ? (i) * 7 : i + 1}</li>
              )}
              </ul>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              className='rangehandle'
              style={{
                ...props.style,
                style: {
                  width: '4px',
                  height: '16px',
                  outline: 'none',
                  borderRadius: '9px',
                  background: '#fff',
                  padding: '0',
                  border: 'solid 1px #fff',
                  transform: 'translateX(0) !important'
                }
              }}
            >
              <div
                style={{
                  height: '16px',
                  width: '6px',
                  borderRadius: '10px',
                  backgroundColor: isDragged ? '#548BF4' : '#CCC'
                }}
              />
            </div>
          )}
        />
    </div>
  )
}

Ranger.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  filter: PropTypes.object,
  setFilter: PropTypes.func
}

export default Ranger
