import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap'
import CanvasJSReact from '../assets/js/canvasjs.react'

import '../assets/sass/views/footer.scss'
import '../assets/sass/views/common.scss'

import closeicon from '../assets/images/close-icon.svg'
import { useTranslation } from 'react-i18next'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

const GraphModal = (props) => {
  const { t } = useTranslation('global')
  const yLabels = ['Never', 'Scarcely', 'Rare', 'Medium', 'Often']
  const options1 = {
    theme: 'light2',
    animationEnabled: true,
    exportEnabled: true,
    backgroundColor: 'rgba(0, 0, 0, 1)',
    colorSet: '#49B9FF',
    color: '#fff',
    axisX: {
      title: '',
      gridThickness: 0,
      labelFontColor: 'white',
      minimum: 1,
      maximum: 42,
      viewportMinimum: 1,
      labelFontSize: screen.width < 992 ? 11 : 14,
      interval: screen.width < 992 ? 1 : 1
    }
  }
  const containerProps1 = {
    height: '300px',
    width: '100%'
  }
  options1.data = [
    {
      type: 'area',
      color: '#417cff',
      xValueFormatString: 'Numbers #,##0.##',
      yValueFormatString: 'Frequency #,##0.##',
      dataPoints: props.graphData
    }
  ]
  options1.axisY = {
    title: '',
    gridThickness: 1,
    lineThickness: 1,
    gridColor: 'rgba(20, 121, 184, 0.4)',
    labelFontSize: screen.width < 992 ? 11 : 14,
    labelFontColor: 'white',
    includeZero: true,
    interval: props.rangeYaxis,
    labelFormatter: function (e) {
      const yCats = yLabels[Math.floor(e.value / props.rangeYaxis)]
      return yCats
    }
  }
  return (
    <>
      <Modal isOpen={props.modal} toggle={props.toggle} className='chart-modal'>
        <ModalBody>
          <div className='common-modal-sec'>
            <button onClick={props.toggle} className='common-close-btn'><img src={closeicon} /></button>
            <p className='chart-numbers'>{t('component.graphModel.title')} {props.selectedRange[0]} draws</p>
            <div className='chart-inner-sec'>
              <CanvasJSChart containerProps={containerProps1} options={options1} />
            </div>
            <p className='graph-vertical-title'>{t('component.graphModel.Frequency')}</p>
            <p className='graph-horizontal-title'>{t('component.graphModel.Numbers')}</p>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

GraphModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  graphData: PropTypes.array,
  rangeYaxis: PropTypes.number,
  selectedRange: PropTypes.array
}

export default GraphModal
