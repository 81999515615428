/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
const style = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  cursor: 'move',
  float: 'left'
}
let element = ''
let element2 = ''
let element3 = ''
let element4 = ''
let element5 = ''
let element6 = ''
let element7 = ''
let element8 = ''

const finalContainer = { left: [], right: [] }
const Box = function Box ({ name, container, className, clickFn }) {
  const [containerNum, setContainerNum] = useState(finalContainer)
  const [dropped, setDropped] = useState(false)
  function wrapHandleClick (event) {
    clickFn(Number(event.target.textContent))
  }

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.BOX,
    item: { name },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (dropResult === null) setDropped(false)
      else if (dropResult?.dropEffect === 'move') setDropped(true)
      if (item && dropResult) {
        const mainContainer = { ...containerNum }
        if (container === 2) {
          if (!mainContainer.left.includes(item.name)) mainContainer.left.push(item.name)
          setContainerNum(mainContainer)
        }
        if (container === 1) {
          if (!mainContainer.right.includes(item.name)) mainContainer.right.push(item.name)
          setContainerNum(mainContainer)
        }
      }
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId()
      }
    }
  }))
  element?.classList?.remove('active')
  element2?.classList?.remove('active')
  element3?.classList?.remove('active')
  element4?.classList?.remove('active')
  element5?.classList?.remove('active')
  element6?.classList?.remove('active')
  element7?.classList?.remove('active')
  element8?.classList?.remove('active')
  const opacity = isDragging ? 0.4 : 1
  if (container === 1 && isDragging) {
    element = document.getElementById('dust2')
    element?.classList?.add('active')
    element3 = document.getElementById('dust4')
    element3?.classList?.add('active')
  } else if (container === 2 && isDragging) {
    element2 = document.getElementById('dust1')
    element2?.classList?.add('active')
    element4 = document.getElementById('dust3')
    element4?.classList?.add('active')
  } else if (container === 3 && isDragging) {
    element5 = document.getElementById('dust6')
    element5?.classList?.add('active')
    element7 = document.getElementById('dust8')
    element7?.classList?.add('active')
  } else if (container === 4 && isDragging) {
    element6 = document.getElementById('dust5')
    element6?.classList?.add('active')
    element8 = document.getElementById('dust7')
    element8?.classList?.add('active')
  }
  return (
    <>
    {dropped
      ? <><div className={className} onClick={wrapHandleClick} onDragEnd={wrapHandleClick} ref={drag} style={{ ...style, opacity }} data-testid={'box'}>
      {name}
    </div><br/></>
      : <><div className={className} onClick={wrapHandleClick} ref={drag} style={{ ...style, opacity }} data-testid={'box'}>
    {name}
  </div><br/></>
    }
    </>
  )
}

export default Box
