import React from 'react'
import PropTypes from 'prop-types'
import infoicon from '../../../assets/images/info-icon.png'
import numberbg from '../../../assets/images/numbers-bg.png'
import { useTranslation } from 'react-i18next'

const SecondComponent = (props) => {
  const { t } = useTranslation('global')
  const selMandatoryNumber = (number) => {
    const result = [...props.steps]
    const finalArr = result.find((x) => x.id === 2 && !x.selectedNum.includes(number))
    if (finalArr === undefined) {
      result[2].selectedNum.splice(props.steps[2].selectedNum.indexOf(number), 1)
    } else {
      if (result[2].selectedNum.length < 6) {
        result[2].selectedNum.push(number)
        result[5].selAppPartiNum.splice(result[5].selAppPartiNum.indexOf(number), 1)
        result[7].selBlockWiseNum.map((p) => !p.numbers.includes(number) ? p.numbers.push(number) : '')
      }
    }
    props.setPrevActiveStep(false)
    props.setSteps(result)
  }
  return <div>

    <div className='common-step-box'>
      <div className='text-center'>
        <h4 className='common-title'>{t('views.nori.step2.title')}<div className='tooltip-heading d-inline-block'>
          <img src={infoicon} className="infoicon" />
          <span className='tooltiptext'>{t('views.nori.step2.specify')}</span>
        </div>
        </h4>
        <p>{t('views.nori.step2.select')}</p>
        <div className='nori-mandetory-ticket-sec'>
          <div className='nori-ticket-sec min-tab-unset'>

            <img src={numberbg} />
            <ul className='mandetory-number-list'>
              {Array.from(Array(42), (op, i) =>
                props.steps[4].isSelected && props.steps[4].selectedExcludeNum.includes(i + 1)
                  ? <><li key={i + 1} className='red'>{i + 1}</li><br /></>
                  : props.steps[6].isSelected && props.steps[6].selPriGrpNum.includes(i + 1) === true
                    ? <><li key={i + 1} className='yellow'>{i + 1}</li><br /></>
                    : props.steps[2].isSelected && props.steps[2].selectedNum.length > 5 && !props.steps[2].selectedNum.includes(i + 1)
                      ? <><li key={i + 1} className='disabled'>{i + 1}</li><br /></>
                      : <><li onClick={() => selMandatoryNumber(i + 1)} key={i + 1} className={props.steps[2].isSelected && props.steps[2].selectedNum.includes(i + 1) === true ? 'active' : ''}><a key={i + 1}>{i + 1}</a></li><br /></>
              )}
            </ul>
          </div>
          <div className='mandetory-number-footer'>
            <div>
              <span>{props.steps[2].selectedNum.length}/6</span> {t('views.nori.step2.numbers')}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

SecondComponent.propTypes = {
  steps: PropTypes.array,
  setSteps: PropTypes.func,
  setPrevActiveStep: PropTypes.func
}

export default SecondComponent
