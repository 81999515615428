import Axios from '../../axios'

export async function primeResp (filterData, activeStep) {
  const response = await Axios.post('/nora642/statistics/prime', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    },
    prc: activeStep
  })
  return response
}

export async function distanceResp (filterData, activeStep) {
  const response = await Axios.post('/nora642/statistics/distance', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    },
    rpt: activeStep
  })
  return response
}

export async function lengthResp (filterData) {
  const response = await Axios.post('/nora642/statistics/length', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    }
  })
  return response
}

export async function averageResp (filterData) {
  const response = await Axios.post('/nora642/statistics/average', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    }
  })
  return response
}

export async function unitResp (filterData, activeStep, activeSubStep) {
  const response = await Axios.post('/nora642/statistics/unit', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    },
    prc: activeSubStep === 11 ? 1 : activeSubStep === 12 ? 2 : 3,
    noc: activeStep
  })
  return response
}

export async function unitDFResp (filterData) {
  const response = await Axios.post('/nora642/statistics/unitDF', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    }
  })
  return response
}

export async function evenNoResp (filterData, activeStep) {
  const response = await Axios.post('/nora642/statistics/even', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    },
    nba: activeStep,
    dw: true
  })
  return response
}

export async function oddNoResp (filterData, activeStep) {
  const response = await Axios.post('/nora642/statistics/odd', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    },
    nba: activeStep,
    dw: true
  })
  return response
}
