import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap'
import { getResTicketDetail } from '../../query/walletconnect/walletconnect.query'
import WinnerDetailModal from './WinnerDetailModal'
import { useTranslation } from 'react-i18next'

import closeicon from '../../assets/images/close-icon.svg'

import '../../assets/sass/views/MyGames.scss'

const ResultDetailsModal = (props) => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const [detailId, setDetailId] = useState({ id: '' })
  const [detailData, setDetailData] = useState({ myGameResultData: [], drawDate: '', ID: '', from: 'res' })
  const [winnerDetailModal, setWinnerDetailModal] = useState(false)

  const winnerDetailToggle = () => {
    setWinnerDetailModal(!winnerDetailModal)
  }
  useQuery(['get-res-details', detailId], () => getResTicketDetail(detailId.id), {
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setDetailData({ ...detailData, myGameResultData: data, drawDate: props?.detailData?.ddt, ID: detailId.id, from: 'res' })
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: 'Internal Server Error' } })
    }
  })
  const handleViewDetails = (selectedId) => {
    setDetailId({ ...detailId, id: selectedId })
    winnerDetailToggle()
  }
  return (
    <>
      <Modal toggle={props.toggle} className='settings-popup result-popup' id='settings-popup' isOpen={props.modal}>
        <ModalBody>
          <div className='common-modal-sec'>
            <button onClick={props.toggle} className='common-close-btn'><img src={closeicon} /></button>
            <h4 className='common-title'>{t('views.myGame.resultDetails.title')}</h4>
            <div className='result-popup-header'>
              <div className='result-popup-header-data'>
                <h5>{t('views.myGame.resultDetails.drawDate')}<strong>{`${new Date(props?.detailData?.ddt).getUTCDate() < 10 ? '0' + new Date(props?.detailData?.ddt).getUTCDate() : new Date(props?.detailData?.ddt).getUTCDate()}/${(new Date(props?.detailData?.ddt).getMonth() + 1) < 10 ? '0' + (new Date(props?.detailData?.ddt).getMonth() + 1) : new Date(props?.detailData?.ddt).getMonth() + 1}/${new Date(props?.detailData?.ddt).getFullYear()}`}</strong></h5>
              </div>
              <div className='result-popup-header-data'>
                <h5>{t('views.myGame.resultDetails.win')}<strong>{`${props?.detailData?.win} BNB`}</strong></h5>
              </div>
            </div>
            <div className='common-winning-sec'>
              <h5>{t('views.myGame.resultDetails.winTicket')}</h5>
              {props?.detailData?.wcs?.length
                ? <ul className='winning-list result-winning-list'>
                  {props?.detailData?.wcs?.map((main, i) =>
                    <li key={i}>
                      <ul>
                        {main?.cb?.map((sub, j) => <li key={j} className={main?.wni?.includes(j) ? 'green' : 'white'}>{sub}</li>)}
                      </ul>
                    </li>
                  )}
                </ul>
                : <p>{t('views.myGame.resultDetails.noRecord')}</p>
              }
            </div>
            <div className='common-winning-sec'>
              <h5>{t('views.myGame.resultDetails.winNori')}</h5>
              {props?.detailData?.wns?.length
                ? <ul className='winning-noris-list'>
                  {props?.detailData?.wns?.map((data, i) =>
                    <li key={i}><span>{t('views.myGame.resultDetails.winClass')}&nbsp; {data?.wcl}</span><a className='common-white-btn small-btn delete-ticket-btn' onClick={() => handleViewDetails(data?.nid)}><span>{t('views.myGame.resultDetails.viewDetails')}</span></a></li>
                  )}
                </ul>
                : <p>{t('views.myGame.resultDetails.noRecord')}</p>
              }
            </div>
          </div>
        </ModalBody>
      </Modal>
      <WinnerDetailModal toggle={winnerDetailToggle} modal={winnerDetailModal} detailData={detailData} />
    </>
  )
}

ResultDetailsModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  detailData: PropTypes.object,
  resId: PropTypes.string
}

export default ResultDetailsModal
