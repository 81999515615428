import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import {
  TabContent, TabPane, Nav,
  NavItem, NavLink
} from 'reactstrap'
import { checkAffiliateData, saveCode, getAffiliateAutoGenerate, getMyGameTickets, getTicketDetail, deleteResp, getCompTickets, getResTickets, deleteResResp, getClaimAllResp, deleteCompResp } from '../../query/walletconnect/walletconnect.query'
// import { getAffiliatesData } from '../../query/affiliates/affiliates.query'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import Sidebar from '../../components/Sidebar'
// import Loading from '../../components/Loader'
import blueballimg from '../../assets/images/blue-ball-img.png'
import userIcon from '../../assets/images/user-icon.png'
import copyIcon from '../../assets/images/copy-icon.svg'
import DataTable from 'react-data-table-component'
import classnames from 'classnames'
import infoicon from '../../assets/images/info-img.svg'
import redballimg from '../../assets/images/red-ball-img.png'
import bnb from '../../assets/images/bnb.svg'
import '../../assets/sass/views/affiliates.scss'
import cookies, { getUserToken } from '../../cookies'
import { useTranslation } from 'react-i18next'

import { useDisconnect, useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'

const LoadingOverlay = () => (
    <div className='loader-contain'>
      <div className="loader"></div>
    </div>
)

const Affiliates = () => {
  const { t } = useTranslation('global')
  const { open: openWallet } = useWeb3Modal()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [loading, setLoading] = useState({ status: true })
  const [mgId, setMgId] = useState('')
  const [mgTicketData, setMgTicketData] = useState([])
  const [compTicketData, setCompTicketData] = useState([])
  const [resTicketData, setResTicketData] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [mgSelectedRows, setMgSelectedRows] = useState([])
  const [compSelectedRows, setCompSelectedRows] = useState([])
  const [currentActiveTab, setCurrentActiveTab] = useState(state !== null && state?.tab !== '1' ? state?.tab : '1')
  const [generatedCode, setGeneratedCode] = useState('')
  const [usersWallet, setUserSwallet] = useState(t('views.Affiliates.identity'))
  const [hasAffiliatesCode, setHasAffiliatesCode] = useState(false)
  // const [hasCode, setHasCode] = useState(true)
  const [popUpModal, setPopUpModal] = useState(false)
  const [gameModal, setGameModal] = useState(false)
  const [compModal, setCompModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const [valideBottom, setValideBottom] = useState(false)
  const [notification, setNotification] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const connectDisconnectWallet = async () => {
    resetValues()
    if (isConnected) {
      await disconnectWallet()
    } else {
      await openWallet()
    }
  }
  /* loading start */
  const [isLoading, setIsLoading] = useState(true)
  const [dataLoaded, setDataLoaded] = useState(true)
  useEffect(() => {
    const loadData = async () => {
      setDataLoaded(false)
    }
    loadData()
  }, [])
  useEffect(() => {
    if (dataLoaded) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
    // console.log('isLoading: ', isLoading)
    // console.log('dataLoaded: ', dataLoaded)
  }, [dataLoaded])

  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab)
    setLoading({ ...loading, status: true })
    setTimeout(() => {
      setLoading({ ...loading, status: false })
    }, 500)
  }
  const popUpToggle = () => {
    return setPopUpModal(!popUpModal)
  }
  const gameToggle = () => {
    return setGameModal(!gameModal)
  }
  const compToggleToggle = () => {
    return setCompModal(!compModal)
  }
  const deleteToggle = () => {
    return setDeleteModal(!deleteModal)
  }

  const handleMgChange = (mgSelectedRows) => {
    setMgSelectedRows(mgSelectedRows)
  }
  const handleCompChange = (compSelectedRows) => {
    setCompSelectedRows(compSelectedRows)
  }
  const handleClearRows = (no, flag) => {
    popUpToggle()
    if (flag) {
      if (no === 1) {
        if (currentActiveTab === '1') deleteRefetch()
        if (currentActiveTab === '2') deleteCompRefetch()
        if (currentActiveTab === '3') deleteResRefetch()
      } else if (no === 2) {
        claimAllRefetch()
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading({ ...loading, status: false })
    }, 500)
  }, [])

  useQuery(['get-affiliates-details', currentActiveTab], () => getMyGameTickets(currentActiveTab), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setMgTicketData([])
      /* setMgTicketData(data)
      setMgTicketData([{
        goID: 1,
        fdt: '2023-10-01T12:00:00Z',
        ldt: 12.34,
        usg: [5, 10, 15, 20, 25]
      },
      {
        goID: 2,
        fdt: '2023-10-02T12:00:00Z',
        ldt: 23.45,
        usg: [10, 15, 20, 25, 30]
      },
      {
        goID: 3,
        fdt: null,
        ldt: null,
        usg: null
      }]) */
    },
    onError: (error) => {
      if (error.request.status === 401) console.log('true')
      else navigate('/error', { state: { errorMsg: t('views.affiliates.error') } })
    }
  })

  useQuery(['get-details', mgId], () => getTicketDetail(mgId), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.affiliates.error') } })
    }
  })

  const { refetch: deleteRefetch } = useQuery('delete-refetch', () => deleteResp(mgSelectedRows.selectedRows), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const finalData = mgTicketData?.length ? [...mgTicketData] : []
      const filterData = finalData.filter((d) => !data.includes(d.goID))
      setMgTicketData(filterData)
      deleteToggle()
    }
  })

  useQuery(['get-comp', currentActiveTab], () => getCompTickets(currentActiveTab), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setCompTicketData([])
      /* setCompTicketData(data)
      setCompTicketData([{
        norID: 1,
        ddt: '2023-10-01T12:00:00Z',
        nsc: 12.34,
        nws: [5, 10, 15, 20, 25]
      },
      {
        norID: 2,
        ddt: '2023-10-02T12:00:00Z',
        nsc: 23.45,
        nws: [10, 15, 20, 25, 30]
      },
      {
        norID: 3,
        ddt: null,
        nsc: null,
        nws: null
      }]) */
      // console.log('compticketdata: ', data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.affiliates.error') } })
    }
  })

  const { refetch: deleteCompRefetch } = useQuery('delete-comp-refetch', () => deleteCompResp(compSelectedRows.selectedRows), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const finalData = compTicketData?.length ? [...compTicketData] : []
      const filterData = finalData.filter((d) => !data.includes(d.norID))
      setCompTicketData(filterData)
      // console.log(filterData)
      deleteToggle()
    }
  })

  useQuery(['get-result', currentActiveTab], () => getResTickets(currentActiveTab), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setResTicketData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.affiliates.error') } })
    }
  })

  const { refetch: deleteResRefetch } = useQuery('delete-res-refetch', () => deleteResResp(), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const finalData = resTicketData?.length ? [...resTicketData] : []
      const filterData = finalData.filter((d) => !data.includes(d.rid))
      setResTicketData(filterData)
      deleteToggle()
    }
  })

  const { refetch: claimAllRefetch } = useQuery('claim-all-refetch', () => getClaimAllResp(), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const finalData = resTicketData?.length ? [...resTicketData] : []
      const filterData = finalData.map((d) => {
        if (data.includes(d.rid)) d.clm = true
        return d
      })
      setResTicketData(filterData)
      deleteToggle()
    }
  })

  const handleMgDetailClick = (id) => {
    setMgId(id)
    gameToggle()
  }
  const handleCompDetailClick = (id) => {
    compToggleToggle()
  }

  const rows = mgTicketData?.map((mg) => {
    const mgArr = {}
    mgArr.id = mg.goID
    mgArr.fd = mg?.fdt !== null ? `${new Date(mg.fdt).getUTCDate() < 10 ? '0' + new Date(mg.fdt).getUTCDate() : new Date(mg.fdt).getUTCDate()}/${(new Date(mg.fdt).getMonth() + 1) < 10 ? '0' + (new Date(mg.fdt).getMonth() + 1) : new Date(mg.fdt).getMonth() + 1}/${new Date(mg.fdt).getFullYear()}` : '-'
    mgArr.ld = mg?.ldt !== null ? `${new Date(mg.ldt).getUTCDate() < 10 ? '0' + new Date(mg.ldt).getUTCDate() : new Date(mg.ldt).getUTCDate()}/${(new Date(mg.ldt).getMonth() + 1) < 10 ? '0' + (new Date(mg.ldt).getMonth() + 1) : new Date(mg.ldt).getMonth() + 1}/${new Date(mg.ldt).getFullYear()}` : '-'
    mgArr.usages = mg.usg
    mgArr.details = <a onClick={() => handleMgDetailClick(mg.goID)} ><img src={infoicon} /></a>
    return mgArr
  })
  const affiliatesColumns = [
    {
      id: 1,
      name: t('views.affiliates.date'),
      selector: (row) => row.fd,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 2,
      name: t('views.affiliates.identity'),
      selector: (row) => row.ld,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: <>{t('views.affiliates.statut')} <a> <span> </span><img src={infoicon} /></a></>,
      selector: (row) => row.usages,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.affiliates.details'),
      selector: (row) => row.details,
      sortable: false,
      reorder: false,
      center: true,
      width: '150px'
    }
  ]
  const setCompt = () => {
    // console.log('CompticketDatas: ', compTicketData)
  }
  setCompt()
  const noriRows = compTicketData?.map((mg) => {
    const mgArr = {}
    mgArr.id = mg.norID
    mgArr.dd = mg.ddt
    mgArr.score = mg.nsc
    mgArr.weight = mg.nws
    mgArr.details = <a onClick={() => handleCompDetailClick(mg?.norID)} ><img src={infoicon} /></a>
    return mgArr
  })
  // console.log('NoriRows: ', noriRows)
  // console.log('Rows: ', rows)
  const activitiesColumns = [
    {
      id: 1,
      name: t('views.affiliates.identity'),
      selector: (row) => row.dd,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 2,
      name: t('views.affiliates.nextDrawDate'),
      selector: (row) => row.score,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.affiliates.payed'),
      selector: (row) => row.weight,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.affiliates.toPay'),
      selector: (row) => row.details,
      sortable: false,
      reorder: false,
      center: true,
      width: '150px'
    }
  ]

  const paginationComponentOptions = {
    noRowsPerPage: true
  }
  const fetchData = async (page, no) => {
    const data = no === 1 ? [...mgTicketData] : no === 2 ? [...compTicketData] : [...resTicketData]
    const result = []
    for (let i = (9 * (page - 1)); i <= (page * 9) - 1; i++) {
      result.push(data[i])
    }
  }
  const handlePageChange = (page, no) => {
    fetchData(page, no)
  }

  const { disconnect } = useDisconnect()

  const disconnectWallet = async () => {
    await disconnect()
    resetValues()
    cookies.remove('sWalletAddress', { path: '/' })
    cookies.remove('userToken', { path: '/' })
  }

  // Get Address
  const { isConnected } = useWeb3ModalAccount()

  const [popupStyle, setPopupStyle] = useState({ display: 'none' })
  const handleCopy = async (event) => {
    const textToCopy = `https://www.nora642.com?code=${generatedCode}` // Récupère le texte du span cliqué
    try {
      await navigator.clipboard.writeText(textToCopy) // Copie le texte dans le presse-papiers
      const { clientX, clientY } = event
      setPopupStyle({
        display: 'block',
        left: `${clientX}px`,
        top: `${clientY}px`
      })
      setShowPopup(true)
      setNotification(t('views.affiliates.copie')) // Affiche la notification
      setTimeout(() => {
        setNotification('') // Cache la notification après 2 secondes
        setShowPopup(false)
      }, 2000) // Indiquer que le texte a été copié
    } catch (err) {
      console.error(t('views.affiliates.noCopie'), err)
    }
  }
  const [totalAmount, setTotalAmount] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const { mutate: validateCodeMutation } = useMutation({
    mutationFn: (data) => {
      return saveCode(data)
    },
    onSuccess: (data) => {
      // console.log('Code saved successfully', data)
      setGeneratedCode(inputValue)
      setHasAffiliatesCode(true)
    },
    onError: (error) => {
      console.error('Error saving Code:', error)
      setHasAffiliatesCode(false)
    }
  })
  const handleValidate = () => {
    setDataLoaded(true)
    validateCodeMutation({ cd: inputValue })
    // alert(`Validé : ${inputValue}`)
  }
  const resetValues = () => {
    setHasAffiliatesCode(false)
    setValideBottom(false)
    setShowPopup(false)
    setGeneratedCode('')
    setUserSwallet('')
    setNotification('')
    setTotalAmount(0)
  }
  const { mutate: checkAffliate } = useMutation({
    mutationFn: checkAffiliateData,
    onSuccess: (data) => {
      // console.log('data.data: ', data.data)
      if (data?.status === 204) {
        setHasAffiliatesCode(false)
        // setHasCode(false)
        // console.log('No affiliate data ')
        // console.log('hasCode Value: ', hasCode)
        // console.log(data)
      } else if (data.data?.usr && !data.data?.cd) {
        setUserSwallet(data.data?.usr)
      } else {
        // console.log(data)
        // const cd = data ? data.data?.cd : ''
        // const usr = data ? data.data?.usr : ''
        setGeneratedCode(data.data?.cd)
        setUserSwallet(data.data?.usr)
        // console.log('generatedCode11: ' + generatedCode + cd)
        // console.log('userSwallet11: ' + usersWallet + usr)
        setHasAffiliatesCode(true)
        // setHasCode(true)
      }
      setDataLoaded(false)
    },
    onError: (error) => {
      // navigate('/error', { state: { errorMsg: 'Internal Server Error' } })
      if (error.request.status === 401) {
        console.log('401: non connecté')
      } else {
        console.log('autre code: erreur inconnue: ', error.request.status)
        setShowPopup(true)
        setNotification(t('views.affiliates.error')) // Affiche la notification
        setTimeout(() => {
          setNotification('') // Cache la notification après 2 secondes
          setShowPopup(false)
        }, 2000)
      }
      setHasAffiliatesCode(false)
      setDataLoaded(false)
    }
  })
  const { mutate: getAutoGe } = useMutation({
    mutationFn: getAffiliateAutoGenerate,
    onSuccess: (data) => {
      // return data
      // console.log(data)
      const cd = data ? data.data?.cd : ''
      // const usr = data ? data.data?.usr : ''
      setGeneratedCode(cd)
      // setUserSwallet(usr)
      // console.log('generatedCode: ' + generatedCode)
      setShowPopup(true)
      setNotification(t('views.affiliates.codeGenereted')) // Affiche la notification
      setTimeout(() => {
        setNotification('') // Cache la notification après 2 secondes
        setShowPopup(false)
      }, 2000)
      setDataLoaded(false)
    },
    onError: (error) => {
      // navigate('/error', { state: { errorMsg: 'Internal Server Error' } })
      if (error.request.status === 401) console.log('401: non connecté')
      else if (error.request.status === 204) {
        console.log('204: gerenation erreur')
        setShowPopup(true)
        setNotification(t('views.affiliates.generationError')) // Affiche la notification
        setTimeout(() => {
          setNotification('') // Cache la notification après 2 secondes
          setShowPopup(false)
        }, 2000)
      } else {
        console.log('autre code: erreur inconnue')
        setShowPopup(true)
        setNotification(t('views.affiliates.error')) // Affiche la notification
        setTimeout(() => {
          setNotification('') // Cache la notification après 2 secondes
          setShowPopup(false)
        }, 2000)
      }
      setDataLoaded(false)
    }
  })
  const handleAutoGenerate = async () => {
    setDataLoaded(true)
    getAutoGe()
    // const generatedValue = Math.random().toString(36).substring(4, 8)
    // const generatedValue = generatedCode
    // console.log('inputValue.length ' + inputValue?.length)
    setInputValue(generatedCode)
    setValideBottom(true)
  }
  const loadin = () => {
    setDataLoaded(true)
  }
  const stopLoading = () => {
    setDataLoaded(false)
  }
  useEffect(() => {
    setInputValue(generatedCode)
  }, [generatedCode])
  useEffect(() => {
    // console.log('UseEffect hasCode: ', hasCode)
    if (isConnected) {
      loadin()
      const fetchData = async () => {
        try {
          checkAffliate()
          const userToken = getUserToken()
          if (userToken) {
            stopLoading()
          }
          // console.log('generatedCode1: ' + generatedCode)
          // console.log('UsersWallet2: ' + usersWallet)
        } catch (error) {
          console.error('Error fetching ticket price:', error)
        }
      }
      const intervalId = setInterval(() => {
        if (!hasAffiliatesCode) {
          const userToken = getUserToken()
          // console.log('hasCode: ', hasCode)
          // console.log('hasAffiliatesCode: ', hasAffiliatesCode)
          // console.log('getUserToken: ', userToken)
          if (userToken) {
            // console.log('UserToken is not null')
            fetchData()
            stopLoading()
          }
        } else {
          stopLoading()
          clearInterval(intervalId) // Arrêter l'intervalle si shouldFetch devient true
        }
      }, 5000) // Exécute toutes les 5 secondes
      return () => clearInterval(intervalId)
    } else {
      setDataLoaded(false)
      resetValues()
    }
  }, [isConnected, hasAffiliatesCode])
  const isValid = inputValue?.length >= 4 && inputValue?.length <= 10
  return (
      <>
        { /* loading.status ? <Loading addClass={'active'} /> : '' */ }
        {isLoading && <LoadingOverlay /> }
        <><Header activeTab={5} />
          <div className='statics-tabs-sec my-game-sec'>
            <Sidebar/>
            {/* <Sidebar sideActiveTab={4}/> */}
            {!isConnected
              ? <div className='statics-main-sec-holder'>
                <div className="notConnectedBG">
                  <img className='red' src={redballimg} alt="Ball" />
                  <img className='blue' src={blueballimg} alt="Ball" />
                  <div>
                    <div className='message'>
                      <h3>{t('global.infoConnect')}</h3>
                      <button className='connect' onClick={() => connectDisconnectWallet()}> <img src={bnb} alt='BNB' /> {t('connectWallet')}</button>
                    </div>
                  </div>
                </div>
              </div>
              : !hasAffiliatesCode
                  ? <div className='statics-main-sec my-games-main-sec'>
                    <img src={blueballimg} className='blueballimg' />
                    <div className="container">
                      <h3 className='not-generated'>{t('views.affiliates.NoCodeGenerated')}</h3><br/>
                      <div className="user-info row">
                        <div className="username col-12 col-md-6"><img src={userIcon} className='user-icon' />{usersWallet}</div>
                        <div className='input-field col-12 col-md-6 row'>
                          <label className='col-7 col-md-6' htmlFor="inputField">{t('views.affiliates.enterCode')} : </label>
                          <input
                              className='col-7 col-md-6'
                              type="text"
                              id="inputField"
                              value={inputValue}
                              onChange={(e) => { setInputValue(e.target.value.slice(0, 10)); setValideBottom(false) }} // Limite à 8 caractères
                          />
                        </div>
                      </div>
                      <div className='selection'>
                        <button className='ok-button' onClick={handleValidate} disabled={!isValid && !valideBottom}>{t('views.affiliates.validate')}</button>
                        <button className='ok-button' onClick={handleAutoGenerate}>{t('views.affiliates.autogenerate')}</button>
                      </div>
                      <p className='warning'>{isValid || valideBottom ? '' : t('views.affiliates.enterCaraters')}</p>
                    </div>
                  </div>
                  : <div className='statics-main-sec my-games-main-sec'>
                      <img src={blueballimg} className='blueballimg' />
                      <div className='container'>
                        <div className='user-info row'>
                          <div className='username col-12 col-md-6'>
                            <div><img src={userIcon} className='user-icon' />{usersWallet}</div>
                            <div className='total-amount'>
                              {t('views.affiliates.totalAmount')}: {totalAmount} BNB
                            </div>
                          </div>
                          <div className='usercode col-12 col-md-6'>
                            <h3 className='selection'>{t('views.affiliates.code')}: {generatedCode}</h3>
                            <div className='selection'>
                              <p className='d-link'>{t('views.affiliates.myLink')} : <span onClick={handleCopy} className='copy-text'>https://www.nora642.com?code={generatedCode}<img src={copyIcon} className='copy-icon' alt='copier'/></span></p>
                              { /* <p className='copie-link'>copier</p> */ }
                              {showPopup && (
                                  <div className="popup-message" style={popupStyle}>
                                    {notification}
                                  </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='text-center'>
                        <h1>{t('views.affiliates.affiliation')}</h1>
                      </div>
                      <div className='stacking-tabs'>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                                className={classnames({
                                  active:
                                      currentActiveTab === '1'
                                })}
                                onClick={() => { toggle('1') }}
                            >
                              {t('views.affiliates.affiliates')}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                                className={classnames({
                                  active:
                                      currentActiveTab === '2'
                                })}
                                onClick={() => { toggle('2') }}
                            >
                              {t('views.affiliates.activities')}
                            </NavLink>
                          </NavItem>

                        </Nav>
                      </div>
                      <TabContent activeTab={currentActiveTab}>
                        <TabPane tabId="1">
                          <div className='p-relative'>
                            <img src={redballimg} className='redballimg' />
                            <div className='statics-sec my-games-sec'>
                              <div className='statics-header mb-30 mygames-header'>
                                <h4 className='common-title'>{t('views.affiliates.myAffiliates')}</h4>
                                {mgSelectedRows?.selectedCount > 0
                                  ? <div>
                                    <a className='common-white-btn small-btn delete-ticket-btn mob-delet-btn' onClick={() => handleClearRows(1, false)}><span>{t('views.affiliates.delete')}</span></a>
                                  </div>
                                  : ''
                              }
                            </div>
                            <div className='common-table-sec my-games-table'>
                              <DataTable
                                  columns={affiliatesColumns}
                                  data={rows}
                                  pagination
                                  paginationPerPage={9}
                                  paginationComponentOptions={paginationComponentOptions}
                                  onChangeRowsPerPage={() => handlePageChange(1)}
                                  // selectableRows
                                  onSelectedRowsChange={(e) => handleMgChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <div className='p-relative'>
                          <img src={redballimg} className='redballimg' />
                          <div className='statics-sec my-games-sec'>
                            <div className='statics-header mb-30 mygames-header'>
                              <h4 className='common-title'>{t('views.affiliates.myActivities')}</h4>
                              {compSelectedRows?.selectedCount > 0
                                ? <div>
                                  <a className='common-white-btn small-btn delete-ticket-btn mob-delet-btn' onClick={() => handleClearRows(1, false)}><span>{t('views.affiliates.delete')}</span></a>
                                </div>
                                : ''
                                }
                              </div>
                              <div className='common-table-sec my-games-table'>
                                <DataTable
                                    columns={activitiesColumns}
                                    data={noriRows}
                                    pagination
                                    paginationPerPage={9}
                                    paginationComponentOptions={paginationComponentOptions}
                                    onChangeRowsPerPage={() => handlePageChange(2)}
                                    // selectableRows
                                    onSelectedRowsChange={(e) => handleCompChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>}
          </div>
          <Footer isVisibleBall={false} />
        </></>
  )
}

export default Affiliates
