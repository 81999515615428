import React from 'react'
import errorimg from '../../assets/images/404-img.png'
import serverErrorImg from '../../assets/images/500-img.png'
import Header from '../../components/Header'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Error = () => {
  const { t } = useTranslation('global')
  const { state } = useLocation()
  return (
    <>
      <Header></Header>
      <div className='competition-sec error-sec'>
        {state?.errorMsg
          ? <>
            <img src={serverErrorImg} />
            <h1>{state.errorMsg}</h1>
            <p>{t('views.error.serverError')}</p>
          </>
          : <>
            <img src={errorimg} />
            <h1>{t('views.error.notFound')}</h1>
            <p>{t('views.error.sorry')}</p>
          </>
        }
        <a href='/' className='common-white-btn'>{t('views.error.back')}</a>
      </div>
    </>
  )
}

export default Error
