import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import infoicon from '../../../assets/images/info-icon.png'
import numberbg from '../../../assets/images/numbers-bg.png'
import { useTranslation } from 'react-i18next'

const EightComponent = (props) => {
  const { t } = useTranslation('global')
  const ticketsslider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    focusOnSelect: true,
    centerPadding: '10px',
    vertical: true,
    infinite: false,
    fade: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  const handleChange = (e) => {
    const result = [...props.steps]
    if (e.target.value) result[7].numOfBlocks = Number(e.target.value)
    props.setPrevActiveStep(false)
    props.setSteps(result)
  }
  const selectBlockWiseNum = (selNo, jarNo) => {
    const result = [...props.steps]
    const res = result[7].selBlockWiseNum.findIndex((s) => s.id === jarNo)
    if (res > -1) {
      if (result[7].selBlockWiseNum[res].numbers.includes(selNo)) {
        result[7].selBlockWiseNum[res].numbers.splice(result[7].selBlockWiseNum[res].numbers.indexOf(selNo), 1)
      } else result[7].selBlockWiseNum[res].numbers.push(selNo)

      if (result[7].selBlockWiseNum[res].selPrioNo.includes(selNo)) {
        result[7].selBlockWiseNum[res].selPrioNo.splice(result[7].selBlockWiseNum[res].selPrioNo.indexOf(selNo), 1)
      } else {
        result[7].selBlockWiseNum[res].selPrioNo.push(selNo)
      }
    }
    props.setPrevActiveStep(false)
    props.setSteps(result)
  }
  return <div>

    <div className='common-step-box'>
      <div className='text-center'>
        <h4 className='common-title'>{t('views.nori.step8.title')}<div className='tooltip-heading d-inline-block'>
          <img src={infoicon} className="infoicon" />
          <span className='tooltiptext'>{t('views.nori.step8.instruction')}</span>
        </div>
        </h4>
        <p>
          {t('views.nori.step8.infos')}
        </p>
        <div className='nori-mandetory-ticket-sec'>
          <Slider {...ticketsslider} className="ticketslidersec">
            {Array.from(Array(props.steps[7].numOfBlocks), (op, i) =>
              <div key={i + 1}>
                <div className='nori-mandetory-ticket-sec'>
                  <div className='nori-ticket-sec'>
                    <img src={numberbg} />
                    <ul className='mandetory-number-list'>
                      {Array.from(Array(42), (op1, j) =>
                        props.steps[2].isSelected && props.steps[2].selectedNum.includes(j + 1)
                          ? <><li key={j + 1} className='active'>{j + 1}</li><br /></>
                          : props.steps[4].isSelected && props.steps[4]?.selectedExcludeNum?.includes(j + 1) === true
                            ? <><li key={j + 1} className='red'>{j + 1}</li><br /></>
                            : props.steps[7].isSelected && props.steps[7].selBlockWiseNum[i]?.selPrioNo?.includes(j + 1) === true && props.steps[7].selBlockWiseNum[i]?.numbers?.includes(j + 1)
                              ? <><li key={j + 1} onClick={() => selectBlockWiseNum(j + 1, i + 1)} className='yellow'><a>{j + 1}</a></li><br /></>
                              : <><li key={j + 1} onClick={() => selectBlockWiseNum(j + 1, i + 1)} className={props.steps[7].isSelected && props.steps[7]?.selBlockWiseNum[i]?.numbers?.includes(j + 1) === true ? '' : 'disabled'}><a>{j + 1}</a></li><br /></>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </Slider>
          <div className='mandetory-number-footer'>
            {t('views.nori.step8.numbers')} <div className='drop-sec'>
              <select value={props.steps[7].numOfBlocks} onChange={handleChange}>
                {Array.from(Array(9), (op, i) =>
                  <option key={1 + i + 1} value={1 + i + 1}>{1 + i + 1}</option>
                )}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

EightComponent.propTypes = {
  steps: PropTypes.array,
  setSteps: PropTypes.func,
  setPrevActiveStep: PropTypes.func,
  noriType: PropTypes.number
}

export default EightComponent
