import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getWinnerList, getNoraWinners, getCompWinners, getListByDate } from '../../query/winners/winner.query'
import WinnerNoraModal from './WinnerNoraModal'
import WinnerCompModal from './WinnerCompModal'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import Sidebar from '../../components/Sidebar'
import Loading from '../../components/Loader'
import DataTable from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import 'react-datepicker/dist/react-datepicker.css'
import '../../assets/sass/views/Winners.scss'
import winningimg from '../../assets/images/winning-img.png'
import redballimg from '../../assets/images/red-ball-img.png'
import blueballimg from '../../assets/images/blue-ball-img.png'

const Winner = () => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const [loading, setLoading] = useState({ status: true })
  const [winnersList, setWinnersList] = useState({})
  const [selectedDate, setSelectedDate] = useState('')
  const [noraId, setNoraId] = useState('')
  const [noraWinnerDetail, setNoraWinnerDetail] = useState([])
  const [compId, setCompId] = useState('')
  const [compWinnerDetail, setCompWinnerDetail] = useState([])
  const [noraModal, setNoraModal] = useState(false)
  const noraToggle = () => {
    return setNoraModal(!noraModal)
  }
  const [compModal, setCompModal] = useState(false)
  const compToggle = () => {
    return setCompModal(!compModal)
  }
  const handleNora = (id) => {
    setNoraId(id)
    noraToggle()
  }
  const handleComp = (id) => {
    setCompId(id)
    compToggle()
  }
  const handleDateChange = (value) => {
    setLoading({ status: true })
    setTimeout(() => {
      setLoading({ status: false })
    }, 500)
    setSelectedDate(value)
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading({ status: false })
    }, 500)
  }, [])

  useQuery(['get-nora-winners', noraId], () => getNoraWinners(noraId), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setNoraWinnerDetail(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.winners.winner.error') } })
    }
  })

  useQuery(['get-comp-winners', compId], () => getCompWinners(compId), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setCompWinnerDetail(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.winners.winner.error') } })
    }
  })

  useQuery('get-winner-list', getWinnerList, {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setWinnersList(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.winners.winner.error') } })
    }
  })

  useQuery(['get-list-by-date', selectedDate], () => getListByDate(selectedDate), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setWinnersList({ ...winnersList, wbd: data })
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.winners.winner.error') } })
    }
  })
  const rows = winnersList?.wbd?.nwl?.map((rowData, i) => {
    const noraArr = {}
    noraArr.id = rowData?.nwi
    noraArr.class = i + 1
    noraArr.match = 6 - i + t('views.winners.winner.match')
    noraArr.totalWinners = <a onClick={() => { if (rowData.nwi !== '-') handleNora(rowData?.nwi) }} className='total-winning-numbers'><span>{rowData?.ntw}</span></a>
    noraArr.prizeAmount = rowData?.npa
    return noraArr
  })
  const columns = [
    {
      id: 1,
      name: t('views.winners.winner.class'),
      selector: (row) => row.class,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 2,
      name: t('views.winners.winner.matchs'),
      selector: (row) => row.match,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.winners.winner.totalWinners'),
      selector: (row) => row.totalWinners,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.winners.winner.prizeAmount'),
      selector: (row) => row.prizeAmount,
      sortable: false,
      reorder: false,
      center: true
    }
  ]
  const compRows = winnersList?.wbd?.cwl?.map((rowData, i) => {
    const noraArr = {}
    noraArr.id = rowData?.cwi
    noraArr.class = i + 1
    noraArr.noricScore = rowData?.nsc
    noraArr.totalWinners = <a onClick={() => { if (rowData.cwi !== '-') handleComp(rowData?.cwi) }} className='total-winning-numbers'><span>{rowData?.ctw}</span></a>
    noraArr.prizeAmount = rowData?.cpa
    return noraArr
  })
  const compColumns = [
    {
      id: 1,
      name: t('views.winners.winner.class'),
      selector: (row) => row.class,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 2,
      name: t('views.winners.winner.noriScore'),
      selector: (row) => row.noricScore,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.winners.winner.totalWinners'),
      selector: (row) => row.totalWinners,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.winners.winner.prizeAmount'),
      selector: (row) => row.prizeAmount,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  return (
    <>
      {loading.status ? <Loading addClass={'active'} /> : ''}
      <Header activeTab={1} />
      <div className='winning-sec'>
        <img src={blueballimg} className='blueballimg' />
        <Sidebar sideActiveTab={3} />
        <div className='winning-inner-sec'>
          <h1 className='text-center'>{t('views.winners.winner.winners')}</h1>
          <div className='winning-header'>
            <div className='winning-header-left'>
              <h3>{t('views.winners.winner.winningNumbers')}</h3>
              <ul>
                {winnersList?.wbd?.wnb.map((wn, i) => <li key={i}>{wn}</li>)}
              </ul>
            </div>
            <div className='winning-header-right'>
              <span>{t('views.winners.winner.drawDate')}</span>
              <div className='winner-select-sec'>
                <select onChange={(e) => handleDateChange(e.target.value)}>
                  {winnersList?.dts?.map((dts, i) => <option key={i} value={dts?.rbi}>{new Date(dts?.rbi).toJSON().slice(0, 10).split('-').reverse().join('/')}</option>)}
                </select>
              </div>
            </div>
          </div>
          <div className='winning-number-sec'>
            <div className='winning-number-left-sec'>
              <img src={winningimg} />
              <div className='winning-info-sec'>
                <h3>Nora642</h3>
                <p>{t('views.winners.winner.totalTicketNumber')}</p>
                <p>{winnersList?.wbd?.ttn || 0}</p>
              </div>
            </div>
            <div className='winning-number-right-sec'>
              <div className='common-table-sec'>
                <DataTable
                  columns={columns}
                  data={rows}
                />
              </div>
            </div>
          </div>
          <div className='winning-number-main-sec' >
            <div className='winning-number-sec'>
              <div className='winning-number-left-sec'>
                <img src={winningimg} />
                <div className='winning-info-sec'>
                  <h3>{t('views.winners.winner.norisCompetition')}</h3>
                  <p>{t('views.winners.winner.totalCompetingNori')}</p>
                  <p>{winnersList?.wbd?.tcn}</p>
                </div>
              </div>
              <div className='winning-number-right-sec'>
                <div className='common-table-sec'>
                  <DataTable
                    columns={compColumns}
                    data={compRows}
                  />
                </div>
              </div>
            </div>
            <img src={redballimg} className='redballimg' />
          </div>
        </div>
      </div>
      <div>{t('views.winners.winner.upcoming')}</div>
      <Footer isVisibleBall={false} />
      <WinnerNoraModal toggle={noraToggle} modal={noraModal} data={noraWinnerDetail} />
      <WinnerCompModal toggle={compToggle} modal={compModal} data={compWinnerDetail} />
    </>
  )
}

export default Winner
