import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Loading from '../../components/Loader'
import Footer from '../../components/footer'
import '../../assets/sass/views/Tutorial.scss'
import { useTranslation } from 'react-i18next'

const Help = () => {
  const { t } = useTranslation('global')
  const [loading, setLoading] = useState({ status: true })
  useEffect(() => {
    setTimeout(() => {
      setLoading({ status: false })
    }, 500)
  }, [])
  return (
    <>
      {loading.status ? <Loading addClass={'active'} /> : ''}
      <Header activeTab={4} />
      <div className='help'>
        <div className='presentation'>
          <h1>{t('views.help.title')}</h1>
          <p>{t('views.help.illustration')}</p>
        </div>
        <div className="accordeon">
          <div className="">
            <input type="radio" id="1" name="liste" />
            <label htmlFor="1"><div>{t('views.help.q1')}</div><div className="cross"></div></label>
            <div className="content">
              {t('views.help.r1')}
            </div>
          </div>
          <div className="">
            <input type="radio" id="2" name="liste" />
            <label htmlFor="2"><div>{t('views.help.q2')}</div><div className="cross"></div></label>
            <div className="content">
              {t('views.help.r2')}
            </div>
          </div>
          <div className="">
            <input type="radio" id="3" name="liste" />
            <label htmlFor="3"><div>{t('views.help.q3')}</div><div className="cross"></div></label>
            <div className="content">
              {t('views.help.r3')}
            </div>
          </div>
          <div className="">
            <input type="radio" id="4" name="liste" />
            <label htmlFor="4"><div>{t('views.help.q4')}</div><div className="cross"></div></label>
            <div className="content">
              <p>{t('views.help.r4one')}</p>
              <p>{t('views.help.r4two')}</p>
              <p>{t('views.help.r4thre')}</p>
              <p>{t('views.help.r4four')}</p>
            </div>
          </div>
          <div className="">
            <input type="radio" id="5" name="liste" />
            <label htmlFor="5"><div>{t('views.help.q5')}</div><div className="cross"></div></label>
            <div className="content">
              <p>{t('views.help.r5one')}</p>
              <p>{t('views.help.r5two')}</p>
            </div>
          </div>
          <div className="">
            <input type="radio" id="6" name="liste" />
            <label htmlFor="6"><div>{t('views.help.q6')}</div><div className="cross"></div></label>
            <div className="content">
              {t('views.help.r6')}
            </div>
          </div>
          <div className="">
            <input type="radio" id="7" name="liste" />
            <label htmlFor="7"><div>{t('views.help.q7')}</div><div className="cross"></div></label>
            <div className="content">
              {t('views.help.r7')}
            </div>
          </div>
          <div className="">
            <input type="radio" id="8" name="liste" />
            <label htmlFor="8"><div>{t('views.help.q8')}</div><div className="cross"></div></label>
            <div className="content">
              {t('views.help.r8')}
            </div>
          </div>
          <div className="">
            <input type="radio" id="9" name="liste" />
            <label htmlFor="9"><div>{t('views.help.q9')}</div><div className="cross"></div></label>
            <div className="content">
              {t('views.help.r9')}
            </div>
          </div>
          <div className="">
            <input type="radio" id="10" name="liste" />
            <label htmlFor="10"><div>{t('views.help.q10')}</div><div className="cross"></div></label>
            <div className="content">
              <p>{t('views.help.r10one')}</p>
              <p>{t('views.help.r10two')}</p>
            </div>
          </div>
          <div className="">
            <input type="radio" id="11" name="liste" />
            <label htmlFor="11"><div>{t('views.help.q11')}</div><div className="cross"></div></label>
            <div className="content">
              <p>{t('views.help.r11one')}</p>
              <p>{t('views.help.r11two')}</p>
              <p>{t('views.help.r11thre')}</p>
              <p>{t('views.help.r11four')}</p>
              <p>{t('views.help.r11five')}</p>
            </div>
          </div>
          <div className="">
            <input type="radio" id="12" name="liste" />
            <label htmlFor="12"><div>{t('views.help.q12')}</div><div className="cross"></div></label>
            <div className="content">
              {t('views.help.r12')}
            </div>
          </div>
          <div className="">
            <input type="radio" id="13" name="liste" />
            <label htmlFor="13"><div>{t('views.help.q13')}</div><div className="cross"></div></label>
            <div className="content">
              <p>{t('views.help.r13one')}</p>
              <p>{t('views.help.r13two')}</p>
              <p>{t('views.help.r13thre')}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer isVisibleBall={false} />
    </>
  )
}

export default Help
