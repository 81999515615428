import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import UseModalGraph from '../../components/UseModalGraph'
import Loading from '../../components/Loader'

import closeicon from '../../assets/images/close-icon.svg'
import infoicon from '../../assets/images/info-icon.png'

const UseModal = (props) => {
  const { t } = useTranslation('global')
  const handleAccept = () => {
    const noriArr = [...props.noriData]
    if (props.indexId !== -1) {
      noriArr[props.indexId].isUseOpen = true
    }
    props.setNoriData(noriArr)
  }
  const handleCancel = () => {
    const noriData = [...props.noriData]
    noriData[props.indexId].isSelected = false
    props.toggle2()
  }
  return (
    <>
      {props.loading.status
        ? <Loading addClass={'active'} />
        : ''}
      {props.modelNo === 2
        ? <Modal toggle={props.toggle2} className='settings-popup' isOpen={props.modal2}>
          <ModalBody>
            <div className='common-modal-sec'>
              <button onClick={handleCancel} className='common-close-btn'><img src={closeicon} /></button>
              <h3>{t('views.useNori.useNoriModal.confirm')}</h3>
              <p>{t('views.useNori.useNoriModal.message')}</p>
              {props.noriData[props.indexId]?.isUseOpen
                ? <div className='settings-btn-grp'>
                  <a onClick={props.toggle2} className='small-btn common-white-btn'>{t('views.useNori.useNoriModal.ready')}</a>
                </div>
                : <div className='settings-btn-grp'>
                  <a onClick={handleCancel} className='small-btn common-white-btn'>{t('views.useNori.useNoriModal.cancel')}</a>
                  <a onClick={handleAccept} className='small-btn common-blue-btn'>{t('views.useNori.useNoriModal.accept')}</a>
                </div>}
            </div>
          </ModalBody>
        </Modal>
        : <Modal toggle={props.toggle1} className='preview-popup' isOpen={props.modal1}>
          <ModalBody>
            <div className='common-modal-sec'>
              <button onClick={props.toggle1} className='common-close-btn'><img src={closeicon} /></button>
              <p className='played-options'>{t('views.useNori.useNoriModal.playedOption')}
                <ul>
                  {props?.noriData[props.indexId]?.playedOps?.map((nori) =>
                    <li key={nori}><span><span>{nori}</span></span></li>
                  )}
                </ul>
              </p>
              <div className='preview-header-sec'>
                <ul>
                  <li>
                    <p>{t('views.useNori.useNoriModal.numberOfNori')}<div className='tooltip-heading'><img src={infoicon} className='info-icon' /><span className="tooltiptext">{t('views.useNori.useNoriModal.tooltip')}</span></div></p>
                    <h3>{props?.noriData[props?.indexId]?.noriPrevData?.noriKeyData?.ncb || 0}</h3>
                  </li>
                  <li>
                    <p>{t('views.useNori.useNoriModal.ratio')}<div className='tooltip-heading'><img src={infoicon} className='info-icon' /><span className="tooltiptext">{t('views.useNori.useNoriModal.tooltip')}</span></div></p>
                    <h3>{props?.noriData[props?.indexId]?.noriPrevData?.noriKeyData?.rcb || 0}</h3>
                  </li>
                  <li>
                    <p>{t('views.useNori.useNoriModal.noriWeight')} <div className='tooltip-heading'><img src={infoicon} className='info-icon' /><span className="tooltiptext">{t('views.useNori.useNoriModal.tooltip')}</span></div></p>
                    <h3>{props?.noriData[props?.indexId]?.noricWeight}</h3>
                  </li>
                </ul>
              </div>
              {props?.noriData[props.indexId]?.noriPrevData && !props.loading.status
                ? <div className='preview-details-main-sec'>
                  <div className='preview-details-sec'>
                    <div className='preview-detail-left use-nori-left-preview'>
                      <ul>
                        {props?.noriData[props.indexId]?.noriPrevData?.noriDateStats?.dateColors?.map((nori, i) =>
                          <li key={i}><span style={nori.color === 0 ? { backgroundColor: '#4BE200' } : nori.color === 1 ? { backgroundColor: '#FCA801' } : { backgroundColor: '#FC002A' }}></span>{nori.drawDate.replaceAll('.', '/')}</li>
                        )}
                      </ul>
                    </div>
                    {props?.noriData[props?.indexId]?.noriPrevData?.noriDateStats?.noriStats.length
                      ? <div className='preview-details-right'>
                        <UseModalGraph graphData={props?.noriData[props?.indexId]?.graphData} />
                      </div>
                      : ''}
                  </div></div>
                : <Loading addClass={'active'} />
              }
            </div>
          </ModalBody>
        </Modal>
      }
    </>
  )
}

UseModal.propTypes = {
  modal1: PropTypes.bool,
  toggle1: PropTypes.func,
  modal2: PropTypes.bool,
  toggle2: PropTypes.func,
  noriData: PropTypes.array,
  noriId: PropTypes.string,
  setNoriData: PropTypes.func,
  indexId: PropTypes.number,
  modelNo: PropTypes.number,
  loading: PropTypes.object
}

export default UseModal
