import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import DatePicker from 'react-datepicker'
import DataTable from 'react-data-table-component'
import { getStatistics, frequencyResp, longestUndrawnResp, neighborResp, parityResp, sbResp, acValueResp } from '../../query/statistics/statistics.query'
import { primeResp, distanceResp, lengthResp, averageResp, unitResp, unitDFResp, evenNoResp, oddNoResp } from '../../query/statistics/advanceStatistics.query'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import Sidebar from '../../components/Sidebar'
import Range from '../../components/Range'
import Loading from '../../components/Loader'
import { useTranslation } from 'react-i18next'

import 'react-datepicker/dist/react-datepicker.css'
import '../../assets/sass/views/Statistics.scss'

import arrowleft from '../../assets/images/arrow-left.png'
import arrowright from '../../assets/images/arrow-right.png'
import closeicon from '../../assets/images/close-icon.svg'
import blueballimg from '../../assets/images/blue-ball-img.png'
import infoicon from '../../assets/images/info-icon.png'

import {
  TabContent, TabPane, Nav,
  NavItem, NavLink
} from 'reactstrap'
import classnames from 'classnames'

const Statistics = () => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const [loading, setLoading] = useState({ status: false })
  const [currentActiveTab, setCurrentActiveTab] = useState('1')
  const [currentActiveTabSub, setCurrentActiveTabSub] = useState('1')
  const [statisticsData, setStatisticsData] = useState({})
  const [flag, setFlag] = useState(true)
  const [filterData, setFilterData] = useState({ startDate: '', prevStartDate: '', endDate: '', prevEndDate: '', range: [1, 42], prevRange: [1, 42], noOfDraws: 0, prevDraws: 0 })
  const [activeStep, setActiveStep] = useState([{ id: 1, tId: 1 }, { id: 2, tId: 1 }, { id: 3, tId: 2 }])
  const [advanceActiveStep, setAdvanceActiveStep] = useState([{ id: 1, tId: 1 }, { id: 2, tId: 1 }, { id: 5, tId: 1, subData: { isChecked: true, subTabId: 11 } }, { id: 6, tId: 1 }, { id: 7, tId: 1 }])
  const [frequencyData, setFrequencyData] = useState([])
  const [longestUndrawnData, setLongestUndrawnData] = useState([])
  const [neighborData, setNeighborData] = useState([])
  const [parityData, setParityData] = useState([])
  const [sbData, setSbData] = useState([])
  const [acValueData, setAcValueData] = useState([])
  const [primeData, setPrimeData] = useState([])
  const [distanceData, setDistanceData] = useState([])
  const [lengthData, setLengthData] = useState([])
  const [avgData, setAvgData] = useState([])
  const [unitData, setUnitData] = useState([])
  const [unitDFData, setUnitDFData] = useState([])
  const [evenNoData, setEvenNoData] = useState([])
  const [oddNoData, setOddNoData] = useState([])
  const [isPopUp, setIsPopUp] = useState(false)

  useEffect(() => {
    if (!statisticsData.fps) setLoading({ status: true })
    else setLoading({ status: false })
  }, [statisticsData])

  useQuery('get-statistics', () => getStatistics(flag), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      if (flag) {
        setStatisticsData(Object.assign(statisticsData, data))
        setFilterData({ startDate: new Date(statisticsData?.fps?.sps?.fd), prevStartDate: new Date(statisticsData?.fps?.sps?.fd), endDate: new Date(statisticsData?.fps?.sps?.td), prevEndDate: new Date(statisticsData?.fps?.sps?.td), range: [statisticsData?.fps?.sps?.fn, statisticsData?.fps?.sps?.tn], prevRange: [statisticsData?.fps?.sps?.fn, statisticsData?.fps?.sps?.tn], noOfDraws: statisticsData?.dts?.length, prevDraws: statisticsData?.dts?.length })
        setFrequencyData(statisticsData?.fda)
        setLoading({ status: false })
      }
      setFlag(false)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: 'Internal Server Error' } })
    }
  })

  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab)
    setCurrentActiveTabSub('1')
    if (Number(tab) === 1) frequencyRefetch()
    if (Number(tab) === 2) primeRefetch()
  }
  const togglesub = (tab) => {
    if (currentActiveTabSub !== tab) {
      setCurrentActiveTabSub(tab)
      if (Number(currentActiveTab) === 1) {
        if (Number(tab) === 1) frequencyRefetch()
        if (Number(tab) === 2) longestUndrawnRefetch()
        if (Number(tab) === 3) neighborRefetch()
        if (Number(tab) === 4) parityRefetch()
        if (Number(tab) === 5) sbRefetch()
        if (Number(tab) === 6) acValueRefetch()
      } else if (Number(currentActiveTab) === 2) {
        if (Number(tab) === 1) primeRefetch()
        if (Number(tab) === 2) distanceRefetch()
        if (Number(tab) === 3) lengthRefetch()
        if (Number(tab) === 4) avgRefetch()
        if (Number(tab) === 5) unitRefetch()
        if (Number(tab) === 6) evenNoRefetch()
        if (Number(tab) === 7) oddNoRefetch()
      }
    }
  }

  // Frequency
  const rows = frequencyData?.map((freq) => {
    const freqArr = {}
    freqArr.number = <div className='blue-numbers-list'>{freq?.ns?.map((no, i) => <span key={i} className='blue-number'>{no}</span>)}</div>
    freqArr.frequency = <div className='frequancy-column'>{freq?.bsi?.fq}<div className='frequancy-bar'><span className='friquancy-value' style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list'><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const columns = [
    {
      id: 1,
      name: t('views.statistics.number'),
      selector: (row) => row.number,
      sortable: false,
      reorder: false,
      width: '200px',
      center: true
    },
    {
      id: 6,
      name: t('views.statistics.frequency'),
      selector: (row) => row.frequency,
      sortable: true,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '285px'
    },
    {
      id: 5,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  // For Un Drawn
  const undrawnrows = longestUndrawnData?.map((freq) => {
    const freqArr = {}
    freqArr.number = <div className='blue-numbers-list'>{freq?.ns?.map((no, i) => <span key={i} className='blue-number'>{no}</span>)}</div>
    freqArr.longestUn = <div className='frequancy-column'>{freq?.bsi?.fq}<div className='frequancy-bar'><span className='friquancy-value' style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list'><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const undrawncolumns = [
    {
      id: 1,
      name: t('views.statistics.number'),
      selector: (row) => row.number,
      sortable: false,
      reorder: false,
      width: '200px',
      center: true
    },
    {
      id: 6,
      name: t('views.statistics.noOccurance'),
      selector: (row) => row.longestUn,
      sortable: true,
      reorder: false,
      width: window.innerWidth < 1199 ? '330px' : 'auto',
      center: true
    },
    {
      id: 3,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '265px'
    },
    {
      id: 5,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  // neighbor
  const neighborRows = neighborData?.map((freq) => {
    const freqArr = {}
    freqArr.number = <div className='blue-numbers-list'>{freq?.ns?.map((no, i) => <span key={i} className='blue-number'>{no}</span>)}</div>
    freqArr.neighbor = <div className='frequancy-column'>{freq?.bsi?.fq}<div className='frequancy-bar'><span className='friquancy-value' style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list'><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const neighborColumns = [
    {
      id: 1,
      name: t('views.statistics.number'),
      selector: (row) => row.number,
      sortable: false,
      reorder: false,
      width: '200px',
      center: true
    },
    {
      id: 6,
      name: t('views.statistics.frequency'),
      selector: (row) => row.neighbor,
      sortable: true,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '265px'
    },
    {
      id: 5,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  // For Parity
  const parityrows = parityData?.map((freq, index) => {
    const freqArr = {}
    freqArr.evennumbers = <ul className='blank-balls-list' key={index}>{Array.from(Array(6 - freq?.odn), (op, i) => <><li key={i}></li></>)}</ul>
    freqArr.oddnumbers = <ul className='blank-balls-list' key={index}>{Array.from(Array(freq?.odn), (op, i) => <li key={i}></li>)}</ul>
    freqArr.frequency = <div className='frequancy-column' key={index}>{freq?.bsi?.fq}<div className='frequancy-bar' key={index}><span className='friquancy-value' key={index} style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list' key={index}><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const paritycolumns = [
    {
      id: 1,
      name: t('views.statistics.evenNumbers'),
      selector: (row) => row.evennumbers,
      sortable: false,
      reorder: false,
      width: '250px',
      right: true
    },
    {
      id: 2,
      name: t('views.statistics.oddNumbers'),
      selector: (row) => row.oddnumbers,
      sortable: false,
      reorder: false,
      width: '250px'
    },
    {
      id: 3,
      name: t('views.statistics.frequency'),
      selector: (row) => row.frequency,
      sortable: false,
      reorder: false,
      center: true,
      width: '350px'
    },
    {
      id: 4,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 5,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '265px'
    },
    {
      id: 6,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  // Small/Big
  const sbRows = sbData?.map((freq) => {
    const freqArr = {}
    freqArr.evennumbers = <ul className='blank-balls-list'>{Array.from(Array(6 - freq?.bgn), (op, i) => <><li key={i}></li></>)}</ul>
    freqArr.oddnumbers = <ul className='blank-balls-list'>{Array.from(Array(freq?.bgn), (op, i) => <li key={i}></li>)}</ul>
    freqArr.frequency = <div className='frequancy-column'>{freq?.bsi?.fq}<div className='frequancy-bar'><span className='friquancy-value' style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list'><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const sbColumns = [
    {
      id: 1,
      name: t('views.statistics.to21'),
      selector: (row) => row.evennumbers,
      sortable: false,
      reorder: false,
      width: '250px',
      right: true
      // center: true
    },
    {
      id: 2,
      name: t('views.statistics.from22'),
      selector: (row) => row.oddnumbers,
      sortable: false,
      reorder: false,
      width: '250px'
      // center: true
    },
    {
      id: 3,
      name: t('views.statistics.frequency'),
      selector: (row) => row.frequency,
      sortable: false,
      reorder: false,
      center: true,
      width: '350px'
    },
    {
      id: 4,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 5,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '265px'
    },
    {
      id: 6,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  // AC values
  const acRows = acValueData?.map((freq) => {
    const freqArr = {}
    freqArr.number = <div className='blue-numbers-list'><span className='without-blue-number'>{freq?.acv}</span></div>
    freqArr.acValue = <div className='frequancy-column'>{freq?.bsi?.fq}<div className='frequancy-bar'><span className='friquancy-value' style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list'><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const acColumns = [
    {
      id: 1,
      name: t('views.statistics.averages'),
      selector: (row) => row.number,
      sortable: false,
      reorder: false,
      width: '100px',
      center: true
    },
    {
      id: 6,
      name: t('views.statistics.frequency'),
      selector: (row) => row.acValue,
      sortable: true,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '265px'
    },
    {
      id: 5,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  // Primes
  const primeRows = primeData?.map((freq) => {
    const freqArr = {}
    freqArr.number = <div className='blue-numbers-list'><span className='without-blue-number'>{freq?.prn}</span></div>
    freqArr.frequency = <div className='frequancy-column'>{freq?.bsi?.fq} <div className='frequancy-bar'><span className='friquancy-value' style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list'><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const primeColumns = [
    {
      id: 1,
      name: t('views.statistics.primeNumber'),
      selector: (row) => row.number,
      sortable: false,
      reorder: false,
      width: '180px',
      center: true
    },
    {
      id: 2,
      name: t('views.statistics.frequency'),
      selector: (row) => row.frequency,
      sortable: false,
      reorder: false,
      width: '350px',
      center: true
    },
    {
      id: 3,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '265px'
    },
    {
      id: 5,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  // Distance
  const distanceRows = distanceData?.map((freq) => {
    const freqArr = {}
    freqArr.number = <div className='blue-numbers-list'><span className='without-blue-number'>{freq?.dst}</span></div>
    freqArr.distance = <div className='frequancy-column'>{freq?.bsi?.fq}<div className='frequancy-bar'><span className='friquancy-value' style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list'><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const distanceColumns = [
    {
      id: 1,
      name: t('views.statistics.distances'),
      selector: (row) => row.number,
      sortable: false,
      reorder: false,
      width: window.innerWidth < 1199 ? '180px' : '90px',
      center: true
    },
    {
      id: 6,
      name: t('views.statistics.frequency'),
      selector: (row) => row.distance,
      sortable: true,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '265px'
    },
    {
      id: 5,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  // Length
  const lengthRows = lengthData?.map((freq) => {
    const freqArr = {}
    freqArr.number = <div className='blue-numbers-list'><span className='without-blue-number'>{freq?.spa}</span></div>
    freqArr.lengthVal = <div className='frequancy-column'>{freq?.bsi?.fq}<div className='frequancy-bar'><span className='friquancy-value' style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list'><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const lengthColumns = [
    {
      id: 1,
      name: t('views.statistics.lengths'),
      selector: (row) => row.number,
      sortable: false,
      reorder: false,
      width: '90px',
      center: true
    },
    {
      id: 6,
      name: t('views.statistics.frequency'),
      selector: (row) => row.lengthVal,
      sortable: true,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '265px'
    },
    {
      id: 5,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  // Average
  const avgrows = avgData?.map((freq) => {
    const freqArr = {}
    freqArr.number = <div className='blue-numbers-list'><span className='without-blue-number'>{freq?.avg}</span></div>
    freqArr.avgVal = <div className='frequancy-column'>{freq?.bsi?.fq}<div className='frequancy-bar'><span className='friquancy-value' style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list'><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const avgcolumns = [
    {
      id: 1,
      name: t('views.statistics.interval'),
      selector: (row) => row.number,
      sortable: false,
      reorder: false,
      width: window.innerWidth < 1199 ? '180px' : '90px',
      center: true
    },
    {
      id: 6,
      name: t('views.statistics.frequency'),
      selector: (row) => row.avgVal,
      sortable: true,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '265px'
    },
    {
      id: 5,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  // Units
  const unitsRows = unitData?.map((freq) => {
    const freqArr = {}
    freqArr.number = <div className='blue-numbers-list'><span className='without-blue-number'>{freq?.uni}</span></div>
    freqArr.frequency = <div className='frequancy-column'>{freq?.bsi?.fq} <div className='frequancy-bar'><span className='friquancy-value' style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list'><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const unitsColumns = [
    {
      id: 1,
      name: t('views.statistics.units'),
      selector: (row) => row.number,
      sortable: false,
      reorder: false,
      width: '90px',
      center: true
    },
    {
      id: 2,
      name: t('views.statistics.frequency'),
      selector: (row) => row.frequency,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '265px'
    },
    {
      id: 5,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  // UnitDF
  const unitDFRows = unitDFData?.map((freq) => {
    const freqArr = {}
    freqArr.number = <div className='blue-numbers-list'><span className='without-blue-number'>{freq?.udf}</span></div>
    freqArr.frequency = <div className='frequancy-column'>{freq?.bsi?.fq} <div className='frequancy-bar'><span className='friquancy-value' style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list'><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const unitDFColumns = [
    {
      id: 1,
      name: t('views.statistics.occurences'),
      selector: (row) => row.number,
      sortable: false,
      reorder: false,
      width: '130px',
      center: true
    },
    {
      id: 2,
      name: t('views.statistics.frequency'),
      selector: (row) => row.frequency,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '265px'
    },
    {
      id: 5,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  // Even Numbers
  const evenNoRows = evenNoData?.map((freq) => {
    const freqArr = {}
    freqArr.number = <div className='blue-numbers-list'>{freq?.ns?.map((no, i) => <span key={i} className='blue-number'>{no}</span>)}</div>
    freqArr.evenVal = <div className='frequancy-column'>{freq?.bsi?.fq}<div className='frequancy-bar'><span className='friquancy-value' style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list'><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const evenNoColumns = [
    {
      id: 1,
      name: t('views.statistics.number'),
      selector: (row) => row.number,
      sortable: false,
      reorder: false,
      width: '175px',
      center: true
    },
    {
      id: 6,
      name: t('views.statistics.frequency'),
      selector: (row) => row.evenVal,
      sortable: true,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '265px'
    },
    {
      id: 5,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  // Odd Numbers
  const oddNoRows = oddNoData?.map((freq) => {
    const freqArr = {}
    freqArr.number = <div className='blue-numbers-list'>{freq?.ns?.map((no, i) => <span key={i} className='blue-number'>{no}</span>)}</div>
    freqArr.oddVal = <div className='frequancy-column'>{freq?.bsi?.fq}<div className='frequancy-bar'><span className='friquancy-value' style={{ width: `${freq?.bsi?.fq}%` }}></span></div></div>
    freqArr.percentage = `${freq?.bsi?.pc.toFixed(2)}%`
    freqArr.lastdraw = freq?.bsi?.ld !== null ? <ul className='last-draw-list'><li>{freq?.bsi?.ld[0]}</li><li>{freq?.bsi?.ld[1]}</li><li>{freq?.bsi?.ld[2]}</li><li>{freq?.bsi?.ld[3]}</li><li>{freq?.bsi?.ld[4]}</li><li>{freq?.bsi?.ld[5]}</li></ul> : '-'
    freqArr.lastdate = freq?.bsi?.dt !== null ? `${new Date(freq?.bsi?.dt).getDate() < 10 ? '0' + new Date(freq?.bsi?.dt).getDate() : new Date(freq?.bsi?.dt).getDate()}/${(new Date(freq?.bsi?.dt).getMonth() + 1) < 10 ? '0' + (new Date(freq?.bsi?.dt).getMonth() + 1) : new Date(freq?.bsi?.dt).getMonth() + 1}/${new Date(freq?.bsi?.dt).getFullYear()}` : '-'
    return freqArr
  })
  const oddNoColumns = [
    {
      id: 1,
      name: t('views.statistics.number'),
      selector: (row) => row.number,
      sortable: false,
      reorder: false,
      width: '175px',
      center: true
    },
    {
      id: 6,
      name: t('views.statistics.frequency'),
      selector: (row) => row.oddVal,
      sortable: true,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.statistics.percentage'),
      width: window.innerWidth < 1199 ? '180px' : 'auto',
      selector: (row) => row.percentage,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.statistics.lastDraw'),
      selector: (row) => row.lastdraw,
      sortable: false,
      reorder: false,
      center: true,
      width: '265px'
    },
    {
      id: 5,
      name: t('views.statistics.lastDate'),
      width: window.innerWidth < 1199 ? '150px' : 'auto',
      selector: (row) => row.lastdate,
      sortable: false,
      reorder: false,
      center: true
    }
  ]

  const customSort = (rows, selector, direction) => {
    let finalData = []
    if (rows[0]?.frequency) {
      finalData = rows.sort((a, b) => direction === 'desc' ? b?.frequency?.props?.children[0] - a?.frequency?.props?.children[0] : a?.frequency?.props?.children[0] - b?.frequency?.props?.children[0])
    } else if (rows[0]?.longestUn) {
      finalData = rows.sort((a, b) => direction === 'desc' ? b?.longestUn?.props?.children[0] - a?.longestUn?.props?.children[0] : a?.longestUn?.props?.children[0] - b?.longestUn?.props?.children[0])
    } else if (rows[0]?.neighbor) {
      finalData = rows.sort((a, b) => direction === 'desc' ? b?.neighbor?.props?.children[0] - a?.neighbor?.props?.children[0] : a?.neighbor?.props?.children[0] - b?.neighbor?.props?.children[0])
    } else if (rows[0]?.acValue) {
      finalData = rows.sort((a, b) => direction === 'desc' ? b?.acValue?.props?.children[0] - a?.acValue?.props?.children[0] : a?.acValue?.props?.children[0] - b?.acValue?.props?.children[0])
    } else if (rows[0]?.distance) {
      finalData = rows.sort((a, b) => direction === 'desc' ? b?.distance?.props?.children[0] - a?.distance?.props?.children[0] : a?.distance?.props?.children[0] - b?.distance?.props?.children[0])
    } else if (rows[0]?.lengthVal) {
      finalData = rows.sort((a, b) => direction === 'desc' ? b?.lengthVal?.props?.children[0] - a?.lengthVal?.props?.children[0] : a?.lengthVal?.props?.children[0] - b?.lengthVal?.props?.children[0])
    } else if (rows[0]?.avgVal) {
      finalData = rows.sort((a, b) => direction === 'desc' ? b?.avgVal?.props?.children[0] - a?.avgVal?.props?.children[0] : a?.avgVal?.props?.children[0] - b?.avgVal?.props?.children[0])
    } else if (rows[0]?.evenVal) {
      finalData = rows.sort((a, b) => direction === 'desc' ? b?.evenVal?.props?.children[0] - a?.evenVal?.props?.children[0] : a?.evenVal?.props?.children[0] - b?.evenVal?.props?.children[0])
    } else if (rows[0]?.oddVal) {
      finalData = rows.sort((a, b) => direction === 'desc' ? b?.oddVal?.props?.children[0] - a?.oddVal?.props?.children[0] : a?.oddVal?.props?.children[0] - b?.oddVal?.props?.children[0])
    }
    return finalData
  }

  const { refetch: frequencyRefetch } = useQuery('frequency', () => frequencyResp(filterData, activeStep[0].tId), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setFrequencyData(data)
    }
  })

  const { refetch: longestUndrawnRefetch, isSuccess: longestSuccess } = useQuery('longest-undrawn', () => longestUndrawnResp(filterData, activeStep[1].tId), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setLongestUndrawnData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.statistics.error') } })
    }
  })

  const { refetch: neighborRefetch, isSuccess: neighborSuccess } = useQuery('neighbor', () => neighborResp(filterData, activeStep[2].tId), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setNeighborData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.statistics.error') } })
    }
  })

  const { refetch: parityRefetch, isSuccess: paritySuccess } = useQuery('parity', () => parityResp(filterData), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setParityData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.statistics.error') } })
    }
  })

  const { refetch: sbRefetch, isSuccess: smallBigSuccess } = useQuery('small-big', () => sbResp(filterData), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setSbData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.statistics.error') } })
    }
  })

  const { refetch: acValueRefetch, isSuccess: acValueSuccess } = useQuery('ac-value', () => acValueResp(filterData), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setAcValueData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.statistics.error') } })
    }
  })

  const { refetch: primeRefetch, isSuccess: primeSuccess } = useQuery('prime', () => primeResp(filterData, advanceActiveStep[0].tId), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setPrimeData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.statistics.error') } })
    }
  })

  const { refetch: distanceRefetch, isSuccess: distanceSuccess } = useQuery('distance', () => distanceResp(filterData, advanceActiveStep[1].tId), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setDistanceData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.statistics.error') } })
    }
  })

  const { refetch: lengthRefetch, isSuccess: lengthSuccess } = useQuery('length', () => lengthResp(filterData), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setLengthData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.statistics.error') } })
    }
  })

  const { refetch: avgRefetch, isSuccess: avgSuccess } = useQuery('average', () => averageResp(filterData), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setAvgData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.statistics.error') } })
    }
  })

  const { refetch: unitRefetch, isSuccess: unitSuccess } = useQuery('unit', () => unitResp(filterData, advanceActiveStep[2].tId, advanceActiveStep[2].subData.subTabId), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setUnitData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.statistics.error') } })
    }
  })

  const { refetch: unitDFRefetch, isSuccess: unitDFSuccess } = useQuery('unitDF', () => unitDFResp(filterData), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setUnitDFData(data)
    }
  })

  const { refetch: evenNoRefetch, isSuccess: evenSuccess } = useQuery('even-numbers', () => evenNoResp(filterData, advanceActiveStep[3].tId), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setEvenNoData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.statistics.error') } })
    }
  })

  const { refetch: oddNoRefetch, isSuccess: oddSuccess } = useQuery('odd-numbers', () => oddNoResp(filterData, advanceActiveStep[4].tId), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setOddNoData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.statistics.error') } })
    }
  })
  const btn = document.getElementById('settings-popup')
  btn?.parentNode?.classList.add('settings-popup-modal')
  const handlePopup = (number) => {
    const filterArr = { ...filterData }
    if (number === 2) {
      filterArr.prevRange = filterArr.range
      filterArr.prevStartDate = filterArr.startDate
      filterArr.prevEndDate = filterArr.endDate
      filterArr.prevDraws = filterArr.noOfDraws
      setFilterData(filterArr)
      if (Number(currentActiveTab) === 1) {
        if (Number(currentActiveTabSub) < 4) radioSelChange(activeStep[currentActiveTabSub - 1].tId)
        else {
          if (Number(currentActiveTabSub) === 4) parityRefetch()
          if (Number(currentActiveTabSub) === 5) sbRefetch()
          if (Number(currentActiveTabSub) === 6) acValueRefetch()
        }
      } else if (Number(currentActiveTab) === 2) {
        if (Number(currentActiveTabSub) !== 3 && Number(currentActiveTabSub) !== 4) {
          const findIndex = advanceActiveStep.findIndex((f) => f.id === Number(currentActiveTabSub))
          radioSelChange(advanceActiveStep[findIndex].tId)
        } else {
          if (Number(currentActiveTabSub) === 3) lengthRefetch()
          if (Number(currentActiveTabSub) === 4) avgRefetch()
        }
      }
    } else if (number === 1) {
      filterArr.range = filterArr.prevRange
      filterArr.startDate = filterArr.prevStartDate
      filterArr.endDate = filterArr.prevEndDate
      filterArr.noOfDraws = filterArr.prevDraws
      setFilterData(filterArr)
    }
    setIsPopUp(!isPopUp)
  }

  const radioSelChange = (stepNumber) => {
    if (Number(currentActiveTab) === 1) {
      const finalArr = [...activeStep]
      const findIndex = finalArr.findIndex((f) => f.id === Number(currentActiveTabSub))
      if (findIndex !== -1) finalArr[findIndex].tId = stepNumber
      setActiveStep(finalArr)
      if (findIndex === 0) frequencyRefetch()
      if (findIndex === 1) longestUndrawnRefetch()
      if (findIndex === 2) neighborRefetch()
    } else if (Number(currentActiveTab) === 2) {
      const advanceFinalArr = [...advanceActiveStep]
      const advanceIndex = advanceFinalArr.findIndex((f) => f.id === Number(currentActiveTabSub))
      if (stepNumber === true || stepNumber === false) {
        advanceFinalArr[advanceIndex].subData.isChecked = stepNumber
        if (!advanceFinalArr[advanceIndex].subData.isChecked) unitDFRefetch()
      } else {
        if (stepNumber < 11 && advanceIndex !== -1) advanceFinalArr[advanceIndex].tId = stepNumber
        else advanceFinalArr[advanceIndex].subData.subTabId = stepNumber
        if (advanceIndex === 0) primeRefetch()
        if (advanceIndex === 1) distanceRefetch()
        if (advanceIndex === 2) unitRefetch()
        if (advanceIndex === 3) evenNoRefetch()
        if (advanceIndex === 4) oddNoRefetch()
      }
      setAdvanceActiveStep(advanceFinalArr)
    }
  }
  const handleDateChange = (date, number) => {
    const firstIndex = statisticsData?.dts?.findIndex((index) => index.split('T')[0] === new Date(date).toISOString().split('T')[0])
    if (number === 1) {
      const secondIndex = statisticsData?.dts?.findIndex((index) => index.split('T')[0] === new Date(filterData.endDate).toISOString().split('T')[0])
      const drawNo = firstIndex - secondIndex + 1
      setFilterData({ startDate: date, prevStartDate: filterData.prevStartDate, endDate: filterData.endDate, prevEndDate: filterData.prevEndDate, range: filterData.range, prevRange: filterData.prevRange, noOfDraws: Math.abs(drawNo), prevDraws: filterData.prevDraws })
    }
    if (number === 2) {
      const secondIndex = statisticsData?.dts?.findIndex((index) => index.split('T')[0] === new Date(filterData.startDate).toISOString().split('T')[0])
      const drawNo = firstIndex - secondIndex - 1
      setFilterData({ startDate: filterData.startDate, prevStartDate: filterData.prevStartDate, endDate: date, prevEndDate: filterData.prevEndDate, range: filterData.range, prevRange: filterData.prevRange, noOfDraws: Math.abs(drawNo), prevDraws: filterData.prevDraws })
    }
    if (!isPopUp) setIsPopUp(!isPopUp)
  }

  const handleChangeDraws = (number) => {
    let finalNumber = filterData.noOfDraws
    let startFDate = filterData.startDate
    let endFDate = filterData.endDate
    let firstIndex = statisticsData?.dts?.findIndex((index) => index.split('T')[0] === new Date(startFDate).toISOString().split('T')[0])
    let secondIndex = statisticsData?.dts?.findIndex((index) => index.split('T')[0] === new Date(endFDate).toISOString().split('T')[0])
    if (number === 1) {
      if (finalNumber > 1) {
        finalNumber -= 1
        firstIndex -= 1
        startFDate = statisticsData?.dts[firstIndex]
      }
    } else if (number === 2) {
      if (finalNumber < statisticsData?.dts?.length) {
        finalNumber += 1
        if (firstIndex >= (statisticsData?.dts?.length - 1)) {
          secondIndex -= 1
          endFDate = statisticsData?.dts[secondIndex]
        } else {
          firstIndex += 1
          startFDate = statisticsData?.dts[firstIndex]
        }
      }
    }
    if (!isPopUp) setIsPopUp(!isPopUp)
    setFilterData({ startDate: new Date(startFDate), prevStartDate: filterData.prevStartDate, endDate: new Date(endFDate), prevEndDate: filterData.prevEndDate, range: filterData.range, prevRange: filterData.prevRange, noOfDraws: finalNumber, prevDraws: filterData.prevDraws })
  }

  return (
    <>
      {loading.status ? <Loading addClass={'active'} /> : ''}
      <Header activeTab={1} />
      <div className='statics-tabs-sec'>
        <img src={blueballimg} className='blueballimg' />
        <Sidebar sideActiveTab={4} />
        <div className='statics-main-sec'>

          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active:
                    currentActiveTab === '1'
                })}
                onClick={() => { toggle('1') }}
              >
                {t('views.statistics.statistics')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active:
                    currentActiveTab === '2'
                })}
                onClick={() => { toggle('2') }}
              >
                {t('views.statistics.advancedStatistics')}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1">
              <div className='statics-sec'>
                {/* <img src={redballimg} className='redballimg' /> */}
                <div className='statics-header'>
                  <h4 className='common-title'>{t('views.statistics.statistics')}</h4>
                  <div className='statics-header-middle'>
                    {t('views.statistics.numberOfDraws')}
                    <a id={filterData?.noOfDraws <= 1 ? 'disabled' : ''} onClick={filterData?.noOfDraws > 1 ? () => handleChangeDraws(1) : null}><img src={arrowleft} /></a>{filterData.noOfDraws}  <a id={filterData?.noOfDraws > statisticsData?.dts?.length - 1 ? 'disabled' : ''} onClick={filterData?.noOfDraws < statisticsData?.dts?.length ? () => handleChangeDraws(2) : null}><img src={arrowright} /></a>
                  </div>
                  <div className='statics-header-right'>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={filterData.startDate}
                      minDate={statisticsData?.dts?.length ? new Date(statisticsData?.dts[statisticsData?.dts?.length - 1]) : ''}
                      maxDate={filterData.endDate}
                      onChange={(date) => handleDateChange(date, 1)}
                      onKeyDown={(e) => {
                        e.preventDefault()
                      }}
                    />
                    <span>-</span>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={filterData.endDate}
                      minDate={filterData.startDate}
                      maxDate={statisticsData?.dts?.length ? new Date(statisticsData.dts[0]) : ''}
                      onChange={(date) => handleDateChange(date, 2)}
                      onKeyDown={(e) => {
                        e.preventDefault()
                      }}
                    />
                  </div>
                </div>
                <Range modal={isPopUp} toggle={setIsPopUp} filter={filterData} setFilter={setFilterData} />
                <div className='settings-sec'>
                  <div className={isPopUp ? 'apply-seetings-popup settings-popup active' : 'apply-seetings-popup settings-popup'}>
                    <div className='modal-content'>
                      <div className='common-modal-sec'>
                        <button onClick={() => handlePopup(1)} className='common-close-btn'><img src={closeicon} /></button>
                        <h3>{t('views.statistics.applySettings')}</h3>
                        <p>{t('views.statistics.beSure')}</p>
                        <div className='settings-btn-grp'>
                          <a onClick={() => handlePopup(1)} className='small-btn common-white-btn'>{t('views.statistics.no')}</a>
                          <a onClick={() => handlePopup(2)} className='small-btn common-blue-btn'>{t('views.statistics.yes')}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='sub-tabs-sec'>
                    <Nav tabs className='sub-tabs advance-sub-tabs statics-first-tabs'>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTabSub === '1'
                          })}
                          onClick={() => togglesub('1')}
                        >
                          {t('views.statistics.frequencys')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTabSub === '2'
                          })}
                          onClick={() => togglesub('2')}
                        >
                          {t('views.statistics.longuest')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTabSub === '3'
                          })}
                          onClick={() => togglesub('3')}
                        >
                          {t('views.statistics.neighbor')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTabSub === '4'
                          })}
                          onClick={() => togglesub('4')}
                        >
                          {t('views.statistics.parity')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTabSub === '5'
                          })}
                          onClick={() => togglesub('5')}
                        >
                          {t('views.statistics.bigOrSmall')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTabSub === '6'
                          })}
                          onClick={() => togglesub('6')}
                        >
                          {t('views.statistics.averageVal')}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <TabContent activeTab={currentActiveTabSub}>
                    <TabPane tabId="1">
                      <div className='statics-radios-main-sec'>
                        <div className='statics-radios-sec draw-radio-grp'>
                          <span>{t('views.statistics.mostDrawn')}</span>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='1' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 1} onChange={() => radioSelChange(1)} />
                            <label htmlFor="1">{t('views.statistics.singles')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='2' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 2} onChange={() => radioSelChange(2)} />
                            <label htmlFor="2">{t('views.statistics.pairs')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='3' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 3} onChange={() => radioSelChange(3)} />
                            <label htmlFor="3">{t('views.statistics.triplets')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='4' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 4} onChange={() => radioSelChange(4)} />
                            <label htmlFor="4">{t('views.statistics.quadruplets')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='5' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 5} onChange={() => radioSelChange(5)} />
                            <label htmlFor="5">{t('views.statistics.quintuplets')}</label>
                          </div>
                        </div>
                      </div>
                      <div className='common-table-sec common-table-statisc sort'>
                        <DataTable
                          columns={columns}
                          data={rows}
                          defaultSortAsc={false}
                          sortFunction={customSort}
                          defaultSortFieldId={6}
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className='statics-radios-main-sec'>
                        <div className='statics-radios-sec undrawn-radio-sec'>
                          <span>{t('views.statistics.longuest')}</span>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='1' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 1} onChange={() => radioSelChange(1)} />
                            <label htmlFor="1">{t('views.statistics.singles')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='2' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 2} onChange={() => radioSelChange(2)} />
                            <label htmlFor="2">{t('views.statistics.pairs')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='3' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 3} onChange={() => radioSelChange(3)} />
                            <label htmlFor="3">{t('views.statistics.triplets')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='5' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 4} onChange={() => radioSelChange(4)} />
                            <label htmlFor="4">{t('views.statistics.quadruplets')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='6' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 5} onChange={() => radioSelChange(5)} />
                            <label htmlFor="5">{t('views.statistics.quintuplets')}</label>
                          </div>
                        </div>
                      </div>
                      <div className='common-table-sec common-table-statisc sort undrawn-table'>
                        {longestSuccess
                          ? <DataTable
                            columns={undrawncolumns}
                            data={undrawnrows}
                            defaultSortAsc={false}
                            sortFunction={customSort}
                            defaultSortFieldId={6}
                          />
                          : <Loading addClass={'active'} />}
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div className='statics-radios-main-sec'>
                        <div className='statics-radios-sec neighborhood-radio-sec'>
                          <span>{t('views.statistics.mostConsecutive')}</span>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='2' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 2} onChange={() => radioSelChange(2)} />
                            <label htmlFor="2">{t('views.statistics.pairs')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='3' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 3} onChange={() => radioSelChange(3)} />
                            <label htmlFor="3">{t('views.statistics.triplets')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='4' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 4} onChange={() => radioSelChange(4)} />
                            <label htmlFor="4">{t('views.statistics.quadruplets')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='5' defaultChecked={activeStep[currentActiveTabSub - 1]?.tId === 5} onChange={() => radioSelChange(5)} />
                            <label htmlFor="5">{t('views.statistics.quintuplets')}</label>
                          </div>
                        </div>
                      </div>
                      <div className='common-table-sec common-table-statisc common-table-statisc sort'>
                        {neighborSuccess
                          ? <DataTable
                            columns={neighborColumns}
                            data={neighborRows}
                            defaultSortAsc={false}
                            sortFunction={customSort}
                            defaultSortFieldId={6}
                          />
                          : <Loading addClass={'active'} />}
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <p>{t('views.statistics.distEvenOdd')}</p>
                      <div className='common-table-sec common-table-statisc parity-table'>
                        {paritySuccess
                          ? <DataTable
                            columns={paritycolumns}
                            data={parityrows}
                          />
                          : <Loading addClass={'active'} />}
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <p>{t('views.statistics.distSmallBig')}</p>
                      <div className='common-table-sec common-table-statisc parity-table'>
                        {smallBigSuccess
                          ? <DataTable
                            columns={sbColumns}
                            data={sbRows}
                          />
                          : <Loading addClass={'active'} />}
                      </div>
                    </TabPane>
                    <TabPane tabId="6">
                      <div className='statics-radios-sec'>
                        <p>{t('views.statistics.mostAverage')}<div className='tooltip-heading d-inline-block ml-10'><img src={infoicon} className="infoicon" />
                          <span className='tooltiptext '>{t('views.statistics.mostAverageTooltip')}</span></div></p>
                      </div>
                      <div className='common-table-sec common-table-statisc sort'>
                        {acValueSuccess
                          ? <DataTable
                            columns={acColumns}
                            data={acRows}
                            defaultSortAsc={false}
                            sortFunction={customSort}
                            defaultSortFieldId={6}
                          />
                          : <Loading addClass={'active'} />}
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className='statics-sec'>
                <div className='statics-header'>
                  <h4 className='common-title'>{t('views.statistics.advancedStatistics')}</h4>
                  <div className='statics-header-middle'>
                    {t('views.statistics.numberOfDraws')}
                    <a id={filterData?.noOfDraws <= 1 ? 'disabled' : ''} onClick={filterData?.noOfDraws > 1 ? () => handleChangeDraws(1) : null}><img src={arrowleft} /></a>{filterData.noOfDraws}  <a id={filterData?.noOfDraws > statisticsData?.dts?.length - 1 ? 'disabled' : ''} onClick={filterData?.noOfDraws < statisticsData?.dts?.length ? () => handleChangeDraws(2) : null}><img src={arrowright} /></a>
                  </div>
                  <div className='statics-header-right'>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={filterData.startDate}
                      minDate={statisticsData?.dts?.length ? new Date(statisticsData?.dts[statisticsData?.dts?.length - 1]) : ''}
                      maxDate={filterData.endDate}
                      onChange={(date) => handleDateChange(date, 1)}
                      onKeyDown={(e) => {
                        e.preventDefault()
                      }}
                    />
                    <span>-</span>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={filterData.endDate}
                      minDate={filterData.startDate}
                      maxDate={statisticsData?.dts?.length ? new Date(statisticsData.dts[0]) : ''}
                      onChange={(date) => handleDateChange(date, 2)}
                      onKeyDown={(e) => {
                        e.preventDefault()
                      }}
                    />
                  </div>
                </div>
                <Range modal={isPopUp} toggle={setIsPopUp} filter={filterData} setFilter={setFilterData} />
                <div className='settings-sec'>
                  <div className={isPopUp ? 'apply-seetings-popup settings-popup active' : 'apply-seetings-popup settings-popup'}>
                    <div className='modal-content'>
                      <div className='common-modal-sec'>
                        <button onClick={() => handlePopup(1)} className='common-close-btn'><img src={closeicon} /></button>
                        <h3>{t('views.statistics.applySettings')}</h3>
                        <p>{t('views.statistics.beSure')}</p>
                        <div className='settings-btn-grp'>
                          <a onClick={() => handlePopup(1)} className='small-btn common-white-btn'>{t('views.statistics.no')}</a>
                          <a onClick={() => handlePopup(2)} className='small-btn common-blue-btn'>{t('views.statistics.yes')}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='sub-tabs-sec'>
                    <Nav tabs className='sub-tabs advance-sub-tabs'>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTabSub === '1'
                          })}
                          onClick={() => togglesub('1')}
                        >
                          {t('views.statistics.prime')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTabSub === '2'
                          })}
                          onClick={() => togglesub('2')}
                        >
                          {t('views.statistics.distance')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTabSub === '3'
                          })}
                          onClick={() => togglesub('3')}
                        >
                          {t('views.statistics.lenght')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTabSub === '4'
                          })}
                          onClick={() => togglesub('4')}
                        >
                          {t('views.statistics.average')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTabSub === '5'
                          })}
                          onClick={() => togglesub('5')}
                        >
                          {t('views.statistics.unit')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTabSub === '6'
                          })}
                          onClick={() => togglesub('6')}
                        >
                          {t('views.statistics.evenNumber')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTabSub === '7'
                          })}
                          onClick={() => togglesub('7')}
                        >
                          {t('views.statistics.oddNumber')}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <TabContent activeTab={currentActiveTabSub}>
                    <TabPane tabId="1">
                      <div className='statics-radios-main-sec'>
                        <div className='statics-radios-sec primes-radio-sec'>
                          <span>{t('views.statistics.accuracy')}</span>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='1' defaultChecked={advanceActiveStep[currentActiveTabSub - 1]?.tId === 1} onChange={() => radioSelChange(1)} />
                            <label htmlFor="1">{t('views.statistics.exactly')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='2' defaultChecked={advanceActiveStep[currentActiveTabSub - 1]?.tId === 2} onChange={() => radioSelChange(2)} />
                            <label htmlFor="2">{t('views.statistics.atLeast')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='3' defaultChecked={advanceActiveStep[currentActiveTabSub - 1]?.tId === 3} onChange={() => radioSelChange(3)} />
                            <label htmlFor="3">{t('views.statistics.atMost')}</label>
                          </div>
                        </div>
                      </div>
                      <div className='common-table-sec common-table-statisc'>
                        {primeSuccess
                          ? <DataTable
                            columns={primeColumns}
                            data={primeRows}
                          />
                          : <Loading addClass={'active'} />}
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className='statics-radios-main-sec'>
                        <div className='statics-radios-sec advance-statics-radios distance-radio'>
                          <span>{t('views.statistics.repetitions')}</span>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='1' defaultChecked={advanceActiveStep[currentActiveTabSub - 1]?.tId === 1} onChange={() => radioSelChange(1)} />
                            <label htmlFor="1">1 {t('views.statistics.times')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='2' defaultChecked={advanceActiveStep[currentActiveTabSub - 1]?.tId === 2} onChange={() => radioSelChange(2)} />
                            <label htmlFor="2">2 {t('views.statistics.times')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='3' defaultChecked={advanceActiveStep[currentActiveTabSub - 1]?.tId === 3} onChange={() => radioSelChange(3)} />
                            <label htmlFor="3">3 {t('views.statistics.times')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='4' defaultChecked={advanceActiveStep[currentActiveTabSub - 1]?.tId === 4} onChange={() => radioSelChange(4)} />
                            <label htmlFor="4">4 {t('views.statistics.times')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='5' defaultChecked={advanceActiveStep[currentActiveTabSub - 1]?.tId === 5} onChange={() => radioSelChange(5)} />
                            <label htmlFor="5">5 {t('views.statistics.times')}</label>
                          </div>
                        </div>
                      </div>
                      <div className='common-table-sec common-table-statisc sort'>
                        {distanceSuccess
                          ? <DataTable
                            columns={distanceColumns}
                            data={distanceRows}
                            defaultSortAsc={false}
                            defaultSortFieldId={6}
                            sortFunction={customSort}
                          />
                          : <Loading addClass={'active'} />}
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <p>{t('views.statistics.mostDifference')}</p>
                      <div className='common-table-sec common-table-statisc sort'>
                        {lengthSuccess
                          ? <DataTable
                            columns={lengthColumns}
                            data={lengthRows}
                            defaultSortAsc={false}
                            defaultSortFieldId={6}
                            sortFunction={customSort}
                          />
                          : <Loading addClass={'active'} />}
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <p>{t('views.statistics.intervals')}</p>
                      <div className='common-table-sec common-table-statisc sort'>
                        {avgSuccess
                          ? <DataTable
                            columns={avgcolumns}
                            data={avgrows}
                            defaultSortAsc={false}
                            defaultSortFieldId={6}
                            sortFunction={customSort}
                          />
                          : <Loading addClass={'active'} />}
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <div className='statics-radios-main-sec unit-radio-grp'>
                        <div className='statics-radios-sec mb-20'>
                          <div className='checkbox-grp ml-0 show-unit-sec'>
                            <input type='checkbox' name='statics-radio' defaultChecked={advanceActiveStep[2]?.subData?.isChecked} onChange={() => radioSelChange(!advanceActiveStep[2]?.subData?.isChecked)} />
                            <label htmlFor="statics-radio-1">{t('views.statistics.showUnits')}</label>
                          </div>
                          {advanceActiveStep[2]?.subData?.isChecked
                            ? <>
                              <div className='radio-grp'>
                                <input type='radio' name='statics-radio' id='11' defaultChecked={advanceActiveStep[2]?.subData?.subTabId === 11} onChange={() => radioSelChange(11)} />
                                <label htmlFor="11">{t('views.statistics.exactly')}</label>
                              </div>
                              <div className='radio-grp'>
                                <input type='radio' name='statics-radio' id='12' defaultChecked={advanceActiveStep[2]?.subData?.subTabId === 12} onChange={() => radioSelChange(12)} />
                                <label htmlFor="12">{t('views.statistics.atLeast')}</label>
                              </div>
                              <div className='radio-grp'>
                                <input type='radio' name='statics-radio' id='13' defaultChecked={advanceActiveStep[2]?.subData?.subTabId === 13} onChange={() => radioSelChange(13)} />
                                <label htmlFor="13">{t('views.statistics.atMost')}</label>
                              </div>
                            </>
                            : <p>{t('views.statistics.exactlyAll')}</p>
                          }
                        </div>
                      </div>
                      {advanceActiveStep[2]?.subData?.isChecked
                        ? <div className='statics-radios-main-sec'>
                          <div className='statics-radios-sec unit-second-radio-grp'>
                            <span>{t('views.statistics.occurence')}</span>
                            <div className='radio-grp'>
                              <input type='radio' name='statics-radio' id='1' defaultChecked={advanceActiveStep[2]?.tId === 1} onChange={() => radioSelChange(1)} />
                              <label htmlFor="1">1 {t('views.statistics.times')}</label>
                            </div>
                            <div className='radio-grp'>
                              <input type='radio' name='statics-radio' id='2' defaultChecked={advanceActiveStep[2]?.tId === 2} onChange={() => radioSelChange(2)} />
                              <label htmlFor="2">2 {t('views.statistics.times')}</label>
                            </div>
                            <div className='radio-grp'>
                              <input type='radio' name='statics-radio' id='3' defaultChecked={advanceActiveStep[2]?.tId === 3} onChange={() => radioSelChange(3)} />
                              <label htmlFor="3">3 {t('views.statistics.times')}</label>
                            </div>
                            <div className='radio-grp'>
                              <input type='radio' name='statics-radio' id='5' defaultChecked={advanceActiveStep[2]?.tId === 4} onChange={() => radioSelChange(4)} />
                              <label htmlFor="4">4 {t('views.statistics.times')}</label>
                            </div>
                            <div className='radio-grp'>
                              <input type='radio' name='statics-radio' id='6' defaultChecked={advanceActiveStep[2]?.tId === 5} onChange={() => radioSelChange(5)} />
                              <label htmlFor="5">5 {t('views.statistics.times')}</label>
                            </div>
                          </div>
                        </div>
                        : ''}
                      <div>

                      </div>
                      <div className='common-table-sec common-table-statisc'>
                        {advanceActiveStep[2]?.subData?.isChecked
                          ? unitSuccess
                            ? <DataTable
                              columns={unitsColumns}
                              data={unitsRows}
                            />
                            : <Loading addClass={'active'} />
                          : unitDFSuccess
                            ? <DataTable
                              columns={unitDFColumns}
                              data={unitDFRows}
                            />
                            : <Loading addClass={'active'} />
                        }
                      </div>
                    </TabPane>
                    <TabPane tabId="6">
                      <div className='statics-radios-main-sec'>
                        <div className='statics-radios-sec event-number-radio-grp'>
                          <span>{t('views.statistics.occurenceCombi')}</span>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='1' defaultChecked={advanceActiveStep[3]?.tId === 1} onChange={() => radioSelChange(1)} />
                            <label htmlFor="1">1 {t('views.statistics.times')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='2' defaultChecked={advanceActiveStep[3]?.tId === 2} onChange={() => radioSelChange(2)} />
                            <label htmlFor="2">2 {t('views.statistics.times')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='3' defaultChecked={advanceActiveStep[3]?.tId === 3} onChange={() => radioSelChange(3)} />
                            <label htmlFor="3">3 {t('views.statistics.times')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='4' defaultChecked={advanceActiveStep[3]?.tId === 4} onChange={() => radioSelChange(4)} />
                            <label htmlFor="4">4 {t('views.statistics.times')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='5' defaultChecked={advanceActiveStep[3]?.tId === 5} onChange={() => radioSelChange(5)} />
                            <label htmlFor="5">5 {t('views.statistics.times')}</label>
                          </div>
                        </div>
                      </div>
                      <div className='common-table-sec common-table-statisc sort'>
                        {evenSuccess
                          ? <DataTable
                            columns={evenNoColumns}
                            data={evenNoRows}
                            defaultSortAsc={false}
                            defaultSortFieldId={6}
                            sortFunction={customSort}
                          />
                          : <Loading addClass={'active'} />}
                      </div>
                    </TabPane>
                    <TabPane tabId="7">
                      <div className='statics-radios-main-sec'>
                        <div className='statics-radios-sec event-number-radio-grp'>
                          <span>{t('views.statistics.occurenceCombi')}</span>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='1' defaultChecked={advanceActiveStep[4]?.tId === 1} onChange={() => radioSelChange(1)} />
                            <label htmlFor="1">1 {t('views.statistics.times')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='2' defaultChecked={advanceActiveStep[4]?.tId === 2} onChange={() => radioSelChange(2)} />
                            <label htmlFor="2">2 {t('views.statistics.times')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='3' defaultChecked={advanceActiveStep[4]?.tId === 3} onChange={() => radioSelChange(3)} />
                            <label htmlFor="3">3 {t('views.statistics.times')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='4' defaultChecked={advanceActiveStep[4]?.tId === 4} onChange={() => radioSelChange(4)} />
                            <label htmlFor="4">4 {t('views.statistics.times')}</label>
                          </div>
                          <div className='radio-grp'>
                            <input type='radio' name='statics-radio' id='5' defaultChecked={advanceActiveStep[4]?.tId === 5} onChange={() => radioSelChange(5)} />
                            <label htmlFor="5">5 {t('views.statistics.times')}</label>
                          </div>
                        </div>
                      </div>
                      <div className='common-table-sec common-table-statisc sort'>
                        {oddSuccess
                          ? <DataTable
                            columns={oddNoColumns}
                            data={oddNoRows}
                            defaultSortAsc={false}
                            defaultSortFieldId={6}
                            sortFunction={customSort}
                          />
                          : <Loading addClass={'active'} />}
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
      <Footer isVisibleBall={false} /></>
  )
}

export default Statistics
