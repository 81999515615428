import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../views/Home/Home'
import Nori from '../views/Nori/index'
import SellNori from '../views/SellNori/index'
import Statistics from '../views/Statistics/Statistics'
import QuickPick from '../views/Quick-Pick//Quick-Pick'
import UseNori from '../views/UseNori/UseNori'
import LiveDrawings from '../views/Live-Drawings/Live-Drawings'
import Tutorial from '../views/Tutorial/Tutorial'
import Help from '../views/Help/Help'
import Videos from '../views/Videos/Videos'
import Competition from '../views/Competition/Competition'
import CompetitionNori from '../views/Competition/CompetitionNori'
import Winners from '../views/Winners/Winner'
import MyGames from '../views/My-Games/My-Games'
import Affiliates from '../views/Affiliates/Affiliates'
import StakingRewards from '../views/Staking-Rewards/Staking-Rewards'
import WinnersDetails from '../views/Winners-Details/Winners-Details'
import Error from '../views/Error/Error'
import Test from '../views/test/index'

const Routers = () => {
  return (
    <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="nori">
            <Route path="build" element={<Nori />} />
            <Route path="sell" element={<SellNori />} />
            <Route path="use" element={<UseNori />} />
            <Route path="quick-pick" element={<QuickPick />} />
          </Route>
          <Route path="statistics" element={<Statistics />} />
          <Route path="live-drawings" element={<LiveDrawings />} />
          <Route path="tutorial" element={<Tutorial />} />
          <Route path="help" element={<Help />} />
          <Route path="videos" element={<Videos />} />
          <Route path="competition" element={<Competition />} />
          <Route path="competitions" element={<CompetitionNori />} />
          <Route path="winners" element={<Winners />} />
          <Route path="mygames" element={<MyGames />} />
          <Route path='affiliates' element={<Affiliates />} />
          <Route path="staking-rewards" element={<StakingRewards />} />
          <Route path="winners-details" element={<WinnersDetails />} />
          <Route path="error" element={<Error />} />
          <Route path="test" element={<Test />} />
          <Route path='*' element={<Error />} />
        </Route>
    </Routes>
  )
}

export default Routers
