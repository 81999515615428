import Axios from '../../axios'

export async function getStatistics (flag) {
  if (flag) {
    const response = await Axios.get('/nora642/statistics')
    return response
  }
}

export async function frequencyResp (filterData, activeStep) {
  const response = await Axios.post('/nora642/statistics/frequency', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    },
    nba: activeStep,
    dw: true
  })
  return response
}

export async function longestUndrawnResp (filterData, activeStep) {
  const response = await Axios.post('/nora642/statistics/recentDraw', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    },
    nba: activeStep,
    dw: true
  })
  return response
}

export async function neighborResp (filterData, activeStep) {
  const response = await Axios.post('/nora642/statistics/neighbor', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    },
    nba: activeStep,
    dw: true
  })
  return response
}

export async function parityResp (filterData) {
  const response = await Axios.post('/nora642/statistics/parity', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    }
  })
  return response
}

export async function sbResp (filterData) {
  const response = await Axios.post('/nora642/statistics/smallB', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    }
  })
  return response
}

export async function acValueResp (filterData) {
  const response = await Axios.post('/nora642/statistics/acValue', {
    sps: {
      fd: `${new Date(filterData.startDate).getDate() < 10 ? '0' + new Date(filterData.startDate).getDate() : new Date(filterData.startDate).getDate()}.${(new Date(filterData.startDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.startDate).getMonth() + 1) : new Date(filterData.startDate).getMonth() + 1}.${new Date(filterData.startDate).getFullYear()}`,
      fn: filterData.range[0],
      tn: filterData.range[1],
      td: `${new Date(filterData.endDate).getDate() < 10 ? '0' + new Date(filterData.endDate).getDate() : new Date(filterData.endDate).getDate()}.${(new Date(filterData.endDate).getMonth() + 1) < 10 ? '0' + (new Date(filterData.endDate).getMonth() + 1) : new Date(filterData.endDate).getMonth() + 1}.${new Date(filterData.endDate).getFullYear()}`
    }
  })
  return response
}
