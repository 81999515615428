const CONTRACT_DATA = {
  NORA642Address: process.env.REACT_APP_NORA642ADDRESS,
  NORA642ABI: [
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'subscriptionId',
          type: 'uint256'
        },
        {
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address'
        },
        {
          internalType: 'bytes32',
          name: 'keyHash',
          type: 'bytes32'
        },
        {
          internalType: 'address',
          name: 'noraTokenAddress',
          type: 'address'
        },
        {
          internalType: 'address',
          name: 'pancakeRouterAddress',
          type: 'address'
        },
        {
          internalType: 'address',
          name: 'bnbAddress',
          type: 'address'
        }
      ],
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      inputs: [],
      name: 'ECDSAInvalidSignature',
      type: 'error'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'length',
          type: 'uint256'
        }
      ],
      name: 'ECDSAInvalidSignatureLength',
      type: 'error'
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: 's',
          type: 'bytes32'
        }
      ],
      name: 'ECDSAInvalidSignatureS',
      type: 'error'
    },
    {
      inputs: [],
      name: 'InvalidShortString',
      type: 'error'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'have',
          type: 'address'
        },
        {
          internalType: 'address',
          name: 'want',
          type: 'address'
        }
      ],
      name: 'OnlyCoordinatorCanFulfill',
      type: 'error'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'have',
          type: 'address'
        },
        {
          internalType: 'address',
          name: 'owner',
          type: 'address'
        },
        {
          internalType: 'address',
          name: 'coordinator',
          type: 'address'
        }
      ],
      name: 'OnlyOwnerOrCoordinator',
      type: 'error'
    },
    {
      inputs: [],
      name: 'ReentrancyGuardReentrantCall',
      type: 'error'
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'str',
          type: 'string'
        }
      ],
      name: 'StringTooLong',
      type: 'error'
    },
    {
      inputs: [],
      name: 'ZeroAddress',
      type: 'error'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'poolIds',
          type: 'uint256[]'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'amounts',
          type: 'uint256[]'
        }
      ],
      name: 'Claimed',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'claimAddress',
          type: 'address'
        }
      ],
      name: 'CommissionClaimed',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'vrfCoordinator',
          type: 'address'
        }
      ],
      name: 'CoordinatorSet',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [],
      name: 'EIP712DomainChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'to',
          type: 'address'
        }
      ],
      name: 'OwnershipTransferRequested',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'to',
          type: 'address'
        }
      ],
      name: 'OwnershipTransferred',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'poolId',
          type: 'uint256'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'bnbAmount',
          type: 'uint256'
        }
      ],
      name: 'Participated',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'poolId',
          type: 'uint256'
        }
      ],
      name: 'PoolCreated',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'poolid',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'randomNumbers',
          type: 'uint256[]'
        }
      ],
      name: 'RandomNumberGenerated',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'currentPoolId',
          type: 'uint256'
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256'
        }
      ],
      name: 'RandomNumberRequested',
      type: 'event'
    },
    {
      inputs: [],
      name: 'acceptOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'callbackGasLimit',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'poolId',
          type: 'uint256'
        }
      ],
      name: 'checkIsCompleted',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256[]',
          name: 'poolIds',
          type: 'uint256[]'
        },
        {
          internalType: 'uint256[]',
          name: 'winningAmounts',
          type: 'uint256[]'
        },
        {
          internalType: 'bytes',
          name: 'signature',
          type: 'bytes'
        }
      ],
      name: 'claim',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        },
        {
          internalType: 'address',
          name: 'claimAddress',
          type: 'address'
        }
      ],
      name: 'claimCommission',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'createPool',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'currentPoolId',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'eip712Domain',
      outputs: [
        {
          internalType: 'bytes1',
          name: 'fields',
          type: 'bytes1'
        },
        {
          internalType: 'string',
          name: 'name',
          type: 'string'
        },
        {
          internalType: 'string',
          name: 'version',
          type: 'string'
        },
        {
          internalType: 'uint256',
          name: 'chainId',
          type: 'uint256'
        },
        {
          internalType: 'address',
          name: 'verifyingContract',
          type: 'address'
        },
        {
          internalType: 'bytes32',
          name: 'salt',
          type: 'bytes32'
        },
        {
          internalType: 'uint256[]',
          name: 'extensions',
          type: 'uint256[]'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'generateRandomNumber',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getCommissionAmount',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'poolId',
          type: 'uint256'
        },
        {
          internalType: 'address',
          name: 'user',
          type: 'address'
        }
      ],
      name: 'getParticipationDetails',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'poolId',
          type: 'uint256'
        }
      ],
      name: 'getPoolBalance',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getPools',
      outputs: [
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        },
        {
          internalType: 'address',
          name: '',
          type: 'address'
        }
      ],
      name: 'hasClaimed',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'noraToken',
      outputs: [
        {
          internalType: 'contract IERC20',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'numWords',
      outputs: [
        {
          internalType: 'uint32',
          name: '',
          type: 'uint32'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'pancakeRouter',
      outputs: [
        {
          internalType: 'contract IUniswapV2Router02',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'bool',
          name: 'payInBNB',
          type: 'bool'
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'participate',
      outputs: [],
      stateMutability: 'payable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'requestId',
          type: 'uint256'
        },
        {
          internalType: 'uint256[]',
          name: 'randomWords',
          type: 'uint256[]'
        }
      ],
      name: 'rawFulfillRandomWords',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'sKeyHash',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 's_vrfCoordinator',
      outputs: [
        {
          internalType: 'contract IVRFCoordinatorV2Plus',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_vrfCoordinator',
          type: 'address'
        }
      ],
      name: 'setCoordinator',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'to',
          type: 'address'
        }
      ],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'wbnbAddress',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'poolId',
          type: 'uint256'
        }
      ],
      name: 'winningNumbers',
      outputs: [
        {
          internalType: 'uint256[]',
          name: '',
          type: 'uint256[]'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      stateMutability: 'payable',
      type: 'receive'
    }
  ],
  NORATOKENAddress: process.env.REACT_APP_NORATOKENADDRESS,
  NORAABI: [
    {
      inputs: [
        {
          internalType: 'address',
          name: 'spender',
          type: 'address'
        },
        {
          internalType: 'uint256',
          name: 'allowance',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'needed',
          type: 'uint256'
        }
      ],
      name: 'ERC20InsufficientAllowance',
      type: 'error'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'sender',
          type: 'address'
        },
        {
          internalType: 'uint256',
          name: 'balance',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'needed',
          type: 'uint256'
        }
      ],
      name: 'ERC20InsufficientBalance',
      type: 'error'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'approver',
          type: 'address'
        }
      ],
      name: 'ERC20InvalidApprover',
      type: 'error'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'receiver',
          type: 'address'
        }
      ],
      name: 'ERC20InvalidReceiver',
      type: 'error'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'sender',
          type: 'address'
        }
      ],
      name: 'ERC20InvalidSender',
      type: 'error'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'spender',
          type: 'address'
        }
      ],
      name: 'ERC20InvalidSpender',
      type: 'error'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Approval',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'to',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Transfer',
      type: 'event'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'owner',
          type: 'address'
        },
        {
          internalType: 'address',
          name: 'spender',
          type: 'address'
        }
      ],
      name: 'allowance',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'spender',
          type: 'address'
        },
        {
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'approve',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'account',
          type: 'address'
        }
      ],
      name: 'balanceOf',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'decimals',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'name',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'totalSupply',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'to',
          type: 'address'
        },
        {
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'transfer',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'from',
          type: 'address'
        },
        {
          internalType: 'address',
          name: 'to',
          type: 'address'
        },
        {
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'transferFrom',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'nonpayable',
      type: 'function'
    }
  ]
}
export default CONTRACT_DATA
