import React from 'react'
import PropTypes from 'prop-types'
import Box from '../../../views/test/Box'
import { Dustbin } from '../../../views/test/Dustbin'
import infoicon from '../../../assets/images/info-icon.png'
import numberbg from '../../../assets/images/numbers-bg.png'
import { useTranslation } from 'react-i18next'

const FourthComponent = (props) => {
  const { t } = useTranslation('global')
  const selectPrioritizeNum = (number) => {
    const result = [...props.steps]
    const finalArr = result.find((x) => x.id === 4 && !x.selPriGrpNum.includes(number))
    if (finalArr === undefined) {
      result[6].selPriGrpNum.splice(result[6].selPriGrpNum.indexOf(number), 1)
    } else {
      result[6].selPriGrpNum.push(number)
    }
    result[7].selBlockWiseNum.map((no) => {
      if (!no.selPrioNo.includes(number)) {
        no.selPrioNo.push(number)
      } else {
        no.selPrioNo.splice(no.selPrioNo.indexOf(number), 1)
      }
      return no
    })
    props.setPrevActiveStep(false)
    props.setSteps(result)
  }
  const handleChange = (e, no) => {
    const arr = e.target.value.replace('%', '')
    const result = [...props.steps]
    if (e.target.value) {
      if (no === 1) {
        result[6].highPrio = Number(arr)
        result[6].lowPrio = 100 - Number(arr)
      } else {
        result[6].lowPrio = Number(arr)
        result[6].highPrio = 100 - Number(arr)
      }
    }
    props.setPrevActiveStep(false)
    return props.setSteps(result)
  }

  return <div>

    <div className='common-step-box'>
      <div className='text-center'>
        <h4 className='common-title'>{t('views.nori.step4.title')}<div className='tooltip-heading d-inline-block'>
          <img src={infoicon} className="infoicon" />
          <span className="tooltiptext">{t('views.nori.step4.infos')}</span>
        </div>
        </h4>
        <p>{t('views.nori.step4.instruction')}</p>
        <div className='nori-mandetory-ticket-sec'>
          <div className='nori-ticket-sec'>
            <div className='drag-sec'>
              <img src={numberbg} />
              <div className='dustbin-sec' id='dust1'><Dustbin name={1} dustbin={1}></Dustbin></div>
              <div className='mandetory-number-list drag-item-list'>
                <section>
                  {Array.from(Array(42), (op, i) =>
                    props.steps[2].isSelected && props.steps[2].selectedNum.includes(i + 1)
                      ? <><Box id={i + 1} container={1} name={i + 1} key={i + 1} className='active'>{i + 1}</Box><br /></>
                      : (props.steps[4].isSelected && props.steps[4].selectedExcludeNum.includes(i + 1) === true) || (props.steps[6].isSelected && props.steps[6].selPriGrpNum.includes(i + 1) === true)
                          ? <><Box id={i + 1} container={1} name={i + 1} key={i + 1} className='disabled'>{i + 1}</Box><br /></>
                          : <><Box id={i + 1} container={1} name={i + 1} key={i + 1} clickFn={(e) => selectPrioritizeNum(e, i + 1)} className=''></Box><br /></>
                  )}
                </section>
              </div>
            </div>
            <div className='mandetory-number-footer'>
              {t('views.nori.step4.heighly')} <div className='drop-sec'>
                <select value={props.steps[6].highPrio} onChange={(e) => handleChange(e, 1)}>
                  {Array.from(Array(49), (op, i) =>
                    <option key={i + 50 + 1} value={(i + 50 + 1)}>{i + 50 + 1}%</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className='nori-ticket-sec '>
            <div className='drag-sec'>
              <img src={numberbg} />
              <div className='dustbin-sec' id='dust2' ><Dustbin name={2} dustbin={2}></Dustbin></div>
              <div className='mandetory-number-list drag-item-list'>
                <section>
                  {Array.from(Array(42), (op, i) =>
                    props.steps[4].isSelected && props.steps[4].selectedExcludeNum.includes(i + 1)
                      ? <><Box id={i + 1} name={i + 1} container={2} key={i + 1} className='red'>{i + 1}</Box><br /></>
                      : props.steps[6].isSelected && props.steps[6].selPriGrpNum.includes(i + 1) === true
                        ? <><Box key={i + 1} id={i + 1} name={i + 1} container={2} className='yellow' clickFn={(e) => selectPrioritizeNum(e, i + 1)}><a>{i + 1}</a></Box><br /></>
                        : <><Box key={i + 1} id={i + 1} name={i + 1} container={2} className='disabled'>{i + 1}</Box><br /></>
                  )}
                </section>
              </div>
            </div>
            <div className='mandetory-number-footer'>
              {t('views.nori.step4.lowly')} <div className='drop-sec'>
                <select value={props.steps[6].lowPrio} onChange={(e) => handleChange(e, 2)}>
                  {Array.from(Array(49), (op, i) =>
                    <option key={48 - i + 1} value={(48 - i + 1)}>{48 - i + 1}%</option>
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

FourthComponent.propTypes = {
  steps: PropTypes.array,
  setSteps: PropTypes.func,
  setPrevActiveStep: PropTypes.func
}

export default FourthComponent
