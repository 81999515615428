import React from 'react'
import PropTypes from 'prop-types'
import infoicon from '../../../assets/images/info-icon.png'
import { useTranslation } from 'react-i18next'

const FirstComponent = (props) => {
  const { t } = useTranslation('global')
  const handleChange = (step) => {
    const prevStep = [...props.steps]
    const modifyingStep = prevStep.findIndex((ele) => ele.id === step)
    prevStep[modifyingStep].isSelected = !prevStep[modifyingStep].isSelected
    if (!prevStep[modifyingStep].isSelected) {
      prevStep[modifyingStep].isDone = false
      prevStep[modifyingStep].isVisited = false
    }
    props.setPrevActiveStep(false)
    props.setSteps(prevStep)
  }
  return <div>
    <div className='common-step-box'>
      <div className='text-center'>
        <h4 className='common-title'>{props.noriType === 1 ? t('views.nori.step1.selfConf') : props.noriType === 2 ? t('views.nori.step1.configure') : t('views.nori.step1.build')}
          <div className='tooltip-heading '><img src={infoicon} className="infoicon" />
            <span className="tooltiptext">{t('views.nori.step1.instruction')}</span>
          </div>
        </h4>
      </div>
      <div className='self-config-list'>
        <div className='row'>
          {props.steps?.map((step, i) =>
            step.id < 9
              ? <div className='col-md-6' key={i}>
                <div className='form-grp config-form-grp '>
                  {step.isSelected === true ? <input name={i} type="checkbox" checked onChange={() => handleChange(step.id)} /> : <input name={i} type="checkbox" onChange={() => handleChange(step.id)} />}
                  <div className='tooltip-heading'><img src={infoicon} className='info-icon' /><span className="tooltiptext">{step.tooltip}</span></div>
                  <label>{step.label}</label>
                </div>
              </div>
              : ''
          )}
        </div>
      </div>
    </div>
  </div>
}

FirstComponent.propTypes = {
  steps: PropTypes.array,
  setSteps: PropTypes.func,
  setPrevActiveStep: PropTypes.func,
  noriType: PropTypes.number
}

export default FirstComponent
