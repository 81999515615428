import React from 'react'
import '../../assets/sass/views/Nori.scss'
import Sell from './SellNori.jsx'

const SellNori = (props) => {
  return (
    <>
      <Sell {...props}/>
    </>
  )
}

export default SellNori
