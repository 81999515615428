/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Range } from 'react-range'
import Header from '../../components/Header'
import Loading from '../../components/Loader'
import Footer from '../../components/footer'
import infoicon from '../../assets/images/info-icon.png'
import dollericon from '../../assets/images/dollar-front-premium.svg'
import flashicon from '../../assets/images/flash-front-premium.svg'
import gifticon from '../../assets/images/gift-front-premium.svg'
import staricon from '../../assets/images/star-front-premium.svg'
import refralimg from '../../assets/images/refral-img.png'
import closeicon from '../../assets/images/close-icon.svg'
import blueballimg from '../../assets/images/blue-ball-img.png'
import redballimg from '../../assets/images/red-ball-img.png'
import diceimg from '../../assets/images/dice-img.png'

import '../../assets/sass/views/stacking.scss'
import {
  TabContent, TabPane, Nav, NavLink, NavItem, Modal, ModalBody
} from 'reactstrap'

const StakingRewards = (props) => {
  const [loading, setLoading] = useState({ status: true })
  const [currentActiveTab, setCurrentActiveTab] = useState('1')
  const [range, setRange] = useState({ values: [360] })
  const [optionValue, setOptionValue] = useState(1)
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab)
  }
  const handleTab = (no) => {
    if (no === 1) {
      toggle('1')
    } else if (no === 2) {
      toggle('2')
    } else if (no === 3) {
      toggle('3')
    }
  }
  const [modal, setModal] = useState(false)
  const togglemodal = () => {
    return setModal(!modal)
  }
  useEffect(() => {
    setTimeout(() => {
      setLoading({ status: false })
    }, 500)
  }, [])
  const handleChange = (e) => {
    if (e.target.value === '1') setOptionValue(1)
    else setOptionValue(2)
    console.log('e', e.target.value)
  }
  return (
    <>
    {loading.status ? <Loading addClass={'active'} /> : ''}
      <Header activeTab={2} />
      <div className='stacking-reward-header-sec'>
        <div className='container'>
          <div className='stacking-header-left'>
            <p>NORACLE offers not only amazing games you can use your gaming skills or try your good luck to earn big.</p>
            <p className='mb-0'>It also provides a staking mechanism that produces:</p>
            <ul>
              <li>Daily Ecosystem Rewards (ER) in BNB coins.</li>
              <li>Daily NORA Rewards (NR) up to <strong>288%</strong> ROI within the first year after launch.</li>
              <li>Up to <strong>144% ROI</strong> in NORA Rewards thereafter.</li>
            </ul>
            <span>Once you have NORA tokens in your wallet, you are ready to stake (deposit) your NORA to earn consistently high rewards.</span>
          </div>
          <div className='stacking-header-right'>
            <h1>Want to own a piece of the Ecosystem?</h1>
            <p>Simply visit one of the exchanges Where you can <a href='#'>Buy or Sell</a></p>
            <p>your NORA tokens.</p>
          </div>
        </div>
      </div>
      <div className='stacking-main-sec statics-tabs-sec'>
        <img src={blueballimg} className='blueballimg' />
        <div className='container'>
          <div className='stacking-tabs'>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      currentActiveTab === '1'
                  })}
                  onClick={() => { handleTab(1) }}
                >
                  Deposit NORA tokens
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      currentActiveTab === '2'
                  })}
                  onClick={() => { handleTab(2) }}
                >
                  Compound/Claim
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      currentActiveTab === '3'
                  })}
                  onClick={() => { handleTab(3) }}
                >
                  Referrals
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1">
              <div className='p-relative'>
                <img src={redballimg} className='redballimg' />
                <img src={diceimg} className='diceimg' />
                <div className='statics-sec stacking-tab-sec'>
                  <div className='nora-ticket-list'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='nora-ticket deposite-nora-ticket'>
                          <label>NORA Price</label>
                          <h1>$ 0.08</h1>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='nora-ticket deposite-nora-ticket text-right'>
                          <label>Balance</label>
                          <h1>10000 NORA</h1>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='nora-ticket deposite-nora-ticket nora-ticket-form-sec'>
                          <form>
                            <div className='form-grp'>
                              <input type='text' placeholder='Enter NORA Amount' />
                              <button><span>Enter all my NORA</span></button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='nora-ticket deposite-nora-ticket'>
                          <label>Staking Duration <span><i>(In days)</i></span></label>
                          <div className='range-slider-sec statics-range stack-range'>
                          <Range
                                step={90}
                                min={90}
                                max={360}
                                values={range.values}
                                onChange={(values) => setRange({ values })}
                                renderTrack={({ props, children }) => (
                                  <div>
                                    <div
                                      {...props}
                                      style={{
                                        ...props.style,
                                        height: '8px',
                                        width: '100%',
                                        backgroundColor: 'rgb(65, 124, 255)'
                                      }}
                                    >
                                      {children}
                                    </div>
                                    <ul>
                                    {Array.from(Array(4), (op, i) =>
                                      <li key={(i + 1) * 90}>{(i + 1) * 90}</li>
                                    )}
                                  </ul>
                                </div>
                                )}
                                renderThumb={({ props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...props.style,
                                      height: '16px',
                                      width: '16px',
                                      backgroundColor: '#fff'
                                    }}
                                  />
                                )}
                              />
                              {/* <span>Days</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='nora-ticket nora-switch-sec'>
                          <div>
                            <p>A minimum of 10 NORA required for deposits</p>
                            <p className='mb-0'><div className='tooltip-heading d-inline-block'><img src={infoicon} className="infoicon" /></div>No Fees are charged on deposits</p>
                          </div>
                          <div className='text-right d-flex flex-wrap justify-content-end flex-column align-items-end'>
                            <div>
                              <input id='checkbox' className='switch-input' type='checkbox' />
                              <label htmlFor='checkbox' className='switch'></label>
                            </div>
                            <p className='mb-0'>Approve NORA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='text-center'>
                      <a className='common-blue-btn deposit-btn' onClick={togglemodal}>Deposit into the Well</a>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className='p-relative'>
                  <img src={redballimg} className='redballimg' />
                  <img src={diceimg} className='diceimg' />
                <div className='statics-sec stacking-tab-sec'>
                  <div className='compund-main-sec'>
                    <div className='row'>
                      <div className='col-lg-7'>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='claim-sec'>
                              <img src={dollericon} />
                              <h6><strong>Deposits</strong></h6>
                              <h6>100000 NORA</h6>
                              <p>$10000</p>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='claim-sec'>
                              <img src={staricon} />
                              <h6><strong>NORA Rewards</strong></h6>
                              <h6>500 NORA</h6>
                              <p>$50</p>
                            </div>
                          </div>
                          {/* <div className='col-md-6'>
                            <div className='claim-sec'>
                              <img src={flashicon} />
                              <h6><strong>Max Payout</strong></h6>
                              <h6>366000 NORA</h6>
                              <p>$36600</p>
                            </div>
                          </div> */}
                          <div className='col-md-6'>
                            <div className='claim-sec'>
                              {/* <img src={flashicon} /> */}
                              <div className='active-stack-header'>
                                <h6><strong>Active Stakes</strong></h6>
                                <select value={optionValue} onChange={handleChange}>
                                  <option value='1'>10000 NORA</option>
                                  <option value='2'>No Stake</option>
                                </select>
                                </div>
                                {optionValue === 1
                                  ? <ul className='active-stack-list'>
                                  <li>Expected Rewards: <strong>16000 NORA</strong></li>
                                  <li>Staking Duration: <strong>180 days</strong></li>
                                  <li>Rewards to Date: <strong>13500 NORA</strong></li>
                                  <li>Remaining Time: <strong>47 days</strong></li>
                                </ul>
                                  : <div className='text-center'>
                                  <img src={flashicon} />
                                  <h6>No Active Stake</h6>
                                </div>
                                }
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='claim-sec'>
                              <img src={gifticon} />
                              <h6><strong>Claimed NR</strong></h6>
                              <h6>13000 NORA</h6>
                              <p>$1300</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-5'>
                        <div className='claim-sec'>
                          <ul className='active-stack-list mb-0'>
                            <li>Active Stakes: <strong>3</strong></li>
                            <li>NORA Rewards: <strong>500 NORA</strong></li>
                            <li><strong>10% </strong>Fees applied on claimed NORA</li>
                            <li><strong>5% </strong>Fees applied on redeposits</li>
                          </ul>
                          <div className='text-center'>
                            <div className='claim-btns-sec'>
                              <a className='common-white-btn'><span>redeposit</span></a>
                              <a className='common-blue-btn'><span>Claim</span></a>
                            </div>
                          </div>
                        </div>
                        <div className='claim-sec'>
                          <div className='eco-system-sec text-center'>
                            <h4 className='green-title'>Ecosystem Rewards (ER)</h4>
                            <h4>2 BNB</h4>
                            <a className='common-blue-btn green-btn'><span>CLAIM NOW</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="3">
              <div className='p-relative'>
                  <img src={redballimg} className='redballimg' />
                  <img src={diceimg} className='diceimg' />
                <div className='statics-sec stacking-tab-sec'>
                  <div className='referral-tab-sec'>
                    <ul>
                      <li>Choose or become a referrer to receive a portion of the Fees</li>
                    </ul>
                    <div className='row'>
                      <div className='col-lg-9'>
                        <div className='nora-ticket-list m-0'>
                          <div className='nora-ticket'>
                            <form>
                              <div className='form-grp'>
                                <input type='text' placeholder='Enter a referrer address' />
                                <button><span>Choose a Referrer</span></button>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className='my-referal-sec'>
                          <h3>My Referrer: <span>0100x52…7bx40</span></h3>
                          <div className='my-refral-heading'>
                            <span>MY REFERRED</span>
                          </div>
                          <div className='refral-list-sec'>
                            <ul>
                              <li>0100x52…7bx40</li>
                              <li>0100x52…7bx40</li>
                              <li>0100x52…7bx40</li>
                              <li>0100x52…7bx40</li>
                              <li>0100x52…7bx40</li>
                              <li>0100x52…7bx40</li>
                              <li>0100x52…7bx40</li>
                              <li>0100x52…7bx40</li>
                              <li>0100x52…7bx40</li>
                              <li>0100x52…7bx40</li>
                              <li>0100x52…7bx40</li>
                              <li>0100x52…7bx40</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3'>
                        <div className='refral-right-sec'>
                          <img src={refralimg} />
                          <div className='refral-right-top-sec'>
                            <h5>Referral Rewards</h5>
                            <h1>300 NORA</h1>
                            <h2>$30</h2>
                          </div>
                          <a className='common-blue-btn deposit-btn'>Claim Now</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
      <Footer isVisibleBall={false} />
      <Modal toggle={togglemodal} className='settings-popup result-popup' id='settings-popup' isOpen={modal}>
        <ModalBody>
          <div className='common-modal-sec'>
            <button onClick={togglemodal} className='common-close-btn'><img src={closeicon} /></button>
            <h4>My Nori Compitition Details</h4>
            <div className='result-popup-header'>
              <div className='result-popup-header-data'>
                <h5>Draw Date: <strong>20/02/2022</strong></h5>
              </div>
              <div className='result-popup-header-data'>
                <h5>My Score: <strong>50 BNB</strong></h5>
              </div>
              <a className='common-blue-btn view-nori-btn'>View Nori</a>
            </div>
            <div className='common-winning-sec'>
              <h5>Winning Nori Score</h5>
              <ul className='winning-list nori-score-list mb-0'>
                <li>
                  <ul>
                    <li><label className='diamond-icon'>1</label> <span>Class 1</span></li>
                    <li><label className='diamond-icon'>5</label> <span>Class 2</span></li>
                    <li><label className='diamond-icon'>28</label> <span>Class 3</span></li>
                    <li><label className='diamond-icon'>1</label> <span>Class 4</span></li>
                    <li><label className='diamond-icon'>5</label> <span>Class 5</span></li>
                    <li><label className='diamond-icon'>28</label> <span>Class 6</span></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

StakingRewards.propTypes = {
  currentPool: PropTypes.array,
  isVisibleBall: PropTypes.bool
}

export default StakingRewards
