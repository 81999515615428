import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import PropTypes from 'prop-types'
import { Modal, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import CanvasJSReact from '../assets/js/canvasjs.react'
import { pFactor } from '../query/nori/nori.query'
import classnames from 'classnames'
import infoicon from '../assets/images/info-icon-white.png'
import '../assets/sass/views/footer.scss'
import '../assets/sass/views/common.scss'
import { useTranslation } from 'react-i18next'

import closeicon from '../assets/images/close-icon.svg'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

const GraphModal = (props) => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const [currentActiveTab, setCurrentActiveTab] = useState('1')
  const [pFactorData, setPFactorData] = useState([])
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab)
  }
  const options = {
    animationEnabled: true,
    exportEnabled: true,
    backgroundColor: '#1F1F1F',
    colorSet: '#49B9FF',
    color: '#fff',
    axisY: {
      title: '',
      interval: 1,
      labelFontColor: 'white',
      minimum: 0,
      maximum: 10.2
    },
    axisX: {
      title: '',
      prefix: '',
      interval: 10,
      minimum: -1,
      labelFontColor: 'white',
      maximum: 101
    },
    data: [{
      type: 'line',
      dataPoints: props?.noriEvalData?.graphData
    }]
  }
  const containerProps = {
    height: '300px',
    width: '100%'
  }

  const options1 = {
    theme: 'light2',
    animationEnabled: true,
    exportEnabled: true,
    backgroundColor: '#000',
    colorSet: '#49B9FF',
    color: '#fff',
    toolTip: {
      shared: true,
      contentFormatter: function (e) {
        const value = pFactorData[e.entries[0].index]?.drawDate.replace('.', '/')
        if (value !== undefined) {
          const newValue = value.replace('.', ' ')
          const tooltipdata = t('component.modalNoriEval.date') + ' <span class="blue-text">' + newValue + '</span><br/>' + t('component.modalNoriEval.pFactor') + ' <span class="blue-text">' + pFactorData[e.entries[0].index]?.pfactor.toFixed(6) + '</span>'
          return tooltipdata
        }
        return value
      }
    },
    axisY: {
      title: '',
      interval: 1,
      lineThickness: 1,
      lineColor: '#707070',
      labelFontColor: 'white',
      minimum: 0
    },
    axisX: {
      title: '',
      prefix: '',
      ineThickness: 1,
      labelFontColor: 'white',
      labelFormatter: function (e) {
        const value = pFactorData[e.value]?.drawDate.replace('.', '/')
        if (value !== undefined) {
          const newValue = value.replace('.', ' ')
          return newValue
        }
        return value
      }
    },
    data: [{
      type: 'area',
      dataPoints: pFactorData?.map((p) => {
        return {
          y: p.pfactor
        }
      })
    }]
  }
  const containerProps1 = {
    height: '230px',
    width: '100%'
  }

  const { refetch: pFactorRefetch } = useQuery('p-factor', () => pFactor(props.steps, props.ncb), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setPFactorData(data)
      if (props?.setNoriPFactorData) {
        props.setNoriPFactorData(data)
      }
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: 'Internal Server Error' } })
    }
  })

  const handleTab = (no) => {
    if (no === 1) {
      toggle('1')
    } else if (no === 2) {
      pFactorRefetch()
      toggle('2')
    }
  }

  return (
    <>
      <Modal toggle={props.toggle} className='preview-popup nori-evation-popup' isOpen={props.modal}>
        <ModalBody>
          <div className='common-modal-sec'>
            <button onClick={props.toggle} className='common-close-btn'><img src={closeicon} /></button>
            <h4 className='common-title'>{t('component.modalNoriEval.title')}</h4>
            <ul className='preview-header-list'>
              <li><span style={{ backgroundColor: '#4BE200' }}></span>{t('component.modalNoriEval.compliant1')}</li>
              <li><span style={{ backgroundColor: '#FCA801' }}></span>{t('component.modalNoriEval.compliant2')}</li>
              <li><span style={{ backgroundColor: '#FC002A' }}></span>{t('component.modalNoriEval.compliant3')}</li>
            </ul>
            <div className='preview-details-main-sec'>
              <div className='preview-details-sec'>
                <div className='preview-detail-left'>
                  <ul>
                    {props?.noriEvalData.dateColors?.map((nori, i) =>
                      <li key={i}><span style={nori.color === 0 ? { backgroundColor: '#4BE200' } : nori.color === 1 ? { backgroundColor: '#FCA801' } : { backgroundColor: '#FC002A' }}></span>{nori.drawDate.replaceAll('.', '/')}</li>
                    )}
                  </ul>
                </div>
                <div className='preview-details-right'>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active:
                            currentActiveTab === '1'
                        })}
                        onClick={() => { handleTab(1) }}
                      >
                        {t('component.modalNoriEval.compliance')} <div className='p-relative d-inline-block tooltip-heading mb-0'><img src={infoicon} className='ml-5' /><span className="tooltiptext tooltip-modal-text">{t('component.modalNoriEval.complianceInfo')}</span></div>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active:
                            currentActiveTab === '2'
                        })}
                        onClick={() => { handleTab(2) }}
                      >
                        {t('component.modalNoriEval.pFactor')} <div className='p-relative d-inline-block tooltip-heading mb-0'><img src={infoicon} className='ml-5' /><span className="tooltiptext tooltip-modal-text">{t('component.modalNoriEval.pFactorInfo')}</span></div>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={currentActiveTab}>
                    <TabPane tabId="1">
                      <div className='tab-chart-sec'>
                        <CanvasJSChart options={options} containerProps={containerProps} />
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className='tab-chart-sec p-fact-chart-sec'>
                        <CanvasJSChart options={options1} containerProps={containerProps1} />
                        <span className='p-fact-horizon-title'>{t('component.modalNoriEval.drawDate')}</span>
                        <span className='p-fact-vertical-title'>{t('component.modalNoriEval.pFactors')}</span>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

GraphModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  noriEvalData: PropTypes.object,
  steps: PropTypes.array,
  ncb: PropTypes.number,
  noriPFactorData: PropTypes.array,
  setNoriPFactorData: PropTypes.func
}

export default GraphModal
