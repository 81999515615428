import React from 'react'
import PropTypes from 'prop-types'
import infoicon from '../../../assets/images/info-icon.png'
import { useTranslation } from 'react-i18next'

const FifthComponent = (props) => {
  const { t } = useTranslation('global')
  const handleChange = (e, id, no) => {
    const result = [...props.steps]
    if (e.target.value) {
      const res = result[1].selFilterCriteria.findIndex((m) => id === m.id)
      if (no === 1) {
        result[1].selFilterCriteria[res].min = Number(e.target.value)
      } else {
        result[1].selFilterCriteria[res].max = Number(e.target.value)
      }
      props.setPrevActiveStep(false)
      return props.setSteps(result)
    }
  }

  return <div>

    <div className='common-step-box'>
      <div className='text-center'>
        <h4 className='common-title '>{t('views.nori.step5.title')}
          <div className='tooltip-heading d-inline-block'>
            <img src={infoicon} className="infoicon" />
            <span className='tooltiptext'>{t('views.nori.step5.infos')}</span>
          </div>
        </h4>
        <p>{t('views.nori.step5.instruction')}</p>
      </div>
      <div className='filter-list-sec'>
        <div className='filter-list-item'>
          <div className='filter-left-sec'>
          </div>
          <div className='filter-right-sec filter-header'>
            <div>
              <label>{t('views.nori.step5.min')}</label>
            </div>
            <div>
              <label>{t('views.nori.step5.max')}</label>
            </div>
          </div>
        </div>
        {props.steps[1].selFilterCriteria.map((fc) =>
          <div className='filter-list-item' key={fc.id}>
            <div className='filter-left-sec ' key={fc.id}>
              <div className='tooltip-heading'>
                <img src={infoicon} />
                <span className='tooltiptext'>{fc.tooltip}</span>
              </div>
              <span>{fc.key}</span>
            </div>
            <div className='filter-right-sec'>
              <div className='filter-select'>
                <div>
                  <select value={Number(fc.min) !== 0 && Number(fc.min)} onChange={(e) => handleChange(e, fc.id, 1)}>
                    {Array.from(Array(Number(fc.max) + 1), (op, i) =>
                      <option key={i} value={i}>{i}</option>
                    )}
                  </select>
                </div>
              </div>
              <div className='filter-select'>
                <div>
                  <select value={Number(fc.max)} onChange={(e) => handleChange(e, fc.id, 2)}>
                    {Array.from(Array(Number(fc.min) === 0 ? 7 : Number(6 - fc.min) + 1), (op, i) =>
                      <option key={Number(fc.min) === 0 ? i : Number(fc.min) + i} value={Number(fc.min) === 0 ? i : Number(fc.min) + i}>{Number(fc.min) === 0 ? i : Number(fc.min) + i}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
}

FifthComponent.propTypes = {
  steps: PropTypes.array,
  setSteps: PropTypes.func,
  setPrevActiveStep: PropTypes.func
}

export default FifthComponent
