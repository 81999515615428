import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap'
import WinnerDetailModal from './WinnerDetailModal'
import { getResTicketDetail } from '../../query/walletconnect/walletconnect.query'
import closeicon from '../../assets/images/close-icon.svg'
import { useTranslation } from 'react-i18next'

import '../../assets/sass/views/stacking.scss'

const MyGameDetailsModal = (props) => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const [detailId, setDetailId] = useState({ id: '' })
  const [detailData, setDetailData] = useState({ myGameResultData: [], drawDate: '', ID: '', from: 'res' })
  const [winnerDetailModal, setWinnerDetailModal] = useState(false)

  const winnerDetailToggle = () => {
    setWinnerDetailModal(!winnerDetailModal)
  }

  useQuery(['get-res-details', detailId], () => getResTicketDetail(detailId.id), {
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setDetailData({ ...detailData, myGameResultData: data, drawDate: props?.detailData?.ddt, ID: detailId.id, from: 'res' })
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: 'Internal Server Error' } })
    }
  })
  const handleViewDetails = (selectedId) => {
    setDetailId({ ...detailId, id: selectedId })
    winnerDetailToggle()
  }
  return (
    <>
      <Modal toggle={props.toggle} className='settings-popup result-popup' id='settings-popup' isOpen={props.modal}>
        <ModalBody>
          <div className='common-modal-sec'>
            <button onClick={props.toggle} className='common-close-btn'><img src={closeicon} /></button>
            <h4 className='common-title'>{t('views.myGame.compDetailsModal.title')}</h4>
            <div className='result-popup-header'>
              <div className='result-popup-header-data'>
                <h5>{t('views.myGame.compDetailsModal.drawDate')}<strong>{`${new Date(props?.detailData?.ddt).getUTCDate() < 10 ? '0' + new Date(props?.detailData?.ddt).getUTCDate() : new Date(props?.detailData?.ddt).getUTCDate()}/${(new Date(props?.detailData?.ddt).getMonth() + 1) < 10 ? '0' + (new Date(props?.detailData?.ddt).getMonth() + 1) : new Date(props?.detailData?.ddt).getMonth() + 1}/${new Date(props?.detailData?.ddt).getFullYear()}`}</strong></h5>
              </div>
              <div className='result-popup-header-data'>
                <h5>{t('views.myGame.compDetailsModal.myScore')}<strong>{props?.detailData?.nsc < 0 || props?.detailData?.nsc === null ? '--' : props?.detailData?.nsc?.toFixed(2)}</strong></h5>
              </div>
              <a className='common-blue-btn view-nori-btn' onClick={() => handleViewDetails(props?.detailData?.norID)}>{t('views.myGame.compDetailsModal.viewNori')}</a>
            </div>
            <div className='common-winning-sec'>
              <h5>{t('views.myGame.compDetailsModal.winningNori')}</h5>
              <ul className='winning-list nori-score-list mb-0'>
                <li>
                  {props?.detailData?.nws?.length
                    ? <ul>
                      {props?.detailData?.nws?.map((ele, i) => <li className='white' key={i}>{ele}<span>{t('views.myGame.compDetailsModal.class')} {i + 1}</span></li>)}
                    </ul>
                    : <ul>
                      {Array.from(Array(5), (op, i) => <li className='white' key={i}><span>{t('views.myGame.compDetailsModal.class')} {i + 1}</span>-</li>)}
                    </ul>
                  }
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <WinnerDetailModal toggle={winnerDetailToggle} modal={winnerDetailModal} detailData={detailData} />
    </>
  )
}

MyGameDetailsModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  detailData: PropTypes.object,
  compId: PropTypes.string
}

export default MyGameDetailsModal
