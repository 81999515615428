import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import closeicon from '../../assets/images/close-icon.svg'

const ConfirmationModal = (props) => {
  const { t } = useTranslation('global')
  const handleClick = () => {
    props.handle(props.no, true)
    props.toggle()
  }
  return (
    <Modal toggle={props.toggle} className='settings-popup' isOpen={props.modal}>
      <ModalBody>
        <div className='common-modal-sec'>
          <button onClick={props.toggle} className='common-close-btn'><img src={closeicon} /></button>
          <h3>{props.heading}</h3>
          <p>{t('views.myGame.confirmationModal.doYou')}{props.content}{t('views.myGame.confirmationModal.selected')}</p>
          <div className='settings-btn-grp'>
            <a onClick={props.toggle} className='small-btn common-white-btn'>{t('views.myGame.confirmationModal.no')}</a>
            <a onClick={handleClick} className='small-btn common-blue-btn'>{t('views.myGame.confirmationModal.yes')}</a>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
ConfirmationModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  heading: PropTypes.string,
  content: PropTypes.string,
  no: PropTypes.number,
  handle: PropTypes.func
}

export default ConfirmationModal
