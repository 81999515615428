/* eslint-disable no-undef */
import axios from 'axios'

// 'http://localhost:8080'
// 'http://svr.noracle.io'
// `${process.env.GRAILS_APP_NODE_BACKEND_URL}`
// 'https://svr.nora642.com'
const Axios = axios.create({
  baseURL: 'https://svr.nora642.com'
})

export default Axios
