/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap'
import closeicon from '../../assets/images/close-icon.svg'
import refreshicon from '../../assets/images/refresh-icon.svg'
import tikallicon from '../../assets/images/tik-all-icon.svg'
import cancelicon from '../../assets/images/cancel-icon.svg'
import deleteicon from '../../assets/images/delete.svg'
import { useTranslation } from 'react-i18next'

const TicketModal = (props) => {
  const { t } = useTranslation('global')
  const [confirmDelete, setConfirmDeletet] = useState(false)
  const [errorStatus, setErrorStatus] = useState(false)
  const seletedTicketNo = props.ticketNo - 1
  const selectedNo = (e, number) => {
    const finalArray = [...props.selectedTicket]
    e.preventDefault()
    if (finalArray[seletedTicketNo].selectedNo.includes(number)) {
      finalArray[seletedTicketNo].selectedNo.splice(finalArray[seletedTicketNo].selectedNo.indexOf(number), 1)
      finalArray[seletedTicketNo].totalSelectedNo -= 1
    } else {
      if (finalArray[seletedTicketNo].totalSelectedNo < 7 && finalArray[seletedTicketNo].selectedNo.length < 6) {
        finalArray[seletedTicketNo].selectedNo.push(number)
        finalArray[seletedTicketNo].totalSelectedNo += 1
      }
    }
    props.setSelectedTicket(finalArray)
    setConfirmDeletet(false)
  }

  const randomNo = (count) => {
    const numberlist2 = [...props.selectedTicket[seletedTicketNo].selectedNo]
    const finalArr = [...props.selectedTicket]
    const finalLength = count === 6 ? (count - numberlist2.length) + 1 : count + 1
    if (numberlist2.length < 6) {
      for (let i = 1; i < finalLength; i++) {
        const res = generateRandomNo()
        if (!numberlist2.includes(res)) {
          numberlist2.push(res)
          finalArr[seletedTicketNo].totalSelectedNo += 1
        } else {
          const result = randomNo(count)
          return result
        }
      }
      finalArr[seletedTicketNo].selectedNo = numberlist2
      props.setSelectedTicket(finalArr, props.selectedTicket[seletedTicketNo].selectedNo = numberlist2)
    }
    if (count === 6) {
      props.selectedTicket[seletedTicketNo].totalSelectedNo = 6
    }
    setConfirmDeletet(false)
  }

  const clearList = () => {
    const finalArr = [...props.selectedTicket]
    finalArr[seletedTicketNo].selectedNo = []
    props.setSelectedTicket(finalArr, props.selectedTicket[seletedTicketNo].totalSelectedNo = 0)
    setConfirmDeletet(false)
  }

  const handleDeleteClick = () => {
    const finalArr = [...props.selectedTicket]
    finalArr.splice(seletedTicketNo, 1)
    for (let i = 0; i < finalArr.length; i++) {
      finalArr[i].id = i + 1
    }
    props.setSelectedTicket(finalArr)
    props.toggle()
    setConfirmDeletet(false)
  }

  const handleCancelClick = () => {
    const finalArr = [...props.selectedTicket]
    if (finalArr[seletedTicketNo].confirmClick === false) {
      finalArr[seletedTicketNo].selectedNo = []
      props.setSelectedTicket(finalArr, props.selectedTicket[seletedTicketNo].totalSelectedNo = 0)
    } else if (finalArr[seletedTicketNo].confirmClick === true) {
      finalArr[seletedTicketNo] = JSON.parse(sessionStorage.getItem('tmpList'))
      props.setSelectedTicket(finalArr)
    }
    props.toggle()
    setConfirmDeletet(false)
    setErrorStatus(false)
  }
  const handleConfirmClick = () => {
    if (props.selectedTicket[seletedTicketNo]?.totalSelectedNo < 6) setErrorStatus(true)
    else if (props.selectedTicket[seletedTicketNo]?.totalSelectedNo === 6 && (Number(props.ticketNo) < 1005)) {
      setErrorStatus(false)
      if (props.selectedTicket[seletedTicketNo].confirmClick === false) {
        props.setSelectedTicket(props.selectedTicket, props.selectedTicket[seletedTicketNo].confirmClick = true)
        props.selectedTicket.push({ id: props.selectedTicket.length + 1, selectedNo: [], totalSelectedNo: 0, autoGenerated: false, confirmClick: false })
      } else props.setSelectedTicket(props.selectedTicket, props.selectedTicket[seletedTicketNo].autoGenerated === false)

      props.toggle()
    } else props.toggle()
    setConfirmDeletet(false)
  }

  const generateRandomNo = () => {
    const generateNumber = Math.round(Math.random() * (42 - 1) + 1)
    return generateNumber
  }
  /**
  const handleClose = () => {
    const finalArr = [...props.selectedTicket]
    if (finalArr[seletedTicketNo].autoGenerated === false && finalArr[seletedTicketNo].confirmClick === false) {
      finalArr[seletedTicketNo].selectedNo = []
      props.setSelectedTicket(finalArr, props.selectedTicket[seletedTicketNo].totalSelectedNo = 0)
    }
    props.toggle()
  }
*/
  return (
    <>
      <Modal isOpen={props.modal}
        toggle={() => handleCancelClick()}>
        <ModalBody>
          <div className='common-modal-sec'>
            <button onClick={handleCancelClick} className='common-close-btn'><img src={closeicon} /></button>
            {
              !confirmDelete
                ? <button onClick={() => setConfirmDeletet(true)} className='deleteTicket'><img src={deleteicon} alt="Delete this ticket" /></button>
                : <div className='deleteTicket'>
                  {t('views.home.ticketModal.deleteTicket')}
                  <div>
                    <button onClick={handleDeleteClick}>{t('global.yes')}</button>
                    <button onClick={() => setConfirmDeletet(false)}>{t('global.no')}</button>
                  </div>
                </div>
            }
            <h4 className='tickPaddTop'>{t('views.home.ticketModal.ticket')} {props.ticketNo}</h4>
            <div className='ticket-detail-sec'>
              <div className='ticket-left-sec'>
                <ul className='hexagone-list'>
                  {Array.from(Array(42), (op, i) =>
                    <li key={i + 1} onClick={(e) => selectedNo(e, i + 1)} className={props.selectedTicket[seletedTicketNo]?.selectedNo?.includes(i + 1) == true ? 'active' : ''}><a href='#'>{i + 1}</a></li>
                  )}
                </ul>
              </div>
              <div className='ticket-right-sec'>
                <ul className='ticket-btns-list'>
                  <li onClick={() => randomNo(1)}>
                    <div>
                      <img src={refreshicon} />
                      <span>{t('views.home.ticketModal.randomPick1')}</span>
                    </div>
                  </li>
                  <li onClick={() => randomNo(6)}>
                    <div>
                      <img src={tikallicon} />
                      <span>{t('views.home.ticketModal.randomPickAll')}</span>
                    </div>
                  </li>
                  <li>
                    <div onClick={clearList}>
                      <img src={cancelicon} />
                      <span>{t('views.home.ticketModal.clear')}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {errorStatus ? <p style={{ color: 'red' }}>{t('views.home.ticketModal.SixNum')}</p> : ''}
            <div className='ticket-footer-sec'>
              <div className='remianin-tickets'>
                <span><strong>{props.selectedTicket[seletedTicketNo]?.totalSelectedNo}</strong> {t('views.home.ticketModal.ticket')}</span> <strong>/ </strong>
                <span><strong>{6 - props.selectedTicket[seletedTicketNo]?.totalSelectedNo} </strong> {t('views.home.ticketModal.remain')}</span>
              </div>
              <div className='confirmation-btn'>
                <a onClick={handleCancelClick} className='common-white-btn small-btn'><span>{t('views.home.ticketModal.cancel')}</span></a>
                <a onClick={handleConfirmClick} className='common-blue-btn small-btn'><span>{t('views.home.ticketModal.confirm')}</span></a>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
TicketModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  selectedTicket: PropTypes.array,
  setSelectedTicket: PropTypes.func,
  ticketNo: PropTypes.number,
  setTicketNo: PropTypes.func
}

export default TicketModal
